// const location = window.location;
const dashjs = window.dashjs;
let channel;
export default class VideoPlayer {
  listeners = [];
  newUrl;
  dashPlayer;
  constructor(config) {
    this.initialized = false;
    this.src = config.src;
    this.videoType = config.videoType;
    this.container = config.container;
    this.player = this.createPlayer();
    this.setupEventListeners();
  }
  createPlayer() {
    let player = document.createElement('video');
    if (this.videoType == 'application/dash+xml') {
      this.dashPlayer = dashjs.MediaPlayer().create();
      this.dashPlayer.initialize(player, this.src, false);
    } else {
      let source = document.createElement('source');
      source.src = this.src;
      source.type = this.videoType;
      player.appendChild(source);
    }
    player.id = getRandomId();
    player.autoplay = false;
    this.container.appendChild(player);
    return player;
  }

  setupEventListeners() {
    this.addListener('ended', this.onEnded.bind(this));
    this.addListener('error', this.onError.bind(this));
    this.addListener('loadedmetadata', this.onLoadedMetadata.bind(this));
    // Aggiungi altri listener per eventi HbbTV rilevanti
  }

  onEnded() {
    console.log('Video ended');
    // Implementa la logica per la fine del video
  }

  onError(error) {
    console.error('Video error:', error, error.message);
    // Gestisci gli errori in modo appropriato
  }

  onLoadedMetadata() {
    console.log('Metadata loaded');
    // Implementa la logica per quando i metadata sono caricati
  }

  init() {
    const userAgent = navigator.userAgent;
    let version = 140;
    try {
      version = userAgent.split('HbbTV')[1].split(' ')[0].replace(/[^0-9]/g, '');
    } catch (error) {
      console.warn(error);
    }
    try {
      let broadcast = document.getElementById('broadcast');
      if (!broadcast) {
        broadcast = document.createElement('object');
        broadcast.setAttribute('type', 'video/broadcast');
        broadcast.id = 'broadcast';
        document.body.appendChild(broadcast);
      }
      console.log(broadcast);
      if (version <= 160) {
        channel = broadcast.currentChannel;
        broadcast.bindToCurrentChannel();
        broadcast.stop();
        console.log('broadcast stopped');
        broadcast.onChannelChangeError = (ch, err) => {
          console.log('onChannelChangeError', ch, err);
          if (!ch) {
            ch = channel;
          }
          broadcast.setChannel(ch);
        };
        broadcast.onChannelChangeSucceeded = (ch, err) => {
          console.log('onChannelChangeSucceeded', ch, err);
        };
      }
    } catch (e) {
      console.log('error stopping broadcast', e);
    }
  }

  play() {
    console.log('new url:', this.newUrl);
    if (this.newUrl) {
      this.updateSource(this.newUrl);
    }
    if (!this.initialized) {
      this.init();
    }
    const container = this.container;
    container.classList.add('play');
    setTimeout(() => {
      container.classList.remove('play');
    }, 500);
    try {
      this.player.play();
    } catch (error) {
      console.warn('PLAY WARNING', error);
    }
  }

  updateSource(url) {
    if (this.dashPlayer) {
      this.dashPlayer.attachSource(url);
    } else {
      this.player.src = url;
      this.player.load();
    }
    this.newUrl = null;
  }

  pause() {
    const container = this.container;
    container.classList.add('pause');
    setTimeout(() => {
      container.classList.remove('pause');
    }, 500);
    try {
      this.player.pause();
    } catch (error) {
      console.warn('PAUSE WARNING', error);
    }
  }

  stop() {
    if (this.dashPlayer) {
      this.dashPlayer.pause();
    }
    this.player.pause();
    this.player.currentTime = 0;
  }

  fastForward(speed = 2) {
    if (this.player.playbackRate !== speed) {
      this.player.playbackRate = speed;
    }
  }

  rewind(speed = -2) {
    if (this.player.playbackRate !== speed) {
      this.player.playbackRate = speed;
    }
  }

  seek(val) {
    const newTime = this.player.currentTime + val;
    if (newTime >= 0 && newTime <= this.player.duration) {
      this.player.currentTime = newTime;
    }
  }

  setURL(url) {
    console.log('setURL', url);
    this.newUrl = url;
  }

  addListener(eventName, handler) {
    this.listeners.push({ eventName, handler });
    console.log('this.player.addEventListener', eventName);
    this.player.addEventListener(eventName, handler);
  }

  removeListeners() {
    const listeners = this.listeners;
    for (const item of listeners) {
      console.log('remove listener', item.eventName);
      this.player.removeEventListener(item.eventName, item.handler);
    }
    this.listeners = [];
  }

  destroy() {
    try {
      this.removeListeners();
      console.log('this', this);
      console.log('dashPlayer', this.dashPlayer);
      console.log('player', this.player);
      console.log('beforedestroy dashPlayer');
      if (this.dashPlayer) {
        console.log('destroy dashPlayer');
        this.dashPlayer.destroy();
        this.dashPlayer = null;
        delete this.dashPlayer;
      }
      if (this.player) {
        this.player.pause();
        this.player.src = '';
        this.player.removeAttribute('src');
        let last = this.player.lastChild;
        console.log('destroy: while');
        while (last) {
          this.player.removeChild(last);
          last = this.player.lastChild;
        }
        console.log('destroy: end while');
        console.log('load');
        this.player.load();
        console.log('removeChild');
        this.player.remove(); // clear from dom
        console.log('video null');
        this.player = null;
        delete this.player;
      }
      try {
        // window.location.reload();
      } catch (error) {
        console.warn(error);
      }
    } catch (e) {
      console.log('Error at destroy()');
      console.log(e.description);
    }
    // finally {
    //   location.reload();
    // }
  }
}

function getRandomId() {
  return 'video-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
