// import store from '../Store';
import CONFIG from '../config';
import { eventBus, EVENTS } from '../services/EventBus';

function openWsConnection() {
  var ws = new WebSocket(CONFIG.WS_URL);
  var interval;
  var closeInterval;

  closeInterval = setInterval(function () {
    try {
      clearInterval(interval);
      interval = null;
      clearInterval(closeInterval);
      closeInterval = null;
      ws.close();
    } catch (error) {
      console.warn(error);
    }
  }, 300000);

  ws.onopen = function () {
    ws.send(JSON.stringify({ "action": "lastMedia" }));
    interval = setInterval(function () {
      try {
        ws.send(JSON.stringify({ "action": "ping" }));
      } catch (error) {
        ws.close();
      }
    }, 200000);
  };
  ws.onclose = function () {
    if (interval) {
      clearInterval(interval);
    }
    // console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    setTimeout(function () {
      console.log('reopen connection');
      openWsConnection();
    }, 500);
  };
  ws.onmessage = function (evt) {
    console.log('onmessage');
    // console.log(evt);
    try {
      var message = JSON.parse(evt.data);
      if (message.action && message.action === 'pong') {
        return;
      }
      eventBus.emit(EVENTS.NEW_MESSAGE, message);
    } catch (error) {
      eventBus.emit(EVENTS.NEW_MESSAGE, { type: 'error' });
      console.warn(error);
    }
  };
}

class WebSocketService {
  init() {
    openWsConnection();
  }
}

const wss = new WebSocketService();
// wss.init();
export default wss;