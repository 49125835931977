<template>
  <div class="news-container">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <div class="swiper-pagination" slot="pagination"></div>
      <swiper-slide v-for="news in store.news" :key="news.id" class="swiper-slide">
        <div class="swiper-content">
          <h3 class="news-title">
            {{ news.title }}
          </h3>
          <div class="news-date">
            <span>{{ news.date }}</span>
            <span>{{ news.hour }}</span>
          </div>
          <div v-html="news.content" class="news-content"/>
          <div class="img-container" v-if="cityImage">
            <img :src="cityImage" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import store from '../Store';
import { eventBus, EVENTS } from '../services/EventBus';
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

function cleanUpSpecialChars(str) {
  return (
    str
      .replace(/[àá]/g, 'a')
      .replace(/[éè]/g, 'e')
      .replace(/[ì]/g, 'i')
      .replace(/[ò]/g, 'o')
      .replace(/[ù]/g, 'u')
      //.... all the rest
      .replace(/[^a-z0-9]/gi, '')
  ); // final clean up
}

export default {
  name: 'News',
  props: {
    initialArticle: { type: Object, default: () => {} }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      store: store,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet'
        }
      }
    };
  },
  computed: {
    cityImage() {
      let city = store.currentCity.name;
      city = cleanUpSpecialChars(city);
      city = city.toLowerCase();
      try {
        return require(`../assets/img/loghi_cn/${city}.png`);
      } catch (error) {
        console.warn(error);
      }
      return null;
    },
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null;
    }
  },
  mounted() {
    this.eventId = eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
        this.swiper.slidePrev(1000);
      } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
        this.swiper.slideNext(1000);
      } else if (key === 'ArrowUp' || key === window.KeyEvent.VK_UP) {
        document.getElementsByClassName(
          'swiper-slide-active'
        )[0].scrollTop -= 20;
      } else if (key === 'ArrowDown' || key === window.KeyEvent.VK_DOWN) {
        document.getElementsByClassName(
          'swiper-slide-active'
        )[0].scrollTop += 20;
      }
    });
  },
  destroyed() {
    eventBus.off(EVENTS.KEY_DOWN, this.eventId);
  }
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper-bundle.css';

.news-container {
  height: 100%;
  width: 100%;
  padding-left: 20px;

  .swiper {
    height: 100%;
    width: 100%;
    margin-right: 10%;

    .swiper-slide::-webkit-scrollbar {
      display: none;
    }

    .swiper-slide {
      height: calc(100% - 60px);
      margin-top: 60px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .swiper-content {
        padding-right: 40px;
        height: calc(100% - 40px);

        .news-title {
          font-weight: bold;
          font-size: 30px;
          line-height: 40px;
          color: #000000;
          margin: 20px 0;
        }

        .news-date {
          font-weight: bold;
          font-size: 25px;
          line-height: 37px;
          color: #000000;
          display: flex;
          margin-bottom: 14px;

          span + span {
            margin-left: 10px;
          }
        }

        .news-content {
          font-weight: 500;
          font-size: 25px;
          line-height: 37px;
          color: #000000;
          word-break: break-all;

          /deep/ {
            h1,
            h2,
            h3,
            h4,
            h5 {
              font-size: 24px;
              font-weight: 400;
              margin: 2px 0 10px 0;
              font-family: 'Gilroy', sans-serif;
            }
            a {
              color: inherit;
              text-decoration: none;
            }
            p {
              margin: 0;
            }
            img {
              width: 100%;
            }
            iframe {
              width: 100%;
            }
          }
        }

        .img-container {
          width: 100%;
          display: flex;
          justify-content: right;
          margin: 20px 0;

          img {
            width: 40%;
            height: 40%;
          }
        }
      }
    }

    .swiper-pagination {
      top: 20px;
      width: 90%;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      --swiper-pagination-bullet-height: 12px;
      --swiper-pagination-bullet-width: 12px;
      --swiper-pagination-color: yellow;
      --swiper-pagination-bullet-inactive-color: #808080;
      --swiper-pagination-bullet-inactive-opacity: 1;
    }
  }
}
</style>