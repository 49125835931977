function _sendRequest(url, fetchConfig, params) {
  let paramsStrings = [];
  if (params) {
    for (const key in params) {
      paramsStrings.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }
    url += '?' + paramsStrings.join('&');
  }
  const req = new Request(url, fetchConfig);
  return fetch(req).catch(e => {
    console.warn(url);
    console.warn(e);
  });
}
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

class HttpService {
  get(url, params) {
    const fetchConfig = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      cache: 'default'
    };
    return _sendRequest(url, fetchConfig, params).then(resp => resp.json()).then(resp => resp.data || resp)
      .catch(e => {
        console.warn(url);
        console.warn(e);
      });
  }
  syncGet(url, cb) {
    let request = new XMLHttpRequest();
    request.open('GET', url, false);  // `false` makes the request synchronous
    request.send(null);

    if (request.status === 200) {
      // console.log(request.responseText);
      return cb(JSON.parse(request.response));
    }


  }

  post(url, obj) {
    const fetchConfig = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(obj)
    };
    return _sendRequest(url, fetchConfig)
      .then(resp => resp.json());
  }
}
export default new HttpService();