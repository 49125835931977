var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.fullPolicy)?_c('div',{staticClass:"cookie-policy-full"},[_vm._m(0),_c('div',{staticClass:"cookie-flags"},_vm._l((_vm.cookieFlags),function(cookieFlag,index){return _c('div',{key:cookieFlag.tile,staticClass:"cookie-flag",class:{ selected: index == _vm.selectedIdx }},[_c('div',[_c('h3',[_vm._v(_vm._s(cookieFlag.title))]),_c('div',[_vm._v(_vm._s(cookieFlag.text))])]),_c('div',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(cookieFlag.enabled),expression:"cookieFlag.enabled"}],attrs:{"disabled":cookieFlag.locked,"type":"checkbox"},domProps:{"checked":Array.isArray(cookieFlag.enabled)?_vm._i(cookieFlag.enabled,null)>-1:(cookieFlag.enabled)},on:{"change":function($event){var $$a=cookieFlag.enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(cookieFlag, "enabled", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(cookieFlag, "enabled", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(cookieFlag, "enabled", $$c)}}}})])])}),0),_c('div',{staticClass:"cookie-footer"},[_c('button',{class:{
        selected:
          this.cookieFlags.length == _vm.selectedIdx && _vm.selectedButton === 0,
      }},[_vm._v(" Visualizza privacy policy completa ")]),_c('button',{class:{
        selected:
          this.cookieFlags.length == _vm.selectedIdx && _vm.selectedButton === 1,
      }},[_vm._v(" Salva e continua ")])])]):_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('CookiePolicyReader')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cookie-header"},[_c('h3',[_vm._v("Impostazioni di tracciamento")]),_c('div',[_vm._v(" Il seguente pannello ti consente di esprimere le tue preferenze di consenso alle tecnologie di tracciamento che adottiamo per offrire le funzionalità e svolgere le attività sotto descritte. Per ottenere ulteriori informazioni in merito all'utilità e al funzionamento di tali strumenti di tracciamento, fai riferimento alla Gestione dei Cookie. Puoi rivedere e modificare le tue scelte in qualsiasi momento. ")])])
}]

export { render, staticRenderFns }