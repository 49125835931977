import http from './services/Http.service';
import config from './config';
let switchModeTimeout;

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
let city = null;
if (!localStorage.getItem('uuid')) {
  localStorage.setItem('uuid', uuidv4());
}

if (localStorage.getItem('city')) {
  city = JSON.parse(localStorage.getItem('city'));
}

class RDSStore {
  currentCity;
  widget;
  news = [];
  mode = 'dark';
  instagramContents = [];
  currentBio = null;
  currentSong = null;
  currentLyrics = null;
  videoclipUrl = null;
  currentContentType = 'link';
  rightContent = null;
  isPlayingVideo = false;
  search = false;
  fullpage = false;
  kitchen = '';
  showcase = '';

  uuid = localStorage.getItem('uuid');
  setCity(city) {
    console.log('setCity', this.currentCity, city);
    this.currentCity = city;
    if (city.hasNews) {
      loadNews(city);
    } else {
      this.news = [];
    }
  }

  async getSunTimes() {
    let resp;
    try {
      resp = await http.get(config.suntimesUrl);
      if (!resp) {
        throw new Error('no resp');
      }
    } catch (error) {
      console.warn(error);
      const d = new Date();
      d.setHours(16);
      d.setMinutes(47);
      const sunset = d.toISOString();
      d.setHours(4);
      d.setMinutes(9);
      const sunrise = d.toISOString();
      resp = {
        sunset: sunset,
        sunrise: sunrise
      };
    }

    if (switchModeTimeout != null) {
      clearTimeout(switchModeTimeout);
    }
    let mode = 'dark';
    if (new Date(resp.sunrise) > new Date()) {
      mode = 'dark';
      switchModeTimeout = setTimeout(() => {
        this.getSunTimes();
      }, new Date(resp.sunrise).getTime() - new Date().getTime());
    } else if (new Date(resp.sunset) > new Date()) {
      mode = 'light';
      console.log(new Date(resp.sunset).getTime() - new Date().getTime());

      switchModeTimeout = setTimeout(() => {
        this.getSunTimes();
      }, new Date(resp.sunset).getTime() - new Date().getTime());
    } else {
      const d = new Date();
      d.setHours(0);
      d.setMinutes(0);
      d.setDate(d.getDate() + 1);
      mode = 'dark';
      switchModeTimeout = setTimeout(() => {
        this.getSunTimes();
      }, new Date(d).getTime() - new Date().getTime());
    }
    this.mode = mode;
  }
  loadKitchenSound() {
    loadKitchenSound();
  }
  loadShowcase() {
    loadShowcase();
  }
  loadTopChart() {
    loadTopChart();
  }
}

let store = new RDSStore();
if (city) {
  store.setCity(city);
}

store.getSunTimes();
// if (!localStorage.getItem('kitchenSound')) {
loadKitchenSound();
// }
// if (!localStorage.getItem('showcase')) {
loadShowcase();
loadTopChart();
// }

// let store = {
//   currentCity: city,
//   news: [],
//   instagramContents: [],
//   currentBio: null,
//   currentSong: null,
//   currentLyrics: null,
//   videoclipUrl: null,
//   currentContentType: 'link',
//   rightContent: null,
//   isPlayingVideo: false,
//   uuid: localStorage.getItem('uuid'),

// };

function loadNews(city) {
  http
    .get(config.rdsNewsURL, { city: city.name })
    .then(r => r.objects)
    .then(nn =>
      nn.filter(n => {
        if (n.categories.map(c => c.name).indexOf('cronaca') != -1) {
          console.log('scartata cronaca', n);
          return false;
        }
        return true;
      })
    )
    .then(nn => {
      store.news = nn.map(n => {
        const date = new Date(n.created_at);
        const dateString = date
          .toLocaleString('it-it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
          .replace(/\//g, '.');
        const hourDateString = date.toLocaleString('it-it', {
          hour: '2-digit',
          minute: '2-digit'
        });
        let content = n.content;
        // var re = /<a\s.*?href=["']([^"']*?#show-gallery[^"']*?)[^>]*>(.*?)<\/a>/g;
        // var subst = '$2';
        content = content.replace(/href="(.*?)"/g, 'href="#"');
        return {
          id: n.id,
          title: n.title,
          content: content,
          date: dateString,
          hour: hourDateString
        };
      });
    })
    .catch(err => console.warn(err));
}

function loadTopChart() {
  http
    .get(config.getVod, { type: 3 }, function (resp) {
      console.log('top chart', resp);
    })
    .then(value => {
      try {
        store.topChart = value.posts;
      } catch (error) {
        console.log(error);
      }
    });
}

function loadKitchenSound() {
  http
    .get(config.kitchenSound, { limit: 10 }, function (resp) {
      console.log('KITCHEN', resp);
    })
    .then(value => {
      try {
        store.kitchen = value.posts;
      } catch (error) {
        console.warn(error);
      }
    });
}

function loadShowcase() {
  http
    .get(config.showcase, {}, function (resp) {
      console.log(resp);
    })
    .then(value => {
      try {
        store.showcase = value.posts;
      } catch (error) {
        console.warn(error);
      }
    });
}

// loadKitchenSound();
export default store;
