<template>
    <div :class="keyboardClass"></div>
</template>

<script>


  import Keyboard from "simple-keyboard";
  import keyNavigation from "simple-keyboard-key-navigation";
  import "simple-keyboard/build/css/index.css";
  import store from '../Store';

  function handleKeyDown(e) {
    e.preventDefault();
    const key = e.key || e.keyCode;

    if (key === "ArrowUp" || key === window.KeyEvent.VK_UP) this.keyboard.modules.keyNavigation.up();
    else if (key === "ArrowDown" || key === window.KeyEvent.VK_DOWN) this.keyboard.modules.keyNavigation.down();
    else if (key === "ArrowLeft" || key === window.KeyEvent.VK_LEFT) this.keyboard.modules.keyNavigation.left();
    else if (key === "ArrowRight" || key === window.KeyEvent.VK_RIGHT) this.keyboard.modules.keyNavigation.right();
    else if (key === "Enter" || key === window.KeyEvent.VK_ENTER) this.keyboard.modules.keyNavigation.press();
  }

  export default {
    name: "SimpleKeyboard",
    props: {
      keyboardClass: {
        default: "simple-keyboard",
        type: String
      },
      input: {
        type: String
      }
    },
    data: () => ({
      keyboard: null,
    }),
    mounted() {

      document.removeEventListener(
        "keydown",
        handleKeyDown.bind(this),
        false
      );
      this.keyboard = new Keyboard(this.keyboardClass, {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        enableKeyNavigation: true,
        modules: [
          keyNavigation
        ],
        theme: "hg-theme-default hg-layout-default myTheme " + store.mode,
        layout: {
          'default': [
            'q w e r t y u i o p {bksp}',
            'a s d f g h j k l {enter}',
            '{shift} z x c v b n m {shift}',
            '/ {space} .'
          ],
          'shift': [
            'Q W E R T Y U I O P {bksp}',
            'A S D F G H J K L {enter}',
            '{shift} Z X C V B N M {shift}',
            '/ {space} .'
          ]
        },
        buttonTheme: [
          {
            class: "hg-custom",
            // button: "{bksp} {tab} q w e r t y u i o p [ ] {lock} a s d f g h j k l ; ' {enter} {shift} z x c v b n m , . / {shift} .com @ {space}"
            buttons: "{bksp} {tab} q w e r t y u i o p [ ] {lock} a s d f g h j k l ; ' {enter} {shift} z x c v b n m , . / {shift} .com @ {space} Q W E R T Y U I O P A S D F G H J K L Z X C V B N M"
          }
        ],
        display: {
          '{bksp}': ' ',
          '{enter}': ' ',
          '{shift}': ' ',
          '{space}': ' ',

        }

      });



    },
    created() {
      const vm = this;
      document.addEventListener(
        "keydown",
        handleKeyDown.bind(vm),
        false
      );
    },
    destroyed() {
      const vm = this;
      document.removeEventListener(
        "keydown",
        handleKeyDown.bind(vm),
        false
      );
    },
    methods: {
      onChange(input) {
        this.$emit("onChange", input);
      },
      onKeyPress(button) {
        this.$emit("onKeyPress", button);

        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") this.handleShift();
      },
      handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
          layoutName: shiftToggle
        });

      }


    },
    watch: {
      input(input) {
        this.keyboard.setInput(input);
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
