class CookieService {
  getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        try {
          const parsedString = JSON.parse(c.substring(name.length, c.length));
          if (typeof parsedString === 'object') {
            return parsedString;
          } else {
            return c.substring(name.length, c.length);
          }
        } catch (error) {
          console.warn(error);
        }
        return c.substring(name.length, c.length);
      }
    }

    return null;
  }
  setCookie(cname, cvalue, exdays) {
    if (typeof cvalue === 'object') {
      cvalue = JSON.stringify(cvalue);
    }
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}


export default new CookieService();