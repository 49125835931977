<template>
  <div
    id="app"
    :class="store.mode"
    style="width: 100vw; height: 100vh; overflow: hidden"
  >
    <div id="test" v-if="showTest">TEST {{ host }}</div>
    <RightBlock />
    <MenuBar v-if="cookieEnabled" />
    <CookiePolicyBar v-else-if="!showFullCookiePage" />
    <Videoclip />
    <Like />
    <CookiePolicyFull v-if="showFullCookiePage" />
    <FullPage v-show="store.fullpage" :isVisible="store.fullpage" />
    <Search />
  </div>
</template>

<script>
import cookieService from './services/CookieService';
import MenuBar from './components/MenuBar';
import CookiePolicyBar from './components/CookiePolicyBar';
import CookiePolicyFull from './components/CookiePolicyFull';
import RightBlock from './components/RightBlock';
import Videoclip from './components/Videoclip';
import FullPage from './components/FullPage';
import Search from './components/Search';
import Like from './components/Like';
import store from './Store';
import { eventBus, EVENTS } from './services/EventBus';
export default {
  name: 'App',
  data() {
    return {
      showTest:
        window.location.host === 'hbbtv-dev.rds.radio' ||
        window.location.host === 'localhost:8080',
      store: store,
      host: window.location.host,

      // cookieEnabled: true,
      showFullCookiePage: false
    };
  },
  computed: {
    cookieEnabled() {
      return cookieService.getCookie('_rds_cookie_enabled');
    }
  },
  components: {
    MenuBar,
    CookiePolicyBar,
    CookiePolicyFull,
    RightBlock,
    Videoclip,
    Like,
    FullPage,
    Search
  },
  created() {
    console.log(
      'cookieService.getCookie',
      !!cookieService.getCookie('_rds_cookie_enabled')
    );
    eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key == 'Backspace' || key == window.KeyEvent.VK_BACK) {
        if (store.isPlayingVideo || store.videoFullscreen) {
          eventBus.emit(EVENTS.HIDE_VIDEO);
          return;
        }
        eventBus.emit(EVENTS.HIDE_RIGHT_BLOK);
        eventBus.emit(EVENTS.HIDE_MENU);
        eventBus.emit(EVENTS.HIDE_SEARCH);
        eventBus.emit(EVENTS.HIDE_FULL_PAGE);
      }
    });
    eventBus.on(EVENTS.SHOW_COOKIE_PAGE, () => {
      this.showFullCookiePage = true;
      eventBus.emit(EVENTS.HIDE_MENU);
    });
    eventBus.on(EVENTS.HIDE_COOKIE_PAGE, () => {
      this.showFullCookiePage = false;
    });
  },
  destroyed() {}
};
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s;
}

.slide-up-enter {
  transform: translateY(100%);
}

.slide-up-leave-to {
  transform: translateY(100%);
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.4s;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}
#test {
  position: absolute;
  left: 0;
  top: 0;
  color: red;
}
</style>
