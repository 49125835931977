<template>
  <transition name="slide-up">
    <div v-if="currentArticle">
      <div class="news-preview-content">
        <h3>{{ currentArticle.title }}</h3>
        <div class="action-container">
          <span>{{ source }}</span>
          <span class="action">
            <span>OK</span>
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import config from '../config';
import { eventBus, EVENTS } from '../services/EventBus';
import store from '../Store';
let eventId;
export default {
  name: 'NewsPreview',
  props: ['currentArticle'],
  computed: {
    source() {
      let city = config.cities.find(c => c.name == store.currentCity.name);
      return city.source;
    }
  },
  methods: {
    showArticle() {
      console.log('----');
      eventBus.emit(EVENTS.SHOW_RIGHT_BLOCK, {
        widget: 'news',
        color: 'yellow',
        param: this.currentArticle
      });
    }
  },
  created() {
    console.log('mounted');
    eventId = eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key == 'Enter' || key == window.KeyEvent.VK_ENTER) {
        this.showArticle();
      }
    });
  },
  destroyed() {
    console.log('unmounted');
    eventBus.off(EVENTS.KEY_DOWN, eventId);
  }
};
</script>
<style lang="scss" scoped>
.news-preview-content {
  margin: auto;
  font-size: 23px;
  font-family: 'Gilroy', sans-serif;
  // font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  width: 100%;
  border-bottom: none;
}
.action-container {
  display: flex;
  & > span:nth-child(1) {
    margin-left: auto;
    margin-right: 10px;
    margin-top: -1px;
  }
  span.action {
    border: black solid 1px;
    border-radius: 50%;
    font-size: 0.6em;
    padding: 4px;
    display: inline-block;
    span {
      max-width: 1.15em;
      max-height: 1.15em;
    }
    // width: 10%;
    // max-width: 1.15em;
    // max-height: 1.15em;
    // text-align: center;
    // padding-bottom: 5px;
    // padding-right: 5px;
  }
}
h3 {
  margin-bottom: 8px;
  font-size: 23px;
  text-align: justify;
  font-size: 23px;
  font-family: 'Gilroy', sans-serif;
  // font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
}
</style>