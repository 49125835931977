<template>
    <div v-if="store.search" id="pageRicerca" :class="[store.mode]">
        <div class="ricerca_cont">
            <div :class="['cont_cerca', store.mode]">
                <div class="big_cerca">Cerca</div>
                <input :value="input"
                        class="input custom_input"
                        @input="onInputChange"
                        placeholder=""
                        id="input-ricerca">

            </div>
            <div :class="['cont_key', store.mode]">
                <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
            </div>
        </div>
    </div>
</template>

<script>

  // import SimpleKeyboard from './components/SimpleKeyboard';
  import {eventBus, EVENTS} from "@/services/EventBus";
  import SimpleKeyboard from "./SimpleKeyboard";
  import store from '../Store';

  let hideSearch;

  export default {
    name: "Search",
    components: {SimpleKeyboard},
    data: () => ({
      keyboard: null,
      search: false,
      input: "",
      store: store
    }),
    mounted() {
      eventBus.on(EVENTS.SHOW_SEARCH,()=> {
        store.search= true;
        this.input = "";
      });
    },
    created() {
      hideSearch = eventBus.on(EVENTS.HIDE_SEARCH, () => {
        store.search = false;
      });
    },
    destroyed() {
      eventBus.off(EVENTS.HIDE_SEARCH, hideSearch);
    },
    methods: {
      onChange(input) {
        this.input = input;
      },
      onKeyPress(button) {
        if(button == "{enter}" || button == "Enter"){
          store.fullpage=true;
          eventBus.emit(EVENTS.SHOW_FULL_PAGE, {widget: ["ricerca", this.input]});
        }
      },
      onInputChange(input) {
        this.input = input.target.value;
      }
    }
  };
</script>


<style scoped lang="scss">
#pageRicerca{
    font-family: 'Gilroy', sans-serif;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .ricerca_cont{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .cont_cerca{
            padding: 100px;
            height: 34%;
            &.dark{
                background: linear-gradient(200deg, rgba(0, 0, 0, 0) 0%, rgba(15, 78, 155, 0.4) 19%, rgba(15, 78, 155, 0.97) 46%, #0b3262 100%);
                .big_cerca{
                    font-size: 60px;
                    font-weight: 700;
                    color: white;
                }
                .custom_input{
                    background: transparent;
                    border: none;
                    outline: none;
                    font-size: 28px;
                    color: white;
                    padding: 50px 0;
                    border-bottom: 2px solid #ffffff24;
                    width: 100%;
                }
            }
            &.light{
                background: linear-gradient(200deg, rgba(0, 0, 0, 0) 0%, rgba(242, 244, 247, 0.4) 19%, rgba(242, 244, 247, 0.97) 46% , rgba(242, 244, 247, 1));
                .big_cerca{
                    font-size: 60px;
                    font-weight: 700;
                    color: #1a4e9b;
                }
                .custom_input{
                    background: transparent;
                    border: none;
                    outline: none;
                    font-size: 28px;
                    color: #1a4e9b;
                    padding-bottom: 50px;
                    border-bottom: 2px solid #1a4e9b52;
                    width: 100%;
                }
            }



        }
        .cont_key{
            height: 40%;
            width: 100%;
            margin: 0 auto;
            background: #e1e4ea;
            &.dark{
                background: #e1e4ea;
            }
            &.light{
                background: #0F4E9B;
            }
            display: flex;
           .simple-keyboard.myTheme.hg-layout-default{
               height: auto;
               width: 60%;
               margin: 20px auto;
               background: transparent;

               font-size: 20px;

               .hg-custom{
                   height: calc(327px / 5);
                   border-radius: 0;
               }
           }
        }

    }

}
</style>

<style>
    .simple-keyboard.myTheme .hg-rows {
        height: 100%;
    }
    .simple-keyboard.myTheme .hg-rows .hg-row{
        height: calc(100% / 4);
    }
    .simple-keyboard.myTheme .hg-rows .hg-row .hg-custom{
        height: 100%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 2px;
        box-shadow: none;
        border: none;

    }
    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-custom{
        background: #ffffff73;
        color: #37474E;
    }
    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-custom{
        background: #15226e5c;
        color: white;
    }
    .simple-keyboard.myTheme .hg-rows .hg-row .hg-custom[data-skbtnuid="default-r3b1"]{
        width: 62%;
    }
    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-keyMarker{
        background: #3d60a1;
    }
    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-keyMarker {
        background: #cecdcd;
    }
    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-button.hg-functionBtn.hg-button-enter.hg-custom.hg-keyMarker[data-skbtnuid="default-r1b9"] {
        background-color: #98c1e9;
    }
    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-button.hg-functionBtn.hg-button-enter.hg-custom.hg-keyMarker[data-skbtnuid="default-r1b9"] {
        background-color: #5e7289;
    }

    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-custom[data-skbtnuid="default-r1b9"]{ /*icona enter*/
        background: #0F4E9B;
        background-image: url(https://media.rds.it/images/hbbtv/Invio_dark.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 11%;
    }
    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-custom.hg-button-shift{ /*caps lock*/
        background-image: url(https://media.rds.it/images/hbbtv/Caps_dark.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25%;
    }
    .simple-keyboard.myTheme.dark .hg-rows .hg-row .hg-custom.hg-button-bksp{ /*backspace*/
        background-image: url(https://media.rds.it/images/hbbtv/Cncella_dark.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 38%;
    }


    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-custom[data-skbtnuid="default-r1b9"]{ /*icona enter*/
        background: #ecedee;
        color: white;
        background-image: url(https://media.rds.it/images/hbbtv/invio.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 11%;
    }

    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-custom.hg-button-shift{ /*caps lock*/
        background-image: url(https://media.rds.it/images/hbbtv/Caps.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25%;
    }

    .simple-keyboard.myTheme.light .hg-rows .hg-row .hg-custom.hg-button-bksp{ /*backspace*/
        background-image: url(https://media.rds.it/images/hbbtv/Cancella.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 38%;
    }




    .simple-keyboard.myTheme .hg-rows .hg-row .hg-custom.hg-keyMarker[data-skbtnuid="default-r1b9"]{
        /*background-color: red;*/
    }


</style>