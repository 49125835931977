<template>
  <div class="lyrics-box">
    <div class="lyrics-box__title-container">
      <h2 class="lyrics-box__title">
        {{currentSong}}
      </h2>
    </div>
    <div class="lyrics-box__text-container">
      <span class="lyrics-box__text" v-html="currentLyrics"></span>
    </div>
  </div>
</template>

<script>
import store from '../Store';
import { eventBus, EVENTS } from '../services/EventBus';

export default {
  name: 'Lyrics',
  data() {
    return {
      store: store,
      currentLyrics: store.currentLyrics,
      currentSong: store.currentSong.title
    };
  },
  mounted() {
    this.currentLyrics = store.currentLyrics.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.eventId = eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key === 'ArrowUp' || key === window.KeyEvent.VK_UP) {
        if (document.getElementsByClassName('lyrics-box__text-container')[0]) {
          document.getElementsByClassName(
            'lyrics-box__text-container'
          )[0].scrollTop -= 20;
        }
      } else if (key === 'ArrowDown' || key === window.KeyEvent.VK_DOWN) {
        if (document.getElementsByClassName('lyrics-box__text-container')[0]) {
          document.getElementsByClassName(
            'lyrics-box__text-container'
          )[0].scrollTop += 20;
        }
      }
    });
  },
  destroyed() {
    eventBus.off(EVENTS.KEY_DOWN, this.eventId);
  }
};
</script>

<style lang="scss" scoped>
.lyrics-box {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  overflow: hidden;

  &__title-container {
    margin: 20px 0;
    height: 82px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: #000;
    margin: 0;
  }

  &__text-container {
    height: calc(100% - 150px);
    overflow-y: auto;
    background: linear-gradient(
      center top,
      rgba(255, 255, 255, 0) 20%,
      #ffffff 95%
    );
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__text-container::-webkit-scrollbar {
    display: none;
  }

  &__text {
    font-size: 25px;
    line-height: 35px;
    color: #000;
  }
}
</style>