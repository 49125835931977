<template>
  <div></div>
</template>
<script>
import { eventBus, EVENTS } from '../services/EventBus';
// import CONFIG from '../config';
const $ = window.$;
let sendLike = false;

export default {
  name: 'Like',
  mounted() {
    eventBus.on(EVENTS.LIKE, () => {
      like();
    });
  },
};
let counterheart = 0;
function like() {
  var me = counterheart;
  counterheart++;
  $('#bar-down-box-red').prepend(
    '<div id="heart_' + me + '" class="bar-down-box-button heart"></div>'
  );

  $('#heart_' + me).animate(
    {
      bottom: '100vh',
      opacity: 0,
      left: parseInt(Math.random() * 1280 + 0),
    },
    3000,
    function () {
      $('#heart_' + me).remove();
    }
  );

  // $.getJSON(CONFIG.rdsLikeURL,function(resp){console.log(resp);});
  if (!sendLike) {
    return;
  }
  // sendLike = false;
  // $.ajax({
  //   url: CONFIG.rdsLikeURL,
  //   dataType: 'json',
  //   type: 'POST',
  //   timeout: 10000,
  //   async: true,
  //   data: JSON.stringify({ uuid: localStorage.getItem('uuid') }),
  //   success: function (resp) {
  //     console.log(resp);
  //   },
  //   error: function (jqXHR, textStatus, errorThrown) {},
  // });
}
</script>
<style lang="scss">
.heart {
  background-image: url('../assets/img/heart.png');
  position: absolute;
  bottom: 35px;
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: contain;
}
</style>