<template>
  <img v-if="!loaded" src="../assets/img/loading.gif" />
  <img v-else :src="src" />
</template>
<script>
export default {
  name: 'RdsImg',
  props: ['src'],
  data() {
    return {
      loaded: false
    };
  },
  mounted() {
    const vm = this;
    const img = new Image();
    img.src = this.src;
    img.onload = function() {
      vm.loaded = true;
    };
  }
};
</script>
<style lang="scss" scoped>
</style>
