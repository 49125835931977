var EMOJI_MAP = {
  "u0023_20e3": "emoji_u0023_20e3.svg",
  "u0023": "emoji_u0023.svg",
  "u002a_20e3": "emoji_u002a_20e3.svg",
  "u002a": "emoji_u002a.svg",
  "u0030_20e3": "emoji_u0030_20e3.svg",
  "u0030": "emoji_u0030.svg",
  "u0031_20e3": "emoji_u0031_20e3.svg",
  "u0031": "emoji_u0031.svg",
  "u0032_20e3": "emoji_u0032_20e3.svg",
  "u0032": "emoji_u0032.svg",
  "u0033_20e3": "emoji_u0033_20e3.svg",
  "u0033": "emoji_u0033.svg",
  "u0034_20e3": "emoji_u0034_20e3.svg",
  "u0034": "emoji_u0034.svg",
  "u0035_20e3": "emoji_u0035_20e3.svg",
  "u0035": "emoji_u0035.svg",
  "u0036_20e3": "emoji_u0036_20e3.svg",
  "u0036": "emoji_u0036.svg",
  "u0037_20e3": "emoji_u0037_20e3.svg",
  "u0037": "emoji_u0037.svg",
  "u0038_20e3": "emoji_u0038_20e3.svg",
  "u0038": "emoji_u0038.svg",
  "u0039_20e3": "emoji_u0039_20e3.svg",
  "u0039": "emoji_u0039.svg",
  "u00a9": "emoji_u00a9.svg",
  "u00ae": "emoji_u00ae.svg",
  "u1f004": "emoji_u1f004.svg",
  "u1f0cf": "emoji_u1f0cf.svg",
  "u1f170": "emoji_u1f170.svg",
  "u1f171": "emoji_u1f171.svg",
  "u1f17e": "emoji_u1f17e.svg",
  "u1f17f": "emoji_u1f17f.svg",
  "u1f18e": "emoji_u1f18e.svg",
  "u1f191": "emoji_u1f191.svg",
  "u1f192": "emoji_u1f192.svg",
  "u1f193": "emoji_u1f193.svg",
  "u1f194": "emoji_u1f194.svg",
  "u1f195": "emoji_u1f195.svg",
  "u1f196": "emoji_u1f196.svg",
  "u1f197": "emoji_u1f197.svg",
  "u1f198": "emoji_u1f198.svg",
  "u1f199": "emoji_u1f199.svg",
  "u1f19a": "emoji_u1f19a.svg",
  "u1f1e6": "emoji_u1f1e6.svg",
  "u1f1e7": "emoji_u1f1e7.svg",
  "u1f1e8_1f1f3": "emoji_u1f1e8_1f1f3.svg",
  "u1f1e8": "emoji_u1f1e8.svg",
  "u1f1e9_1f1ea": "emoji_u1f1e9_1f1ea.svg",
  "u1f1e9": "emoji_u1f1e9.svg",
  "u1f1ea_1f1f8": "emoji_u1f1ea_1f1f8.svg",
  "u1f1ea": "emoji_u1f1ea.svg",
  "u1f1eb_1f1f7": "emoji_u1f1eb_1f1f7.svg",
  "u1f1eb": "emoji_u1f1eb.svg",
  "u1f1ec_1f1e7": "emoji_u1f1ec_1f1e7.svg",
  "u1f1ec": "emoji_u1f1ec.svg",
  "u1f1ed": "emoji_u1f1ed.svg",
  "u1f1ee_1f1f9": "emoji_u1f1ee_1f1f9.svg",
  "u1f1ee": "emoji_u1f1ee.svg",
  "u1f1ef_1f1f5": "emoji_u1f1ef_1f1f5.svg",
  "u1f1ef": "emoji_u1f1ef.svg",
  "u1f1f0_1f1f7": "emoji_u1f1f0_1f1f7.svg",
  "u1f1f0": "emoji_u1f1f0.svg",
  "u1f1f1": "emoji_u1f1f1.svg",
  "u1f1f2": "emoji_u1f1f2.svg",
  "u1f1f3": "emoji_u1f1f3.svg",
  "u1f1f4": "emoji_u1f1f4.svg",
  "u1f1f5": "emoji_u1f1f5.svg",
  "u1f1f6": "emoji_u1f1f6.svg",
  "u1f1f7_1f1fa": "emoji_u1f1f7_1f1fa.svg",
  "u1f1f7": "emoji_u1f1f7.svg",
  "u1f1f8": "emoji_u1f1f8.svg",
  "u1f1f9": "emoji_u1f1f9.svg",
  "u1f1fa_1f1f8": "emoji_u1f1fa_1f1f8.svg",
  "u1f1fa": "emoji_u1f1fa.svg",
  "u1f1fb": "emoji_u1f1fb.svg",
  "u1f1fc": "emoji_u1f1fc.svg",
  "u1f1fd": "emoji_u1f1fd.svg",
  "u1f1fe": "emoji_u1f1fe.svg",
  "u1f1ff": "emoji_u1f1ff.svg",
  "u1f201": "emoji_u1f201.svg",
  "u1f202": "emoji_u1f202.svg",
  "u1f21a": "emoji_u1f21a.svg",
  "u1f22f": "emoji_u1f22f.svg",
  "u1f232": "emoji_u1f232.svg",
  "u1f233": "emoji_u1f233.svg",
  "u1f234": "emoji_u1f234.svg",
  "u1f235": "emoji_u1f235.svg",
  "u1f236": "emoji_u1f236.svg",
  "u1f237": "emoji_u1f237.svg",
  "u1f238": "emoji_u1f238.svg",
  "u1f239": "emoji_u1f239.svg",
  "u1f23a": "emoji_u1f23a.svg",
  "u1f250": "emoji_u1f250.svg",
  "u1f251": "emoji_u1f251.svg",
  "u1f300": "emoji_u1f300.svg",
  "u1f301": "emoji_u1f301.svg",
  "u1f302": "emoji_u1f302.svg",
  "u1f303": "emoji_u1f303.svg",
  "u1f304": "emoji_u1f304.svg",
  "u1f305": "emoji_u1f305.svg",
  "u1f306": "emoji_u1f306.svg",
  "u1f307": "emoji_u1f307.svg",
  "u1f308": "emoji_u1f308.svg",
  "u1f309": "emoji_u1f309.svg",
  "u1f30a": "emoji_u1f30a.svg",
  "u1f30b": "emoji_u1f30b.svg",
  "u1f30c": "emoji_u1f30c.svg",
  "u1f30d": "emoji_u1f30d.svg",
  "u1f30e": "emoji_u1f30e.svg",
  "u1f30f": "emoji_u1f30f.svg",
  "u1f310": "emoji_u1f310.svg",
  "u1f311": "emoji_u1f311.svg",
  "u1f312": "emoji_u1f312.svg",
  "u1f313": "emoji_u1f313.svg",
  "u1f314": "emoji_u1f314.svg",
  "u1f315": "emoji_u1f315.svg",
  "u1f316": "emoji_u1f316.svg",
  "u1f317": "emoji_u1f317.svg",
  "u1f318": "emoji_u1f318.svg",
  "u1f319": "emoji_u1f319.svg",
  "u1f31a": "emoji_u1f31a.svg",
  "u1f31b": "emoji_u1f31b.svg",
  "u1f31c": "emoji_u1f31c.svg",
  "u1f31d": "emoji_u1f31d.svg",
  "u1f31e": "emoji_u1f31e.svg",
  "u1f31f": "emoji_u1f31f.svg",
  "u1f320": "emoji_u1f320.svg",
  "u1f321": "emoji_u1f321.svg",
  "u1f324": "emoji_u1f324.svg",
  "u1f325": "emoji_u1f325.svg",
  "u1f326": "emoji_u1f326.svg",
  "u1f327": "emoji_u1f327.svg",
  "u1f328": "emoji_u1f328.svg",
  "u1f329": "emoji_u1f329.svg",
  "u1f32a": "emoji_u1f32a.svg",
  "u1f32b": "emoji_u1f32b.svg",
  "u1f32c": "emoji_u1f32c.svg",
  "u1f32d": "emoji_u1f32d.svg",
  "u1f32e": "emoji_u1f32e.svg",
  "u1f32f": "emoji_u1f32f.svg",
  "u1f330": "emoji_u1f330.svg",
  "u1f331": "emoji_u1f331.svg",
  "u1f332": "emoji_u1f332.svg",
  "u1f333": "emoji_u1f333.svg",
  "u1f334": "emoji_u1f334.svg",
  "u1f335": "emoji_u1f335.svg",
  "u1f336": "emoji_u1f336.svg",
  "u1f337": "emoji_u1f337.svg",
  "u1f338": "emoji_u1f338.svg",
  "u1f339": "emoji_u1f339.svg",
  "u1f33a": "emoji_u1f33a.svg",
  "u1f33b": "emoji_u1f33b.svg",
  "u1f33c": "emoji_u1f33c.svg",
  "u1f33d": "emoji_u1f33d.svg",
  "u1f33e": "emoji_u1f33e.svg",
  "u1f33f": "emoji_u1f33f.svg",
  "u1f340": "emoji_u1f340.svg",
  "u1f341": "emoji_u1f341.svg",
  "u1f342": "emoji_u1f342.svg",
  "u1f343": "emoji_u1f343.svg",
  "u1f344": "emoji_u1f344.svg",
  "u1f345": "emoji_u1f345.svg",
  "u1f346": "emoji_u1f346.svg",
  "u1f347": "emoji_u1f347.svg",
  "u1f348": "emoji_u1f348.svg",
  "u1f349": "emoji_u1f349.svg",
  "u1f34a": "emoji_u1f34a.svg",
  "u1f34b": "emoji_u1f34b.svg",
  "u1f34c": "emoji_u1f34c.svg",
  "u1f34d": "emoji_u1f34d.svg",
  "u1f34e": "emoji_u1f34e.svg",
  "u1f34f": "emoji_u1f34f.svg",
  "u1f350": "emoji_u1f350.svg",
  "u1f351": "emoji_u1f351.svg",
  "u1f352": "emoji_u1f352.svg",
  "u1f353": "emoji_u1f353.svg",
  "u1f354": "emoji_u1f354.svg",
  "u1f355": "emoji_u1f355.svg",
  "u1f356": "emoji_u1f356.svg",
  "u1f357": "emoji_u1f357.svg",
  "u1f358": "emoji_u1f358.svg",
  "u1f359": "emoji_u1f359.svg",
  "u1f35a": "emoji_u1f35a.svg",
  "u1f35b": "emoji_u1f35b.svg",
  "u1f35c": "emoji_u1f35c.svg",
  "u1f35d": "emoji_u1f35d.svg",
  "u1f35e": "emoji_u1f35e.svg",
  "u1f35f": "emoji_u1f35f.svg",
  "u1f360": "emoji_u1f360.svg",
  "u1f361": "emoji_u1f361.svg",
  "u1f362": "emoji_u1f362.svg",
  "u1f363": "emoji_u1f363.svg",
  "u1f364": "emoji_u1f364.svg",
  "u1f365": "emoji_u1f365.svg",
  "u1f366": "emoji_u1f366.svg",
  "u1f367": "emoji_u1f367.svg",
  "u1f368": "emoji_u1f368.svg",
  "u1f369": "emoji_u1f369.svg",
  "u1f36a": "emoji_u1f36a.svg",
  "u1f36b": "emoji_u1f36b.svg",
  "u1f36c": "emoji_u1f36c.svg",
  "u1f36d": "emoji_u1f36d.svg",
  "u1f36e": "emoji_u1f36e.svg",
  "u1f36f": "emoji_u1f36f.svg",
  "u1f370": "emoji_u1f370.svg",
  "u1f371": "emoji_u1f371.svg",
  "u1f372": "emoji_u1f372.svg",
  "u1f373": "emoji_u1f373.svg",
  "u1f374": "emoji_u1f374.svg",
  "u1f375": "emoji_u1f375.svg",
  "u1f376": "emoji_u1f376.svg",
  "u1f377": "emoji_u1f377.svg",
  "u1f378": "emoji_u1f378.svg",
  "u1f379": "emoji_u1f379.svg",
  "u1f37a": "emoji_u1f37a.svg",
  "u1f37b": "emoji_u1f37b.svg",
  "u1f37c": "emoji_u1f37c.svg",
  "u1f37d": "emoji_u1f37d.svg",
  "u1f37e": "emoji_u1f37e.svg",
  "u1f37f": "emoji_u1f37f.svg",
  "u1f380": "emoji_u1f380.svg",
  "u1f381": "emoji_u1f381.svg",
  "u1f382": "emoji_u1f382.svg",
  "u1f383": "emoji_u1f383.svg",
  "u1f384": "emoji_u1f384.svg",
  "u1f385_1f3fb": "emoji_u1f385_1f3fb.svg",
  "u1f385_1f3fc": "emoji_u1f385_1f3fc.svg",
  "u1f385_1f3fd": "emoji_u1f385_1f3fd.svg",
  "u1f385_1f3fe": "emoji_u1f385_1f3fe.svg",
  "u1f385_1f3ff": "emoji_u1f385_1f3ff.svg",
  "u1f385": "emoji_u1f385.svg",
  "u1f386": "emoji_u1f386.svg",
  "u1f387": "emoji_u1f387.svg",
  "u1f388": "emoji_u1f388.svg",
  "u1f389": "emoji_u1f389.svg",
  "u1f38a": "emoji_u1f38a.svg",
  "u1f38b": "emoji_u1f38b.svg",
  "u1f38c": "emoji_u1f38c.svg",
  "u1f38d": "emoji_u1f38d.svg",
  "u1f38e": "emoji_u1f38e.svg",
  "u1f38f": "emoji_u1f38f.svg",
  "u1f390": "emoji_u1f390.svg",
  "u1f391": "emoji_u1f391.svg",
  "u1f392": "emoji_u1f392.svg",
  "u1f393": "emoji_u1f393.svg",
  "u1f396": "emoji_u1f396.svg",
  "u1f397": "emoji_u1f397.svg",
  "u1f399": "emoji_u1f399.svg",
  "u1f39a": "emoji_u1f39a.svg",
  "u1f39b": "emoji_u1f39b.svg",
  "u1f39e": "emoji_u1f39e.svg",
  "u1f39f": "emoji_u1f39f.svg",
  "u1f3a0": "emoji_u1f3a0.svg",
  "u1f3a1": "emoji_u1f3a1.svg",
  "u1f3a2": "emoji_u1f3a2.svg",
  "u1f3a3": "emoji_u1f3a3.svg",
  "u1f3a4": "emoji_u1f3a4.svg",
  "u1f3a5": "emoji_u1f3a5.svg",
  "u1f3a6": "emoji_u1f3a6.svg",
  "u1f3a7": "emoji_u1f3a7.svg",
  "u1f3a8": "emoji_u1f3a8.svg",
  "u1f3a9": "emoji_u1f3a9.svg",
  "u1f3aa": "emoji_u1f3aa.svg",
  "u1f3ab": "emoji_u1f3ab.svg",
  "u1f3ac": "emoji_u1f3ac.svg",
  "u1f3ad": "emoji_u1f3ad.svg",
  "u1f3ae": "emoji_u1f3ae.svg",
  "u1f3af": "emoji_u1f3af.svg",
  "u1f3b0": "emoji_u1f3b0.svg",
  "u1f3b1": "emoji_u1f3b1.svg",
  "u1f3b2": "emoji_u1f3b2.svg",
  "u1f3b3": "emoji_u1f3b3.svg",
  "u1f3b4": "emoji_u1f3b4.svg",
  "u1f3b5": "emoji_u1f3b5.svg",
  "u1f3b6": "emoji_u1f3b6.svg",
  "u1f3b7": "emoji_u1f3b7.svg",
  "u1f3b8": "emoji_u1f3b8.svg",
  "u1f3b9": "emoji_u1f3b9.svg",
  "u1f3ba": "emoji_u1f3ba.svg",
  "u1f3bb": "emoji_u1f3bb.svg",
  "u1f3bc": "emoji_u1f3bc.svg",
  "u1f3bd": "emoji_u1f3bd.svg",
  "u1f3be": "emoji_u1f3be.svg",
  "u1f3bf": "emoji_u1f3bf.svg",
  "u1f3c0": "emoji_u1f3c0.svg",
  "u1f3c1": "emoji_u1f3c1.svg",
  "u1f3c2": "emoji_u1f3c2.svg",
  "u1f3c3_1f3fb_200d_2640": "emoji_u1f3c3_1f3fb_200d_2640.svg",
  "u1f3c3_1f3fb_200d_2642": "emoji_u1f3c3_1f3fb_200d_2642.svg",
  "u1f3c3_1f3fb": "emoji_u1f3c3_1f3fb.svg",
  "u1f3c3_1f3fc_200d_2640": "emoji_u1f3c3_1f3fc_200d_2640.svg",
  "u1f3c3_1f3fc_200d_2642": "emoji_u1f3c3_1f3fc_200d_2642.svg",
  "u1f3c3_1f3fc": "emoji_u1f3c3_1f3fc.svg",
  "u1f3c3_1f3fd_200d_2640": "emoji_u1f3c3_1f3fd_200d_2640.svg",
  "u1f3c3_1f3fd_200d_2642": "emoji_u1f3c3_1f3fd_200d_2642.svg",
  "u1f3c3_1f3fd": "emoji_u1f3c3_1f3fd.svg",
  "u1f3c3_1f3fe_200d_2640": "emoji_u1f3c3_1f3fe_200d_2640.svg",
  "u1f3c3_1f3fe_200d_2642": "emoji_u1f3c3_1f3fe_200d_2642.svg",
  "u1f3c3_1f3fe": "emoji_u1f3c3_1f3fe.svg",
  "u1f3c3_1f3ff_200d_2640": "emoji_u1f3c3_1f3ff_200d_2640.svg",
  "u1f3c3_1f3ff_200d_2642": "emoji_u1f3c3_1f3ff_200d_2642.svg",
  "u1f3c3_1f3ff": "emoji_u1f3c3_1f3ff.svg",
  "u1f3c3_200d_2640": "emoji_u1f3c3_200d_2640.svg",
  "u1f3c3_200d_2642": "emoji_u1f3c3_200d_2642.svg",
  "u1f3c3": "emoji_u1f3c3.svg",
  "u1f3c4_1f3fb_200d_2640": "emoji_u1f3c4_1f3fb_200d_2640.svg",
  "u1f3c4_1f3fb_200d_2642": "emoji_u1f3c4_1f3fb_200d_2642.svg",
  "u1f3c4_1f3fb": "emoji_u1f3c4_1f3fb.svg",
  "u1f3c4_1f3fc_200d_2640": "emoji_u1f3c4_1f3fc_200d_2640.svg",
  "u1f3c4_1f3fc_200d_2642": "emoji_u1f3c4_1f3fc_200d_2642.svg",
  "u1f3c4_1f3fc": "emoji_u1f3c4_1f3fc.svg",
  "u1f3c4_1f3fd_200d_2640": "emoji_u1f3c4_1f3fd_200d_2640.svg",
  "u1f3c4_1f3fd_200d_2642": "emoji_u1f3c4_1f3fd_200d_2642.svg",
  "u1f3c4_1f3fd": "emoji_u1f3c4_1f3fd.svg",
  "u1f3c4_1f3fe_200d_2640": "emoji_u1f3c4_1f3fe_200d_2640.svg",
  "u1f3c4_1f3fe_200d_2642": "emoji_u1f3c4_1f3fe_200d_2642.svg",
  "u1f3c4_1f3fe": "emoji_u1f3c4_1f3fe.svg",
  "u1f3c4_1f3ff_200d_2640": "emoji_u1f3c4_1f3ff_200d_2640.svg",
  "u1f3c4_1f3ff_200d_2642": "emoji_u1f3c4_1f3ff_200d_2642.svg",
  "u1f3c4_1f3ff": "emoji_u1f3c4_1f3ff.svg",
  "u1f3c4_200d_2640": "emoji_u1f3c4_200d_2640.svg",
  "u1f3c4_200d_2642": "emoji_u1f3c4_200d_2642.svg",
  "u1f3c4": "emoji_u1f3c4.svg",
  "u1f3c5": "emoji_u1f3c5.svg",
  "u1f3c6": "emoji_u1f3c6.svg",
  "u1f3c7": "emoji_u1f3c7.svg",
  "u1f3c8": "emoji_u1f3c8.svg",
  "u1f3c9": "emoji_u1f3c9.svg",
  "u1f3ca_1f3fb_200d_2640": "emoji_u1f3ca_1f3fb_200d_2640.svg",
  "u1f3ca_1f3fb_200d_2642": "emoji_u1f3ca_1f3fb_200d_2642.svg",
  "u1f3ca_1f3fb": "emoji_u1f3ca_1f3fb.svg",
  "u1f3ca_1f3fc_200d_2640": "emoji_u1f3ca_1f3fc_200d_2640.svg",
  "u1f3ca_1f3fc_200d_2642": "emoji_u1f3ca_1f3fc_200d_2642.svg",
  "u1f3ca_1f3fc": "emoji_u1f3ca_1f3fc.svg",
  "u1f3ca_1f3fd_200d_2640": "emoji_u1f3ca_1f3fd_200d_2640.svg",
  "u1f3ca_1f3fd_200d_2642": "emoji_u1f3ca_1f3fd_200d_2642.svg",
  "u1f3ca_1f3fd": "emoji_u1f3ca_1f3fd.svg",
  "u1f3ca_1f3fe_200d_2640": "emoji_u1f3ca_1f3fe_200d_2640.svg",
  "u1f3ca_1f3fe_200d_2642": "emoji_u1f3ca_1f3fe_200d_2642.svg",
  "u1f3ca_1f3fe": "emoji_u1f3ca_1f3fe.svg",
  "u1f3ca_1f3ff_200d_2640": "emoji_u1f3ca_1f3ff_200d_2640.svg",
  "u1f3ca_1f3ff_200d_2642": "emoji_u1f3ca_1f3ff_200d_2642.svg",
  "u1f3ca_1f3ff": "emoji_u1f3ca_1f3ff.svg",
  "u1f3ca_200d_2640": "emoji_u1f3ca_200d_2640.svg",
  "u1f3ca_200d_2642": "emoji_u1f3ca_200d_2642.svg",
  "u1f3ca": "emoji_u1f3ca.svg",
  "u1f3cb_1f3fb_200d_2640": "emoji_u1f3cb_1f3fb_200d_2640.svg",
  "u1f3cb_1f3fb_200d_2642": "emoji_u1f3cb_1f3fb_200d_2642.svg",
  "u1f3cb_1f3fb": "emoji_u1f3cb_1f3fb.svg",
  "u1f3cb_1f3fc_200d_2640": "emoji_u1f3cb_1f3fc_200d_2640.svg",
  "u1f3cb_1f3fc_200d_2642": "emoji_u1f3cb_1f3fc_200d_2642.svg",
  "u1f3cb_1f3fc": "emoji_u1f3cb_1f3fc.svg",
  "u1f3cb_1f3fd_200d_2640": "emoji_u1f3cb_1f3fd_200d_2640.svg",
  "u1f3cb_1f3fd_200d_2642": "emoji_u1f3cb_1f3fd_200d_2642.svg",
  "u1f3cb_1f3fd": "emoji_u1f3cb_1f3fd.svg",
  "u1f3cb_1f3fe_200d_2640": "emoji_u1f3cb_1f3fe_200d_2640.svg",
  "u1f3cb_1f3fe_200d_2642": "emoji_u1f3cb_1f3fe_200d_2642.svg",
  "u1f3cb_1f3fe": "emoji_u1f3cb_1f3fe.svg",
  "u1f3cb_1f3ff_200d_2640": "emoji_u1f3cb_1f3ff_200d_2640.svg",
  "u1f3cb_1f3ff_200d_2642": "emoji_u1f3cb_1f3ff_200d_2642.svg",
  "u1f3cb_1f3ff": "emoji_u1f3cb_1f3ff.svg",
  "u1f3cb_200d_2640": "emoji_u1f3cb_200d_2640.svg",
  "u1f3cb_200d_2642": "emoji_u1f3cb_200d_2642.svg",
  "u1f3cb": "emoji_u1f3cb.svg",
  "u1f3cc_200d_2640": "emoji_u1f3cc_200d_2640.svg",
  "u1f3cc_200d_2642": "emoji_u1f3cc_200d_2642.svg",
  "u1f3cc": "emoji_u1f3cc.svg",
  "u1f3cd": "emoji_u1f3cd.svg",
  "u1f3ce": "emoji_u1f3ce.svg",
  "u1f3cf": "emoji_u1f3cf.svg",
  "u1f3d0": "emoji_u1f3d0.svg",
  "u1f3d1": "emoji_u1f3d1.svg",
  "u1f3d2": "emoji_u1f3d2.svg",
  "u1f3d3": "emoji_u1f3d3.svg",
  "u1f3d4": "emoji_u1f3d4.svg",
  "u1f3d5": "emoji_u1f3d5.svg",
  "u1f3d6": "emoji_u1f3d6.svg",
  "u1f3d7": "emoji_u1f3d7.svg",
  "u1f3d8": "emoji_u1f3d8.svg",
  "u1f3d9": "emoji_u1f3d9.svg",
  "u1f3da": "emoji_u1f3da.svg",
  "u1f3db": "emoji_u1f3db.svg",
  "u1f3dc": "emoji_u1f3dc.svg",
  "u1f3dd": "emoji_u1f3dd.svg",
  "u1f3de": "emoji_u1f3de.svg",
  "u1f3df": "emoji_u1f3df.svg",
  "u1f3e0": "emoji_u1f3e0.svg",
  "u1f3e1": "emoji_u1f3e1.svg",
  "u1f3e2": "emoji_u1f3e2.svg",
  "u1f3e3": "emoji_u1f3e3.svg",
  "u1f3e4": "emoji_u1f3e4.svg",
  "u1f3e5": "emoji_u1f3e5.svg",
  "u1f3e6": "emoji_u1f3e6.svg",
  "u1f3e7": "emoji_u1f3e7.svg",
  "u1f3e8": "emoji_u1f3e8.svg",
  "u1f3e9": "emoji_u1f3e9.svg",
  "u1f3ea": "emoji_u1f3ea.svg",
  "u1f3eb": "emoji_u1f3eb.svg",
  "u1f3ec": "emoji_u1f3ec.svg",
  "u1f3ed": "emoji_u1f3ed.svg",
  "u1f3ee": "emoji_u1f3ee.svg",
  "u1f3ef": "emoji_u1f3ef.svg",
  "u1f3f0": "emoji_u1f3f0.svg",
  "u1f3f3": "emoji_u1f3f3.svg",
  "u1f3f4": "emoji_u1f3f4.svg",
  "u1f3f5": "emoji_u1f3f5.svg",
  "u1f3f7": "emoji_u1f3f7.svg",
  "u1f3f8": "emoji_u1f3f8.svg",
  "u1f3f9": "emoji_u1f3f9.svg",
  "u1f3fa": "emoji_u1f3fa.svg",
  "u1f3fb": "emoji_u1f3fb.svg",
  "u1f3fc": "emoji_u1f3fc.svg",
  "u1f3fd": "emoji_u1f3fd.svg",
  "u1f3fe": "emoji_u1f3fe.svg",
  "u1f3ff": "emoji_u1f3ff.svg",
  "u1f400": "emoji_u1f400.svg",
  "u1f401": "emoji_u1f401.svg",
  "u1f402": "emoji_u1f402.svg",
  "u1f403": "emoji_u1f403.svg",
  "u1f404": "emoji_u1f404.svg",
  "u1f405": "emoji_u1f405.svg",
  "u1f406": "emoji_u1f406.svg",
  "u1f407": "emoji_u1f407.svg",
  "u1f408": "emoji_u1f408.svg",
  "u1f409": "emoji_u1f409.svg",
  "u1f40a": "emoji_u1f40a.svg",
  "u1f40b": "emoji_u1f40b.svg",
  "u1f40c": "emoji_u1f40c.svg",
  "u1f40d": "emoji_u1f40d.svg",
  "u1f40e": "emoji_u1f40e.svg",
  "u1f40f": "emoji_u1f40f.svg",
  "u1f410": "emoji_u1f410.svg",
  "u1f411": "emoji_u1f411.svg",
  "u1f412": "emoji_u1f412.svg",
  "u1f413": "emoji_u1f413.svg",
  "u1f414": "emoji_u1f414.svg",
  "u1f415": "emoji_u1f415.svg",
  "u1f416": "emoji_u1f416.svg",
  "u1f417": "emoji_u1f417.svg",
  "u1f418": "emoji_u1f418.svg",
  "u1f419": "emoji_u1f419.svg",
  "u1f41a": "emoji_u1f41a.svg",
  "u1f41b": "emoji_u1f41b.svg",
  "u1f41c": "emoji_u1f41c.svg",
  "u1f41d": "emoji_u1f41d.svg",
  "u1f41e": "emoji_u1f41e.svg",
  "u1f41f": "emoji_u1f41f.svg",
  "u1f420": "emoji_u1f420.svg",
  "u1f421": "emoji_u1f421.svg",
  "u1f422": "emoji_u1f422.svg",
  "u1f423": "emoji_u1f423.svg",
  "u1f424": "emoji_u1f424.svg",
  "u1f425": "emoji_u1f425.svg",
  "u1f426": "emoji_u1f426.svg",
  "u1f427": "emoji_u1f427.svg",
  "u1f428": "emoji_u1f428.svg",
  "u1f429": "emoji_u1f429.svg",
  "u1f42a": "emoji_u1f42a.svg",
  "u1f42b": "emoji_u1f42b.svg",
  "u1f42c": "emoji_u1f42c.svg",
  "u1f42d": "emoji_u1f42d.svg",
  "u1f42e": "emoji_u1f42e.svg",
  "u1f42f": "emoji_u1f42f.svg",
  "u1f430": "emoji_u1f430.svg",
  "u1f431": "emoji_u1f431.svg",
  "u1f432": "emoji_u1f432.svg",
  "u1f433": "emoji_u1f433.svg",
  "u1f434": "emoji_u1f434.svg",
  "u1f435": "emoji_u1f435.svg",
  "u1f436": "emoji_u1f436.svg",
  "u1f437": "emoji_u1f437.svg",
  "u1f438": "emoji_u1f438.svg",
  "u1f439": "emoji_u1f439.svg",
  "u1f43a": "emoji_u1f43a.svg",
  "u1f43b": "emoji_u1f43b.svg",
  "u1f43c": "emoji_u1f43c.svg",
  "u1f43d": "emoji_u1f43d.svg",
  "u1f43e": "emoji_u1f43e.svg",
  "u1f43f": "emoji_u1f43f.svg",
  "u1f440": "emoji_u1f440.svg",
  "u1f441_200d_1f5e8": "emoji_u1f441_200d_1f5e8.svg",
  "u1f441": "emoji_u1f441.svg",
  "u1f442_1f3fb": "emoji_u1f442_1f3fb.svg",
  "u1f442_1f3fc": "emoji_u1f442_1f3fc.svg",
  "u1f442_1f3fd": "emoji_u1f442_1f3fd.svg",
  "u1f442_1f3fe": "emoji_u1f442_1f3fe.svg",
  "u1f442_1f3ff": "emoji_u1f442_1f3ff.svg",
  "u1f442": "emoji_u1f442.svg",
  "u1f443_1f3fb": "emoji_u1f443_1f3fb.svg",
  "u1f443_1f3fc": "emoji_u1f443_1f3fc.svg",
  "u1f443_1f3fd": "emoji_u1f443_1f3fd.svg",
  "u1f443_1f3fe": "emoji_u1f443_1f3fe.svg",
  "u1f443_1f3ff": "emoji_u1f443_1f3ff.svg",
  "u1f443": "emoji_u1f443.svg",
  "u1f444": "emoji_u1f444.svg",
  "u1f445": "emoji_u1f445.svg",
  "u1f446_1f3fb": "emoji_u1f446_1f3fb.svg",
  "u1f446_1f3fc": "emoji_u1f446_1f3fc.svg",
  "u1f446_1f3fd": "emoji_u1f446_1f3fd.svg",
  "u1f446_1f3fe": "emoji_u1f446_1f3fe.svg",
  "u1f446_1f3ff": "emoji_u1f446_1f3ff.svg",
  "u1f446": "emoji_u1f446.svg",
  "u1f447_1f3fb": "emoji_u1f447_1f3fb.svg",
  "u1f447_1f3fc": "emoji_u1f447_1f3fc.svg",
  "u1f447_1f3fd": "emoji_u1f447_1f3fd.svg",
  "u1f447_1f3fe": "emoji_u1f447_1f3fe.svg",
  "u1f447_1f3ff": "emoji_u1f447_1f3ff.svg",
  "u1f447": "emoji_u1f447.svg",
  "u1f448_1f3fb": "emoji_u1f448_1f3fb.svg",
  "u1f448_1f3fc": "emoji_u1f448_1f3fc.svg",
  "u1f448_1f3fd": "emoji_u1f448_1f3fd.svg",
  "u1f448_1f3fe": "emoji_u1f448_1f3fe.svg",
  "u1f448_1f3ff": "emoji_u1f448_1f3ff.svg",
  "u1f448": "emoji_u1f448.svg",
  "u1f449_1f3fb": "emoji_u1f449_1f3fb.svg",
  "u1f449_1f3fc": "emoji_u1f449_1f3fc.svg",
  "u1f449_1f3fd": "emoji_u1f449_1f3fd.svg",
  "u1f449_1f3fe": "emoji_u1f449_1f3fe.svg",
  "u1f449_1f3ff": "emoji_u1f449_1f3ff.svg",
  "u1f449": "emoji_u1f449.svg",
  "u1f44a_1f3fb": "emoji_u1f44a_1f3fb.svg",
  "u1f44a_1f3fc": "emoji_u1f44a_1f3fc.svg",
  "u1f44a_1f3fd": "emoji_u1f44a_1f3fd.svg",
  "u1f44a_1f3fe": "emoji_u1f44a_1f3fe.svg",
  "u1f44a_1f3ff": "emoji_u1f44a_1f3ff.svg",
  "u1f44a": "emoji_u1f44a.svg",
  "u1f44b_1f3fb": "emoji_u1f44b_1f3fb.svg",
  "u1f44b_1f3fc": "emoji_u1f44b_1f3fc.svg",
  "u1f44b_1f3fd": "emoji_u1f44b_1f3fd.svg",
  "u1f44b_1f3fe": "emoji_u1f44b_1f3fe.svg",
  "u1f44b_1f3ff": "emoji_u1f44b_1f3ff.svg",
  "u1f44b": "emoji_u1f44b.svg",
  "u1f44c_1f3fb": "emoji_u1f44c_1f3fb.svg",
  "u1f44c_1f3fc": "emoji_u1f44c_1f3fc.svg",
  "u1f44c_1f3fd": "emoji_u1f44c_1f3fd.svg",
  "u1f44c_1f3fe": "emoji_u1f44c_1f3fe.svg",
  "u1f44c_1f3ff": "emoji_u1f44c_1f3ff.svg",
  "u1f44c": "emoji_u1f44c.svg",
  "u1f44d_1f3fb": "emoji_u1f44d_1f3fb.svg",
  "u1f44d_1f3fc": "emoji_u1f44d_1f3fc.svg",
  "u1f44d_1f3fd": "emoji_u1f44d_1f3fd.svg",
  "u1f44d_1f3fe": "emoji_u1f44d_1f3fe.svg",
  "u1f44d_1f3ff": "emoji_u1f44d_1f3ff.svg",
  "u1f44d": "emoji_u1f44d.svg",
  "u1f44e_1f3fb": "emoji_u1f44e_1f3fb.svg",
  "u1f44e_1f3fc": "emoji_u1f44e_1f3fc.svg",
  "u1f44e_1f3fd": "emoji_u1f44e_1f3fd.svg",
  "u1f44e_1f3fe": "emoji_u1f44e_1f3fe.svg",
  "u1f44e_1f3ff": "emoji_u1f44e_1f3ff.svg",
  "u1f44e": "emoji_u1f44e.svg",
  "u1f44f_1f3fb": "emoji_u1f44f_1f3fb.svg",
  "u1f44f_1f3fc": "emoji_u1f44f_1f3fc.svg",
  "u1f44f_1f3fd": "emoji_u1f44f_1f3fd.svg",
  "u1f44f_1f3fe": "emoji_u1f44f_1f3fe.svg",
  "u1f44f_1f3ff": "emoji_u1f44f_1f3ff.svg",
  "u1f44f": "emoji_u1f44f.svg",
  "u1f450_1f3fb": "emoji_u1f450_1f3fb.svg",
  "u1f450_1f3fc": "emoji_u1f450_1f3fc.svg",
  "u1f450_1f3fd": "emoji_u1f450_1f3fd.svg",
  "u1f450_1f3fe": "emoji_u1f450_1f3fe.svg",
  "u1f450_1f3ff": "emoji_u1f450_1f3ff.svg",
  "u1f450": "emoji_u1f450.svg",
  "u1f451": "emoji_u1f451.svg",
  "u1f452": "emoji_u1f452.svg",
  "u1f453": "emoji_u1f453.svg",
  "u1f454": "emoji_u1f454.svg",
  "u1f455": "emoji_u1f455.svg",
  "u1f456": "emoji_u1f456.svg",
  "u1f457": "emoji_u1f457.svg",
  "u1f458": "emoji_u1f458.svg",
  "u1f459": "emoji_u1f459.svg",
  "u1f45a": "emoji_u1f45a.svg",
  "u1f45b": "emoji_u1f45b.svg",
  "u1f45c": "emoji_u1f45c.svg",
  "u1f45d": "emoji_u1f45d.svg",
  "u1f45e": "emoji_u1f45e.svg",
  "u1f45f": "emoji_u1f45f.svg",
  "u1f460": "emoji_u1f460.svg",
  "u1f461": "emoji_u1f461.svg",
  "u1f462": "emoji_u1f462.svg",
  "u1f463": "emoji_u1f463.svg",
  "u1f464": "emoji_u1f464.svg",
  "u1f465": "emoji_u1f465.svg",
  "u1f466_1f3fb": "emoji_u1f466_1f3fb.svg",
  "u1f466_1f3fc": "emoji_u1f466_1f3fc.svg",
  "u1f466_1f3fd": "emoji_u1f466_1f3fd.svg",
  "u1f466_1f3fe": "emoji_u1f466_1f3fe.svg",
  "u1f466_1f3ff": "emoji_u1f466_1f3ff.svg",
  "u1f466": "emoji_u1f466.svg",
  "u1f467_1f3fb": "emoji_u1f467_1f3fb.svg",
  "u1f467_1f3fc": "emoji_u1f467_1f3fc.svg",
  "u1f467_1f3fd": "emoji_u1f467_1f3fd.svg",
  "u1f467_1f3fe": "emoji_u1f467_1f3fe.svg",
  "u1f467_1f3ff": "emoji_u1f467_1f3ff.svg",
  "u1f467": "emoji_u1f467.svg",
  "u1f468_1f3fb_200d_1f33e": "emoji_u1f468_1f3fb_200d_1f33e.svg",
  "u1f468_1f3fb_200d_1f373": "emoji_u1f468_1f3fb_200d_1f373.svg",
  "u1f468_1f3fb_200d_1f393": "emoji_u1f468_1f3fb_200d_1f393.svg",
  "u1f468_1f3fb_200d_1f3a4": "emoji_u1f468_1f3fb_200d_1f3a4.svg",
  "u1f468_1f3fb_200d_1f3a8": "emoji_u1f468_1f3fb_200d_1f3a8.svg",
  "u1f468_1f3fb_200d_1f3eb": "emoji_u1f468_1f3fb_200d_1f3eb.svg",
  "u1f468_1f3fb_200d_1f3ed": "emoji_u1f468_1f3fb_200d_1f3ed.svg",
  "u1f468_1f3fb_200d_1f4bb": "emoji_u1f468_1f3fb_200d_1f4bb.svg",
  "u1f468_1f3fb_200d_1f4bc": "emoji_u1f468_1f3fb_200d_1f4bc.svg",
  "u1f468_1f3fb_200d_1f527": "emoji_u1f468_1f3fb_200d_1f527.svg",
  "u1f468_1f3fb_200d_1f52c": "emoji_u1f468_1f3fb_200d_1f52c.svg",
  "u1f468_1f3fb_200d_1f680": "emoji_u1f468_1f3fb_200d_1f680.svg",
  "u1f468_1f3fb_200d_1f692": "emoji_u1f468_1f3fb_200d_1f692.svg",
  "u1f468_1f3fb_200d_2695": "emoji_u1f468_1f3fb_200d_2695.svg",
  "u1f468_1f3fb_200d_2696": "emoji_u1f468_1f3fb_200d_2696.svg",
  "u1f468_1f3fb_200d_2708": "emoji_u1f468_1f3fb_200d_2708.svg",
  "u1f468_1f3fb": "emoji_u1f468_1f3fb.svg",
  "u1f468_1f3fc_200d_1f33e": "emoji_u1f468_1f3fc_200d_1f33e.svg",
  "u1f468_1f3fc_200d_1f373": "emoji_u1f468_1f3fc_200d_1f373.svg",
  "u1f468_1f3fc_200d_1f393": "emoji_u1f468_1f3fc_200d_1f393.svg",
  "u1f468_1f3fc_200d_1f3a4": "emoji_u1f468_1f3fc_200d_1f3a4.svg",
  "u1f468_1f3fc_200d_1f3a8": "emoji_u1f468_1f3fc_200d_1f3a8.svg",
  "u1f468_1f3fc_200d_1f3eb": "emoji_u1f468_1f3fc_200d_1f3eb.svg",
  "u1f468_1f3fc_200d_1f3ed": "emoji_u1f468_1f3fc_200d_1f3ed.svg",
  "u1f468_1f3fc_200d_1f4bb": "emoji_u1f468_1f3fc_200d_1f4bb.svg",
  "u1f468_1f3fc_200d_1f4bc": "emoji_u1f468_1f3fc_200d_1f4bc.svg",
  "u1f468_1f3fc_200d_1f527": "emoji_u1f468_1f3fc_200d_1f527.svg",
  "u1f468_1f3fc_200d_1f52c": "emoji_u1f468_1f3fc_200d_1f52c.svg",
  "u1f468_1f3fc_200d_1f680": "emoji_u1f468_1f3fc_200d_1f680.svg",
  "u1f468_1f3fc_200d_1f692": "emoji_u1f468_1f3fc_200d_1f692.svg",
  "u1f468_1f3fc_200d_2695": "emoji_u1f468_1f3fc_200d_2695.svg",
  "u1f468_1f3fc_200d_2696": "emoji_u1f468_1f3fc_200d_2696.svg",
  "u1f468_1f3fc_200d_2708": "emoji_u1f468_1f3fc_200d_2708.svg",
  "u1f468_1f3fc": "emoji_u1f468_1f3fc.svg",
  "u1f468_1f3fd_200d_1f33e": "emoji_u1f468_1f3fd_200d_1f33e.svg",
  "u1f468_1f3fd_200d_1f373": "emoji_u1f468_1f3fd_200d_1f373.svg",
  "u1f468_1f3fd_200d_1f393": "emoji_u1f468_1f3fd_200d_1f393.svg",
  "u1f468_1f3fd_200d_1f3a4": "emoji_u1f468_1f3fd_200d_1f3a4.svg",
  "u1f468_1f3fd_200d_1f3a8": "emoji_u1f468_1f3fd_200d_1f3a8.svg",
  "u1f468_1f3fd_200d_1f3eb": "emoji_u1f468_1f3fd_200d_1f3eb.svg",
  "u1f468_1f3fd_200d_1f3ed": "emoji_u1f468_1f3fd_200d_1f3ed.svg",
  "u1f468_1f3fd_200d_1f4bb": "emoji_u1f468_1f3fd_200d_1f4bb.svg",
  "u1f468_1f3fd_200d_1f4bc": "emoji_u1f468_1f3fd_200d_1f4bc.svg",
  "u1f468_1f3fd_200d_1f527": "emoji_u1f468_1f3fd_200d_1f527.svg",
  "u1f468_1f3fd_200d_1f52c": "emoji_u1f468_1f3fd_200d_1f52c.svg",
  "u1f468_1f3fd_200d_1f680": "emoji_u1f468_1f3fd_200d_1f680.svg",
  "u1f468_1f3fd_200d_1f692": "emoji_u1f468_1f3fd_200d_1f692.svg",
  "u1f468_1f3fd_200d_2695": "emoji_u1f468_1f3fd_200d_2695.svg",
  "u1f468_1f3fd_200d_2696": "emoji_u1f468_1f3fd_200d_2696.svg",
  "u1f468_1f3fd_200d_2708": "emoji_u1f468_1f3fd_200d_2708.svg",
  "u1f468_1f3fd": "emoji_u1f468_1f3fd.svg",
  "u1f468_1f3fe_200d_1f33e": "emoji_u1f468_1f3fe_200d_1f33e.svg",
  "u1f468_1f3fe_200d_1f373": "emoji_u1f468_1f3fe_200d_1f373.svg",
  "u1f468_1f3fe_200d_1f393": "emoji_u1f468_1f3fe_200d_1f393.svg",
  "u1f468_1f3fe_200d_1f3a4": "emoji_u1f468_1f3fe_200d_1f3a4.svg",
  "u1f468_1f3fe_200d_1f3a8": "emoji_u1f468_1f3fe_200d_1f3a8.svg",
  "u1f468_1f3fe_200d_1f3eb": "emoji_u1f468_1f3fe_200d_1f3eb.svg",
  "u1f468_1f3fe_200d_1f3ed": "emoji_u1f468_1f3fe_200d_1f3ed.svg",
  "u1f468_1f3fe_200d_1f4bb": "emoji_u1f468_1f3fe_200d_1f4bb.svg",
  "u1f468_1f3fe_200d_1f4bc": "emoji_u1f468_1f3fe_200d_1f4bc.svg",
  "u1f468_1f3fe_200d_1f527": "emoji_u1f468_1f3fe_200d_1f527.svg",
  "u1f468_1f3fe_200d_1f52c": "emoji_u1f468_1f3fe_200d_1f52c.svg",
  "u1f468_1f3fe_200d_1f680": "emoji_u1f468_1f3fe_200d_1f680.svg",
  "u1f468_1f3fe_200d_1f692": "emoji_u1f468_1f3fe_200d_1f692.svg",
  "u1f468_1f3fe_200d_2695": "emoji_u1f468_1f3fe_200d_2695.svg",
  "u1f468_1f3fe_200d_2696": "emoji_u1f468_1f3fe_200d_2696.svg",
  "u1f468_1f3fe_200d_2708": "emoji_u1f468_1f3fe_200d_2708.svg",
  "u1f468_1f3fe": "emoji_u1f468_1f3fe.svg",
  "u1f468_1f3ff_200d_1f33e": "emoji_u1f468_1f3ff_200d_1f33e.svg",
  "u1f468_1f3ff_200d_1f373": "emoji_u1f468_1f3ff_200d_1f373.svg",
  "u1f468_1f3ff_200d_1f393": "emoji_u1f468_1f3ff_200d_1f393.svg",
  "u1f468_1f3ff_200d_1f3a4": "emoji_u1f468_1f3ff_200d_1f3a4.svg",
  "u1f468_1f3ff_200d_1f3a8": "emoji_u1f468_1f3ff_200d_1f3a8.svg",
  "u1f468_1f3ff_200d_1f3eb": "emoji_u1f468_1f3ff_200d_1f3eb.svg",
  "u1f468_1f3ff_200d_1f3ed": "emoji_u1f468_1f3ff_200d_1f3ed.svg",
  "u1f468_1f3ff_200d_1f4bb": "emoji_u1f468_1f3ff_200d_1f4bb.svg",
  "u1f468_1f3ff_200d_1f4bc": "emoji_u1f468_1f3ff_200d_1f4bc.svg",
  "u1f468_1f3ff_200d_1f527": "emoji_u1f468_1f3ff_200d_1f527.svg",
  "u1f468_1f3ff_200d_1f52c": "emoji_u1f468_1f3ff_200d_1f52c.svg",
  "u1f468_1f3ff_200d_1f680": "emoji_u1f468_1f3ff_200d_1f680.svg",
  "u1f468_1f3ff_200d_1f692": "emoji_u1f468_1f3ff_200d_1f692.svg",
  "u1f468_1f3ff_200d_2695": "emoji_u1f468_1f3ff_200d_2695.svg",
  "u1f468_1f3ff_200d_2696": "emoji_u1f468_1f3ff_200d_2696.svg",
  "u1f468_1f3ff_200d_2708": "emoji_u1f468_1f3ff_200d_2708.svg",
  "u1f468_1f3ff": "emoji_u1f468_1f3ff.svg",
  "u1f468_200d_1f33e": "emoji_u1f468_200d_1f33e.svg",
  "u1f468_200d_1f373": "emoji_u1f468_200d_1f373.svg",
  "u1f468_200d_1f393": "emoji_u1f468_200d_1f393.svg",
  "u1f468_200d_1f3a4": "emoji_u1f468_200d_1f3a4.svg",
  "u1f468_200d_1f3a8": "emoji_u1f468_200d_1f3a8.svg",
  "u1f468_200d_1f3eb": "emoji_u1f468_200d_1f3eb.svg",
  "u1f468_200d_1f3ed": "emoji_u1f468_200d_1f3ed.svg",
  "u1f468_200d_1f466_200d_1f466": "emoji_u1f468_200d_1f466_200d_1f466.svg",
  "u1f468_200d_1f466": "emoji_u1f468_200d_1f466.svg",
  "u1f468_200d_1f467_200d_1f466": "emoji_u1f468_200d_1f467_200d_1f466.svg",
  "u1f468_200d_1f467_200d_1f467": "emoji_u1f468_200d_1f467_200d_1f467.svg",
  "u1f468_200d_1f467": "emoji_u1f468_200d_1f467.svg",
  "u1f468_200d_1f468_200d_1f466_200d_1f466": "emoji_u1f468_200d_1f468_200d_1f466_200d_1f466.svg",
  "u1f468_200d_1f468_200d_1f466": "emoji_u1f468_200d_1f468_200d_1f466.svg",
  "u1f468_200d_1f468_200d_1f467_200d_1f466": "emoji_u1f468_200d_1f468_200d_1f467_200d_1f466.svg",
  "u1f468_200d_1f468_200d_1f467_200d_1f467": "emoji_u1f468_200d_1f468_200d_1f467_200d_1f467.svg",
  "u1f468_200d_1f468_200d_1f467": "emoji_u1f468_200d_1f468_200d_1f467.svg",
  "u1f468_200d_1f469_200d_1f466_200d_1f466": "emoji_u1f468_200d_1f469_200d_1f466_200d_1f466.svg",
  "u1f468_200d_1f469_200d_1f466": "emoji_u1f468_200d_1f469_200d_1f466.svg",
  "u1f468_200d_1f469_200d_1f467_200d_1f466": "emoji_u1f468_200d_1f469_200d_1f467_200d_1f466.svg",
  "u1f468_200d_1f469_200d_1f467_200d_1f467": "emoji_u1f468_200d_1f469_200d_1f467_200d_1f467.svg",
  "u1f468_200d_1f469_200d_1f467": "emoji_u1f468_200d_1f469_200d_1f467.svg",
  "u1f468_200d_1f4bb": "emoji_u1f468_200d_1f4bb.svg",
  "u1f468_200d_1f4bc": "emoji_u1f468_200d_1f4bc.svg",
  "u1f468_200d_1f527": "emoji_u1f468_200d_1f527.svg",
  "u1f468_200d_1f52c": "emoji_u1f468_200d_1f52c.svg",
  "u1f468_200d_1f680": "emoji_u1f468_200d_1f680.svg",
  "u1f468_200d_1f692": "emoji_u1f468_200d_1f692.svg",
  "u1f468_200d_2695": "emoji_u1f468_200d_2695.svg",
  "u1f468_200d_2696": "emoji_u1f468_200d_2696.svg",
  "u1f468_200d_2708": "emoji_u1f468_200d_2708.svg",
  "u1f468_200d_2764_200d_1f468": "emoji_u1f468_200d_2764_200d_1f468.svg",
  "u1f468_200d_2764_200d_1f48b_200d_1f468": "emoji_u1f468_200d_2764_200d_1f48b_200d_1f468.svg",
  "u1f468": "emoji_u1f468.svg",
  "u1f469_1f3fb_200d_1f33e": "emoji_u1f469_1f3fb_200d_1f33e.svg",
  "u1f469_1f3fb_200d_1f373": "emoji_u1f469_1f3fb_200d_1f373.svg",
  "u1f469_1f3fb_200d_1f393": "emoji_u1f469_1f3fb_200d_1f393.svg",
  "u1f469_1f3fb_200d_1f3a4": "emoji_u1f469_1f3fb_200d_1f3a4.svg",
  "u1f469_1f3fb_200d_1f3a8": "emoji_u1f469_1f3fb_200d_1f3a8.svg",
  "u1f469_1f3fb_200d_1f3eb": "emoji_u1f469_1f3fb_200d_1f3eb.svg",
  "u1f469_1f3fb_200d_1f3ed": "emoji_u1f469_1f3fb_200d_1f3ed.svg",
  "u1f469_1f3fb_200d_1f4bb": "emoji_u1f469_1f3fb_200d_1f4bb.svg",
  "u1f469_1f3fb_200d_1f4bc": "emoji_u1f469_1f3fb_200d_1f4bc.svg",
  "u1f469_1f3fb_200d_1f527": "emoji_u1f469_1f3fb_200d_1f527.svg",
  "u1f469_1f3fb_200d_1f52c": "emoji_u1f469_1f3fb_200d_1f52c.svg",
  "u1f469_1f3fb_200d_1f680": "emoji_u1f469_1f3fb_200d_1f680.svg",
  "u1f469_1f3fb_200d_1f692": "emoji_u1f469_1f3fb_200d_1f692.svg",
  "u1f469_1f3fb_200d_2695": "emoji_u1f469_1f3fb_200d_2695.svg",
  "u1f469_1f3fb_200d_2696": "emoji_u1f469_1f3fb_200d_2696.svg",
  "u1f469_1f3fb_200d_2708": "emoji_u1f469_1f3fb_200d_2708.svg",
  "u1f469_1f3fb": "emoji_u1f469_1f3fb.svg",
  "u1f469_1f3fc_200d_1f33e": "emoji_u1f469_1f3fc_200d_1f33e.svg",
  "u1f469_1f3fc_200d_1f373": "emoji_u1f469_1f3fc_200d_1f373.svg",
  "u1f469_1f3fc_200d_1f393": "emoji_u1f469_1f3fc_200d_1f393.svg",
  "u1f469_1f3fc_200d_1f3a4": "emoji_u1f469_1f3fc_200d_1f3a4.svg",
  "u1f469_1f3fc_200d_1f3a8": "emoji_u1f469_1f3fc_200d_1f3a8.svg",
  "u1f469_1f3fc_200d_1f3eb": "emoji_u1f469_1f3fc_200d_1f3eb.svg",
  "u1f469_1f3fc_200d_1f3ed": "emoji_u1f469_1f3fc_200d_1f3ed.svg",
  "u1f469_1f3fc_200d_1f4bb": "emoji_u1f469_1f3fc_200d_1f4bb.svg",
  "u1f469_1f3fc_200d_1f4bc": "emoji_u1f469_1f3fc_200d_1f4bc.svg",
  "u1f469_1f3fc_200d_1f527": "emoji_u1f469_1f3fc_200d_1f527.svg",
  "u1f469_1f3fc_200d_1f52c": "emoji_u1f469_1f3fc_200d_1f52c.svg",
  "u1f469_1f3fc_200d_1f680": "emoji_u1f469_1f3fc_200d_1f680.svg",
  "u1f469_1f3fc_200d_1f692": "emoji_u1f469_1f3fc_200d_1f692.svg",
  "u1f469_1f3fc_200d_2695": "emoji_u1f469_1f3fc_200d_2695.svg",
  "u1f469_1f3fc_200d_2696": "emoji_u1f469_1f3fc_200d_2696.svg",
  "u1f469_1f3fc_200d_2708": "emoji_u1f469_1f3fc_200d_2708.svg",
  "u1f469_1f3fc": "emoji_u1f469_1f3fc.svg",
  "u1f469_1f3fd_200d_1f33e": "emoji_u1f469_1f3fd_200d_1f33e.svg",
  "u1f469_1f3fd_200d_1f373": "emoji_u1f469_1f3fd_200d_1f373.svg",
  "u1f469_1f3fd_200d_1f393": "emoji_u1f469_1f3fd_200d_1f393.svg",
  "u1f469_1f3fd_200d_1f3a4": "emoji_u1f469_1f3fd_200d_1f3a4.svg",
  "u1f469_1f3fd_200d_1f3a8": "emoji_u1f469_1f3fd_200d_1f3a8.svg",
  "u1f469_1f3fd_200d_1f3eb": "emoji_u1f469_1f3fd_200d_1f3eb.svg",
  "u1f469_1f3fd_200d_1f3ed": "emoji_u1f469_1f3fd_200d_1f3ed.svg",
  "u1f469_1f3fd_200d_1f4bb": "emoji_u1f469_1f3fd_200d_1f4bb.svg",
  "u1f469_1f3fd_200d_1f4bc": "emoji_u1f469_1f3fd_200d_1f4bc.svg",
  "u1f469_1f3fd_200d_1f527": "emoji_u1f469_1f3fd_200d_1f527.svg",
  "u1f469_1f3fd_200d_1f52c": "emoji_u1f469_1f3fd_200d_1f52c.svg",
  "u1f469_1f3fd_200d_1f680": "emoji_u1f469_1f3fd_200d_1f680.svg",
  "u1f469_1f3fd_200d_1f692": "emoji_u1f469_1f3fd_200d_1f692.svg",
  "u1f469_1f3fd_200d_2695": "emoji_u1f469_1f3fd_200d_2695.svg",
  "u1f469_1f3fd_200d_2696": "emoji_u1f469_1f3fd_200d_2696.svg",
  "u1f469_1f3fd_200d_2708": "emoji_u1f469_1f3fd_200d_2708.svg",
  "u1f469_1f3fd": "emoji_u1f469_1f3fd.svg",
  "u1f469_1f3fe_200d_1f33e": "emoji_u1f469_1f3fe_200d_1f33e.svg",
  "u1f469_1f3fe_200d_1f373": "emoji_u1f469_1f3fe_200d_1f373.svg",
  "u1f469_1f3fe_200d_1f393": "emoji_u1f469_1f3fe_200d_1f393.svg",
  "u1f469_1f3fe_200d_1f3a4": "emoji_u1f469_1f3fe_200d_1f3a4.svg",
  "u1f469_1f3fe_200d_1f3a8": "emoji_u1f469_1f3fe_200d_1f3a8.svg",
  "u1f469_1f3fe_200d_1f3eb": "emoji_u1f469_1f3fe_200d_1f3eb.svg",
  "u1f469_1f3fe_200d_1f3ed": "emoji_u1f469_1f3fe_200d_1f3ed.svg",
  "u1f469_1f3fe_200d_1f4bb": "emoji_u1f469_1f3fe_200d_1f4bb.svg",
  "u1f469_1f3fe_200d_1f4bc": "emoji_u1f469_1f3fe_200d_1f4bc.svg",
  "u1f469_1f3fe_200d_1f527": "emoji_u1f469_1f3fe_200d_1f527.svg",
  "u1f469_1f3fe_200d_1f52c": "emoji_u1f469_1f3fe_200d_1f52c.svg",
  "u1f469_1f3fe_200d_1f680": "emoji_u1f469_1f3fe_200d_1f680.svg",
  "u1f469_1f3fe_200d_1f692": "emoji_u1f469_1f3fe_200d_1f692.svg",
  "u1f469_1f3fe_200d_2695": "emoji_u1f469_1f3fe_200d_2695.svg",
  "u1f469_1f3fe_200d_2696": "emoji_u1f469_1f3fe_200d_2696.svg",
  "u1f469_1f3fe_200d_2708": "emoji_u1f469_1f3fe_200d_2708.svg",
  "u1f469_1f3fe": "emoji_u1f469_1f3fe.svg",
  "u1f469_1f3ff_200d_1f33e": "emoji_u1f469_1f3ff_200d_1f33e.svg",
  "u1f469_1f3ff_200d_1f373": "emoji_u1f469_1f3ff_200d_1f373.svg",
  "u1f469_1f3ff_200d_1f393": "emoji_u1f469_1f3ff_200d_1f393.svg",
  "u1f469_1f3ff_200d_1f3a4": "emoji_u1f469_1f3ff_200d_1f3a4.svg",
  "u1f469_1f3ff_200d_1f3a8": "emoji_u1f469_1f3ff_200d_1f3a8.svg",
  "u1f469_1f3ff_200d_1f3eb": "emoji_u1f469_1f3ff_200d_1f3eb.svg",
  "u1f469_1f3ff_200d_1f3ed": "emoji_u1f469_1f3ff_200d_1f3ed.svg",
  "u1f469_1f3ff_200d_1f4bb": "emoji_u1f469_1f3ff_200d_1f4bb.svg",
  "u1f469_1f3ff_200d_1f4bc": "emoji_u1f469_1f3ff_200d_1f4bc.svg",
  "u1f469_1f3ff_200d_1f527": "emoji_u1f469_1f3ff_200d_1f527.svg",
  "u1f469_1f3ff_200d_1f52c": "emoji_u1f469_1f3ff_200d_1f52c.svg",
  "u1f469_1f3ff_200d_1f680": "emoji_u1f469_1f3ff_200d_1f680.svg",
  "u1f469_1f3ff_200d_1f692": "emoji_u1f469_1f3ff_200d_1f692.svg",
  "u1f469_1f3ff_200d_2695": "emoji_u1f469_1f3ff_200d_2695.svg",
  "u1f469_1f3ff_200d_2696": "emoji_u1f469_1f3ff_200d_2696.svg",
  "u1f469_1f3ff_200d_2708": "emoji_u1f469_1f3ff_200d_2708.svg",
  "u1f469_1f3ff": "emoji_u1f469_1f3ff.svg",
  "u1f469_200d_1f33e": "emoji_u1f469_200d_1f33e.svg",
  "u1f469_200d_1f373": "emoji_u1f469_200d_1f373.svg",
  "u1f469_200d_1f393": "emoji_u1f469_200d_1f393.svg",
  "u1f469_200d_1f3a4": "emoji_u1f469_200d_1f3a4.svg",
  "u1f469_200d_1f3a8": "emoji_u1f469_200d_1f3a8.svg",
  "u1f469_200d_1f3eb": "emoji_u1f469_200d_1f3eb.svg",
  "u1f469_200d_1f3ed": "emoji_u1f469_200d_1f3ed.svg",
  "u1f469_200d_1f466_200d_1f466": "emoji_u1f469_200d_1f466_200d_1f466.svg",
  "u1f469_200d_1f466": "emoji_u1f469_200d_1f466.svg",
  "u1f469_200d_1f467_200d_1f466": "emoji_u1f469_200d_1f467_200d_1f466.svg",
  "u1f469_200d_1f467_200d_1f467": "emoji_u1f469_200d_1f467_200d_1f467.svg",
  "u1f469_200d_1f467": "emoji_u1f469_200d_1f467.svg",
  "u1f469_200d_1f469_200d_1f466_200d_1f466": "emoji_u1f469_200d_1f469_200d_1f466_200d_1f466.svg",
  "u1f469_200d_1f469_200d_1f466": "emoji_u1f469_200d_1f469_200d_1f466.svg",
  "u1f469_200d_1f469_200d_1f467_200d_1f466": "emoji_u1f469_200d_1f469_200d_1f467_200d_1f466.svg",
  "u1f469_200d_1f469_200d_1f467_200d_1f467": "emoji_u1f469_200d_1f469_200d_1f467_200d_1f467.svg",
  "u1f469_200d_1f469_200d_1f467": "emoji_u1f469_200d_1f469_200d_1f467.svg",
  "u1f469_200d_1f4bb": "emoji_u1f469_200d_1f4bb.svg",
  "u1f469_200d_1f4bc": "emoji_u1f469_200d_1f4bc.svg",
  "u1f469_200d_1f527": "emoji_u1f469_200d_1f527.svg",
  "u1f469_200d_1f52c": "emoji_u1f469_200d_1f52c.svg",
  "u1f469_200d_1f680": "emoji_u1f469_200d_1f680.svg",
  "u1f469_200d_1f692": "emoji_u1f469_200d_1f692.svg",
  "u1f469_200d_2695": "emoji_u1f469_200d_2695.svg",
  "u1f469_200d_2696": "emoji_u1f469_200d_2696.svg",
  "u1f469_200d_2708": "emoji_u1f469_200d_2708.svg",
  "u1f469_200d_2764_200d_1f468": "emoji_u1f469_200d_2764_200d_1f468.svg",
  "u1f469_200d_2764_200d_1f469": "emoji_u1f469_200d_2764_200d_1f469.svg",
  "u1f469_200d_2764_200d_1f48b_200d_1f468": "emoji_u1f469_200d_2764_200d_1f48b_200d_1f468.svg",
  "u1f469_200d_2764_200d_1f48b_200d_1f469": "emoji_u1f469_200d_2764_200d_1f48b_200d_1f469.svg",
  "u1f469": "emoji_u1f469.svg",
  "u1f46a": "emoji_u1f46a.svg",
  "u1f46b": "emoji_u1f46b.svg",
  "u1f46c": "emoji_u1f46c.svg",
  "u1f46d": "emoji_u1f46d.svg",
  "u1f46e_1f3fb_200d_2640": "emoji_u1f46e_1f3fb_200d_2640.svg",
  "u1f46e_1f3fb_200d_2642": "emoji_u1f46e_1f3fb_200d_2642.svg",
  "u1f46e_1f3fb": "emoji_u1f46e_1f3fb.svg",
  "u1f46e_1f3fc_200d_2640": "emoji_u1f46e_1f3fc_200d_2640.svg",
  "u1f46e_1f3fc_200d_2642": "emoji_u1f46e_1f3fc_200d_2642.svg",
  "u1f46e_1f3fc": "emoji_u1f46e_1f3fc.svg",
  "u1f46e_1f3fd_200d_2640": "emoji_u1f46e_1f3fd_200d_2640.svg",
  "u1f46e_1f3fd_200d_2642": "emoji_u1f46e_1f3fd_200d_2642.svg",
  "u1f46e_1f3fd": "emoji_u1f46e_1f3fd.svg",
  "u1f46e_1f3fe_200d_2640": "emoji_u1f46e_1f3fe_200d_2640.svg",
  "u1f46e_1f3fe_200d_2642": "emoji_u1f46e_1f3fe_200d_2642.svg",
  "u1f46e_1f3fe": "emoji_u1f46e_1f3fe.svg",
  "u1f46e_1f3ff_200d_2640": "emoji_u1f46e_1f3ff_200d_2640.svg",
  "u1f46e_1f3ff_200d_2642": "emoji_u1f46e_1f3ff_200d_2642.svg",
  "u1f46e_1f3ff": "emoji_u1f46e_1f3ff.svg",
  "u1f46e_200d_2640": "emoji_u1f46e_200d_2640.svg",
  "u1f46e_200d_2642": "emoji_u1f46e_200d_2642.svg",
  "u1f46e": "emoji_u1f46e.svg",
  "u1f46f_200d_2640": "emoji_u1f46f_200d_2640.svg",
  "u1f46f_200d_2642": "emoji_u1f46f_200d_2642.svg",
  "u1f46f": "emoji_u1f46f.svg",
  "u1f470_1f3fb": "emoji_u1f470_1f3fb.svg",
  "u1f470_1f3fc": "emoji_u1f470_1f3fc.svg",
  "u1f470_1f3fd": "emoji_u1f470_1f3fd.svg",
  "u1f470_1f3fe": "emoji_u1f470_1f3fe.svg",
  "u1f470_1f3ff": "emoji_u1f470_1f3ff.svg",
  "u1f470": "emoji_u1f470.svg",
  "u1f471_1f3fb_200d_2640": "emoji_u1f471_1f3fb_200d_2640.svg",
  "u1f471_1f3fb_200d_2642": "emoji_u1f471_1f3fb_200d_2642.svg",
  "u1f471_1f3fb": "emoji_u1f471_1f3fb.svg",
  "u1f471_1f3fc_200d_2640": "emoji_u1f471_1f3fc_200d_2640.svg",
  "u1f471_1f3fc_200d_2642": "emoji_u1f471_1f3fc_200d_2642.svg",
  "u1f471_1f3fc": "emoji_u1f471_1f3fc.svg",
  "u1f471_1f3fd_200d_2640": "emoji_u1f471_1f3fd_200d_2640.svg",
  "u1f471_1f3fd_200d_2642": "emoji_u1f471_1f3fd_200d_2642.svg",
  "u1f471_1f3fd": "emoji_u1f471_1f3fd.svg",
  "u1f471_1f3fe_200d_2640": "emoji_u1f471_1f3fe_200d_2640.svg",
  "u1f471_1f3fe_200d_2642": "emoji_u1f471_1f3fe_200d_2642.svg",
  "u1f471_1f3fe": "emoji_u1f471_1f3fe.svg",
  "u1f471_1f3ff_200d_2640": "emoji_u1f471_1f3ff_200d_2640.svg",
  "u1f471_1f3ff_200d_2642": "emoji_u1f471_1f3ff_200d_2642.svg",
  "u1f471_1f3ff": "emoji_u1f471_1f3ff.svg",
  "u1f471_200d_2640": "emoji_u1f471_200d_2640.svg",
  "u1f471_200d_2642": "emoji_u1f471_200d_2642.svg",
  "u1f471": "emoji_u1f471.svg",
  "u1f472_1f3fb": "emoji_u1f472_1f3fb.svg",
  "u1f472_1f3fc": "emoji_u1f472_1f3fc.svg",
  "u1f472_1f3fd": "emoji_u1f472_1f3fd.svg",
  "u1f472_1f3fe": "emoji_u1f472_1f3fe.svg",
  "u1f472_1f3ff": "emoji_u1f472_1f3ff.svg",
  "u1f472": "emoji_u1f472.svg",
  "u1f473_1f3fb_200d_2640": "emoji_u1f473_1f3fb_200d_2640.svg",
  "u1f473_1f3fb_200d_2642": "emoji_u1f473_1f3fb_200d_2642.svg",
  "u1f473_1f3fb": "emoji_u1f473_1f3fb.svg",
  "u1f473_1f3fc_200d_2640": "emoji_u1f473_1f3fc_200d_2640.svg",
  "u1f473_1f3fc_200d_2642": "emoji_u1f473_1f3fc_200d_2642.svg",
  "u1f473_1f3fc": "emoji_u1f473_1f3fc.svg",
  "u1f473_1f3fd_200d_2640": "emoji_u1f473_1f3fd_200d_2640.svg",
  "u1f473_1f3fd_200d_2642": "emoji_u1f473_1f3fd_200d_2642.svg",
  "u1f473_1f3fd": "emoji_u1f473_1f3fd.svg",
  "u1f473_1f3fe_200d_2640": "emoji_u1f473_1f3fe_200d_2640.svg",
  "u1f473_1f3fe_200d_2642": "emoji_u1f473_1f3fe_200d_2642.svg",
  "u1f473_1f3fe": "emoji_u1f473_1f3fe.svg",
  "u1f473_1f3ff_200d_2640": "emoji_u1f473_1f3ff_200d_2640.svg",
  "u1f473_1f3ff_200d_2642": "emoji_u1f473_1f3ff_200d_2642.svg",
  "u1f473_1f3ff": "emoji_u1f473_1f3ff.svg",
  "u1f473_200d_2640": "emoji_u1f473_200d_2640.svg",
  "u1f473_200d_2642": "emoji_u1f473_200d_2642.svg",
  "u1f473": "emoji_u1f473.svg",
  "u1f474_1f3fb": "emoji_u1f474_1f3fb.svg",
  "u1f474_1f3fc": "emoji_u1f474_1f3fc.svg",
  "u1f474_1f3fd": "emoji_u1f474_1f3fd.svg",
  "u1f474_1f3fe": "emoji_u1f474_1f3fe.svg",
  "u1f474_1f3ff": "emoji_u1f474_1f3ff.svg",
  "u1f474": "emoji_u1f474.svg",
  "u1f475_1f3fb": "emoji_u1f475_1f3fb.svg",
  "u1f475_1f3fc": "emoji_u1f475_1f3fc.svg",
  "u1f475_1f3fd": "emoji_u1f475_1f3fd.svg",
  "u1f475_1f3fe": "emoji_u1f475_1f3fe.svg",
  "u1f475_1f3ff": "emoji_u1f475_1f3ff.svg",
  "u1f475": "emoji_u1f475.svg",
  "u1f476_1f3fb": "emoji_u1f476_1f3fb.svg",
  "u1f476_1f3fc": "emoji_u1f476_1f3fc.svg",
  "u1f476_1f3fd": "emoji_u1f476_1f3fd.svg",
  "u1f476_1f3fe": "emoji_u1f476_1f3fe.svg",
  "u1f476_1f3ff": "emoji_u1f476_1f3ff.svg",
  "u1f476": "emoji_u1f476.svg",
  "u1f477_1f3fb_200d_2640": "emoji_u1f477_1f3fb_200d_2640.svg",
  "u1f477_1f3fb_200d_2642": "emoji_u1f477_1f3fb_200d_2642.svg",
  "u1f477_1f3fb": "emoji_u1f477_1f3fb.svg",
  "u1f477_1f3fc_200d_2640": "emoji_u1f477_1f3fc_200d_2640.svg",
  "u1f477_1f3fc_200d_2642": "emoji_u1f477_1f3fc_200d_2642.svg",
  "u1f477_1f3fc": "emoji_u1f477_1f3fc.svg",
  "u1f477_1f3fd_200d_2640": "emoji_u1f477_1f3fd_200d_2640.svg",
  "u1f477_1f3fd_200d_2642": "emoji_u1f477_1f3fd_200d_2642.svg",
  "u1f477_1f3fd": "emoji_u1f477_1f3fd.svg",
  "u1f477_1f3fe_200d_2640": "emoji_u1f477_1f3fe_200d_2640.svg",
  "u1f477_1f3fe_200d_2642": "emoji_u1f477_1f3fe_200d_2642.svg",
  "u1f477_1f3fe": "emoji_u1f477_1f3fe.svg",
  "u1f477_1f3ff_200d_2640": "emoji_u1f477_1f3ff_200d_2640.svg",
  "u1f477_1f3ff_200d_2642": "emoji_u1f477_1f3ff_200d_2642.svg",
  "u1f477_1f3ff": "emoji_u1f477_1f3ff.svg",
  "u1f477_200d_2640": "emoji_u1f477_200d_2640.svg",
  "u1f477_200d_2642": "emoji_u1f477_200d_2642.svg",
  "u1f477": "emoji_u1f477.svg",
  "u1f478_1f3fb": "emoji_u1f478_1f3fb.svg",
  "u1f478_1f3fc": "emoji_u1f478_1f3fc.svg",
  "u1f478_1f3fd": "emoji_u1f478_1f3fd.svg",
  "u1f478_1f3fe": "emoji_u1f478_1f3fe.svg",
  "u1f478_1f3ff": "emoji_u1f478_1f3ff.svg",
  "u1f478": "emoji_u1f478.svg",
  "u1f479": "emoji_u1f479.svg",
  "u1f47a": "emoji_u1f47a.svg",
  "u1f47b": "emoji_u1f47b.svg",
  "u1f47c_1f3fb": "emoji_u1f47c_1f3fb.svg",
  "u1f47c_1f3fc": "emoji_u1f47c_1f3fc.svg",
  "u1f47c_1f3fd": "emoji_u1f47c_1f3fd.svg",
  "u1f47c_1f3fe": "emoji_u1f47c_1f3fe.svg",
  "u1f47c_1f3ff": "emoji_u1f47c_1f3ff.svg",
  "u1f47c": "emoji_u1f47c.svg",
  "u1f47d": "emoji_u1f47d.svg",
  "u1f47e": "emoji_u1f47e.svg",
  "u1f47f": "emoji_u1f47f.svg",
  "u1f480": "emoji_u1f480.svg",
  "u1f481_1f3fb_200d_2640": "emoji_u1f481_1f3fb_200d_2640.svg",
  "u1f481_1f3fb_200d_2642": "emoji_u1f481_1f3fb_200d_2642.svg",
  "u1f481_1f3fb": "emoji_u1f481_1f3fb.svg",
  "u1f481_1f3fc_200d_2640": "emoji_u1f481_1f3fc_200d_2640.svg",
  "u1f481_1f3fc_200d_2642": "emoji_u1f481_1f3fc_200d_2642.svg",
  "u1f481_1f3fc": "emoji_u1f481_1f3fc.svg",
  "u1f481_1f3fd_200d_2640": "emoji_u1f481_1f3fd_200d_2640.svg",
  "u1f481_1f3fd_200d_2642": "emoji_u1f481_1f3fd_200d_2642.svg",
  "u1f481_1f3fd": "emoji_u1f481_1f3fd.svg",
  "u1f481_1f3fe_200d_2640": "emoji_u1f481_1f3fe_200d_2640.svg",
  "u1f481_1f3fe_200d_2642": "emoji_u1f481_1f3fe_200d_2642.svg",
  "u1f481_1f3fe": "emoji_u1f481_1f3fe.svg",
  "u1f481_1f3ff_200d_2640": "emoji_u1f481_1f3ff_200d_2640.svg",
  "u1f481_1f3ff_200d_2642": "emoji_u1f481_1f3ff_200d_2642.svg",
  "u1f481_1f3ff": "emoji_u1f481_1f3ff.svg",
  "u1f481_200d_2640": "emoji_u1f481_200d_2640.svg",
  "u1f481_200d_2642": "emoji_u1f481_200d_2642.svg",
  "u1f481": "emoji_u1f481.svg",
  "u1f482_1f3fb_200d_2640": "emoji_u1f482_1f3fb_200d_2640.svg",
  "u1f482_1f3fb_200d_2642": "emoji_u1f482_1f3fb_200d_2642.svg",
  "u1f482_1f3fb": "emoji_u1f482_1f3fb.svg",
  "u1f482_1f3fc_200d_2640": "emoji_u1f482_1f3fc_200d_2640.svg",
  "u1f482_1f3fc_200d_2642": "emoji_u1f482_1f3fc_200d_2642.svg",
  "u1f482_1f3fc": "emoji_u1f482_1f3fc.svg",
  "u1f482_1f3fd_200d_2640": "emoji_u1f482_1f3fd_200d_2640.svg",
  "u1f482_1f3fd_200d_2642": "emoji_u1f482_1f3fd_200d_2642.svg",
  "u1f482_1f3fd": "emoji_u1f482_1f3fd.svg",
  "u1f482_1f3fe_200d_2640": "emoji_u1f482_1f3fe_200d_2640.svg",
  "u1f482_1f3fe_200d_2642": "emoji_u1f482_1f3fe_200d_2642.svg",
  "u1f482_1f3fe": "emoji_u1f482_1f3fe.svg",
  "u1f482_1f3ff_200d_2640": "emoji_u1f482_1f3ff_200d_2640.svg",
  "u1f482_1f3ff_200d_2642": "emoji_u1f482_1f3ff_200d_2642.svg",
  "u1f482_1f3ff": "emoji_u1f482_1f3ff.svg",
  "u1f482_200d_2640": "emoji_u1f482_200d_2640.svg",
  "u1f482_200d_2642": "emoji_u1f482_200d_2642.svg",
  "u1f482": "emoji_u1f482.svg",
  "u1f483_1f3fb": "emoji_u1f483_1f3fb.svg",
  "u1f483_1f3fc": "emoji_u1f483_1f3fc.svg",
  "u1f483_1f3fd": "emoji_u1f483_1f3fd.svg",
  "u1f483_1f3fe": "emoji_u1f483_1f3fe.svg",
  "u1f483_1f3ff": "emoji_u1f483_1f3ff.svg",
  "u1f483": "emoji_u1f483.svg",
  "u1f484": "emoji_u1f484.svg",
  "u1f485_1f3fb": "emoji_u1f485_1f3fb.svg",
  "u1f485_1f3fc": "emoji_u1f485_1f3fc.svg",
  "u1f485_1f3fd": "emoji_u1f485_1f3fd.svg",
  "u1f485_1f3fe": "emoji_u1f485_1f3fe.svg",
  "u1f485_1f3ff": "emoji_u1f485_1f3ff.svg",
  "u1f485": "emoji_u1f485.svg",
  "u1f486_1f3fb_200d_2640": "emoji_u1f486_1f3fb_200d_2640.svg",
  "u1f486_1f3fb_200d_2642": "emoji_u1f486_1f3fb_200d_2642.svg",
  "u1f486_1f3fb": "emoji_u1f486_1f3fb.svg",
  "u1f486_1f3fc_200d_2640": "emoji_u1f486_1f3fc_200d_2640.svg",
  "u1f486_1f3fc_200d_2642": "emoji_u1f486_1f3fc_200d_2642.svg",
  "u1f486_1f3fc": "emoji_u1f486_1f3fc.svg",
  "u1f486_1f3fd_200d_2640": "emoji_u1f486_1f3fd_200d_2640.svg",
  "u1f486_1f3fd_200d_2642": "emoji_u1f486_1f3fd_200d_2642.svg",
  "u1f486_1f3fd": "emoji_u1f486_1f3fd.svg",
  "u1f486_1f3fe_200d_2640": "emoji_u1f486_1f3fe_200d_2640.svg",
  "u1f486_1f3fe_200d_2642": "emoji_u1f486_1f3fe_200d_2642.svg",
  "u1f486_1f3fe": "emoji_u1f486_1f3fe.svg",
  "u1f486_1f3ff_200d_2640": "emoji_u1f486_1f3ff_200d_2640.svg",
  "u1f486_1f3ff_200d_2642": "emoji_u1f486_1f3ff_200d_2642.svg",
  "u1f486_1f3ff": "emoji_u1f486_1f3ff.svg",
  "u1f486_200d_2640": "emoji_u1f486_200d_2640.svg",
  "u1f486_200d_2642": "emoji_u1f486_200d_2642.svg",
  "u1f486": "emoji_u1f486.svg",
  "u1f487_1f3fb_200d_2640": "emoji_u1f487_1f3fb_200d_2640.svg",
  "u1f487_1f3fb_200d_2642": "emoji_u1f487_1f3fb_200d_2642.svg",
  "u1f487_1f3fb": "emoji_u1f487_1f3fb.svg",
  "u1f487_1f3fc_200d_2640": "emoji_u1f487_1f3fc_200d_2640.svg",
  "u1f487_1f3fc_200d_2642": "emoji_u1f487_1f3fc_200d_2642.svg",
  "u1f487_1f3fc": "emoji_u1f487_1f3fc.svg",
  "u1f487_1f3fd_200d_2640": "emoji_u1f487_1f3fd_200d_2640.svg",
  "u1f487_1f3fd_200d_2642": "emoji_u1f487_1f3fd_200d_2642.svg",
  "u1f487_1f3fd": "emoji_u1f487_1f3fd.svg",
  "u1f487_1f3fe_200d_2640": "emoji_u1f487_1f3fe_200d_2640.svg",
  "u1f487_1f3fe_200d_2642": "emoji_u1f487_1f3fe_200d_2642.svg",
  "u1f487_1f3fe": "emoji_u1f487_1f3fe.svg",
  "u1f487_1f3ff_200d_2640": "emoji_u1f487_1f3ff_200d_2640.svg",
  "u1f487_1f3ff_200d_2642": "emoji_u1f487_1f3ff_200d_2642.svg",
  "u1f487_1f3ff": "emoji_u1f487_1f3ff.svg",
  "u1f487_200d_2640": "emoji_u1f487_200d_2640.svg",
  "u1f487_200d_2642": "emoji_u1f487_200d_2642.svg",
  "u1f487": "emoji_u1f487.svg",
  "u1f488": "emoji_u1f488.svg",
  "u1f489": "emoji_u1f489.svg",
  "u1f48a": "emoji_u1f48a.svg",
  "u1f48b": "emoji_u1f48b.svg",
  "u1f48c": "emoji_u1f48c.svg",
  "u1f48d": "emoji_u1f48d.svg",
  "u1f48e": "emoji_u1f48e.svg",
  "u1f490": "emoji_u1f490.svg",
  "u1f492": "emoji_u1f492.svg",
  "u1f493": "emoji_u1f493.svg",
  "u1f494": "emoji_u1f494.svg",
  "u1f495": "emoji_u1f495.svg",
  "u1f496": "emoji_u1f496.svg",
  "u1f497": "emoji_u1f497.svg",
  "u1f498": "emoji_u1f498.svg",
  "u1f499": "emoji_u1f499.svg",
  "u1f49a": "emoji_u1f49a.svg",
  "u1f49b": "emoji_u1f49b.svg",
  "u1f49c": "emoji_u1f49c.svg",
  "u1f49d": "emoji_u1f49d.svg",
  "u1f49e": "emoji_u1f49e.svg",
  "u1f49f": "emoji_u1f49f.svg",
  "u1f4a0": "emoji_u1f4a0.svg",
  "u1f4a1": "emoji_u1f4a1.svg",
  "u1f4a2": "emoji_u1f4a2.svg",
  "u1f4a3": "emoji_u1f4a3.svg",
  "u1f4a4": "emoji_u1f4a4.svg",
  "u1f4a5": "emoji_u1f4a5.svg",
  "u1f4a6": "emoji_u1f4a6.svg",
  "u1f4a7": "emoji_u1f4a7.svg",
  "u1f4a8": "emoji_u1f4a8.svg",
  "u1f4a9": "emoji_u1f4a9.svg",
  "u1f4aa_1f3fb": "emoji_u1f4aa_1f3fb.svg",
  "u1f4aa_1f3fc": "emoji_u1f4aa_1f3fc.svg",
  "u1f4aa_1f3fd": "emoji_u1f4aa_1f3fd.svg",
  "u1f4aa_1f3fe": "emoji_u1f4aa_1f3fe.svg",
  "u1f4aa_1f3ff": "emoji_u1f4aa_1f3ff.svg",
  "u1f4aa": "emoji_u1f4aa.svg",
  "u1f4ab": "emoji_u1f4ab.svg",
  "u1f4ac": "emoji_u1f4ac.svg",
  "u1f4ad": "emoji_u1f4ad.svg",
  "u1f4ae": "emoji_u1f4ae.svg",
  "u1f4af": "emoji_u1f4af.svg",
  "u1f4b0": "emoji_u1f4b0.svg",
  "u1f4b1": "emoji_u1f4b1.svg",
  "u1f4b2": "emoji_u1f4b2.svg",
  "u1f4b3": "emoji_u1f4b3.svg",
  "u1f4b4": "emoji_u1f4b4.svg",
  "u1f4b5": "emoji_u1f4b5.svg",
  "u1f4b6": "emoji_u1f4b6.svg",
  "u1f4b7": "emoji_u1f4b7.svg",
  "u1f4b8": "emoji_u1f4b8.svg",
  "u1f4b9": "emoji_u1f4b9.svg",
  "u1f4ba": "emoji_u1f4ba.svg",
  "u1f4bb": "emoji_u1f4bb.svg",
  "u1f4bc": "emoji_u1f4bc.svg",
  "u1f4bd": "emoji_u1f4bd.svg",
  "u1f4be": "emoji_u1f4be.svg",
  "u1f4bf": "emoji_u1f4bf.svg",
  "u1f4c0": "emoji_u1f4c0.svg",
  "u1f4c1": "emoji_u1f4c1.svg",
  "u1f4c2": "emoji_u1f4c2.svg",
  "u1f4c3": "emoji_u1f4c3.svg",
  "u1f4c4": "emoji_u1f4c4.svg",
  "u1f4c5": "emoji_u1f4c5.svg",
  "u1f4c6": "emoji_u1f4c6.svg",
  "u1f4c7": "emoji_u1f4c7.svg",
  "u1f4c8": "emoji_u1f4c8.svg",
  "u1f4c9": "emoji_u1f4c9.svg",
  "u1f4ca": "emoji_u1f4ca.svg",
  "u1f4cb": "emoji_u1f4cb.svg",
  "u1f4cc": "emoji_u1f4cc.svg",
  "u1f4cd": "emoji_u1f4cd.svg",
  "u1f4ce": "emoji_u1f4ce.svg",
  "u1f4cf": "emoji_u1f4cf.svg",
  "u1f4d0": "emoji_u1f4d0.svg",
  "u1f4d1": "emoji_u1f4d1.svg",
  "u1f4d2": "emoji_u1f4d2.svg",
  "u1f4d3": "emoji_u1f4d3.svg",
  "u1f4d4": "emoji_u1f4d4.svg",
  "u1f4d5": "emoji_u1f4d5.svg",
  "u1f4d6": "emoji_u1f4d6.svg",
  "u1f4d7": "emoji_u1f4d7.svg",
  "u1f4d8": "emoji_u1f4d8.svg",
  "u1f4d9": "emoji_u1f4d9.svg",
  "u1f4da": "emoji_u1f4da.svg",
  "u1f4db": "emoji_u1f4db.svg",
  "u1f4dc": "emoji_u1f4dc.svg",
  "u1f4dd": "emoji_u1f4dd.svg",
  "u1f4de": "emoji_u1f4de.svg",
  "u1f4df": "emoji_u1f4df.svg",
  "u1f4e0": "emoji_u1f4e0.svg",
  "u1f4e1": "emoji_u1f4e1.svg",
  "u1f4e2": "emoji_u1f4e2.svg",
  "u1f4e3": "emoji_u1f4e3.svg",
  "u1f4e4": "emoji_u1f4e4.svg",
  "u1f4e5": "emoji_u1f4e5.svg",
  "u1f4e6": "emoji_u1f4e6.svg",
  "u1f4e7": "emoji_u1f4e7.svg",
  "u1f4e8": "emoji_u1f4e8.svg",
  "u1f4e9": "emoji_u1f4e9.svg",
  "u1f4ea": "emoji_u1f4ea.svg",
  "u1f4eb": "emoji_u1f4eb.svg",
  "u1f4ec": "emoji_u1f4ec.svg",
  "u1f4ed": "emoji_u1f4ed.svg",
  "u1f4ee": "emoji_u1f4ee.svg",
  "u1f4ef": "emoji_u1f4ef.svg",
  "u1f4f0": "emoji_u1f4f0.svg",
  "u1f4f1": "emoji_u1f4f1.svg",
  "u1f4f2": "emoji_u1f4f2.svg",
  "u1f4f3": "emoji_u1f4f3.svg",
  "u1f4f4": "emoji_u1f4f4.svg",
  "u1f4f5": "emoji_u1f4f5.svg",
  "u1f4f6": "emoji_u1f4f6.svg",
  "u1f4f7": "emoji_u1f4f7.svg",
  "u1f4f8": "emoji_u1f4f8.svg",
  "u1f4f9": "emoji_u1f4f9.svg",
  "u1f4fa": "emoji_u1f4fa.svg",
  "u1f4fb": "emoji_u1f4fb.svg",
  "u1f4fc": "emoji_u1f4fc.svg",
  "u1f4fd": "emoji_u1f4fd.svg",
  "u1f4ff": "emoji_u1f4ff.svg",
  "u1f500": "emoji_u1f500.svg",
  "u1f501": "emoji_u1f501.svg",
  "u1f502": "emoji_u1f502.svg",
  "u1f503": "emoji_u1f503.svg",
  "u1f504": "emoji_u1f504.svg",
  "u1f505": "emoji_u1f505.svg",
  "u1f506": "emoji_u1f506.svg",
  "u1f507": "emoji_u1f507.svg",
  "u1f508": "emoji_u1f508.svg",
  "u1f509": "emoji_u1f509.svg",
  "u1f50a": "emoji_u1f50a.svg",
  "u1f50b": "emoji_u1f50b.svg",
  "u1f50c": "emoji_u1f50c.svg",
  "u1f50d": "emoji_u1f50d.svg",
  "u1f50e": "emoji_u1f50e.svg",
  "u1f50f": "emoji_u1f50f.svg",
  "u1f510": "emoji_u1f510.svg",
  "u1f511": "emoji_u1f511.svg",
  "u1f512": "emoji_u1f512.svg",
  "u1f513": "emoji_u1f513.svg",
  "u1f514": "emoji_u1f514.svg",
  "u1f515": "emoji_u1f515.svg",
  "u1f516": "emoji_u1f516.svg",
  "u1f517": "emoji_u1f517.svg",
  "u1f518": "emoji_u1f518.svg",
  "u1f519": "emoji_u1f519.svg",
  "u1f51a": "emoji_u1f51a.svg",
  "u1f51b": "emoji_u1f51b.svg",
  "u1f51c": "emoji_u1f51c.svg",
  "u1f51d": "emoji_u1f51d.svg",
  "u1f51e": "emoji_u1f51e.svg",
  "u1f51f": "emoji_u1f51f.svg",
  "u1f520": "emoji_u1f520.svg",
  "u1f521": "emoji_u1f521.svg",
  "u1f522": "emoji_u1f522.svg",
  "u1f523": "emoji_u1f523.svg",
  "u1f524": "emoji_u1f524.svg",
  "u1f525": "emoji_u1f525.svg",
  "u1f526": "emoji_u1f526.svg",
  "u1f527": "emoji_u1f527.svg",
  "u1f528": "emoji_u1f528.svg",
  "u1f529": "emoji_u1f529.svg",
  "u1f52a": "emoji_u1f52a.svg",
  "u1f52b": "emoji_u1f52b.svg",
  "u1f52c": "emoji_u1f52c.svg",
  "u1f52d": "emoji_u1f52d.svg",
  "u1f52e": "emoji_u1f52e.svg",
  "u1f52f": "emoji_u1f52f.svg",
  "u1f530": "emoji_u1f530.svg",
  "u1f531": "emoji_u1f531.svg",
  "u1f532": "emoji_u1f532.svg",
  "u1f533": "emoji_u1f533.svg",
  "u1f534": "emoji_u1f534.svg",
  "u1f535": "emoji_u1f535.svg",
  "u1f536": "emoji_u1f536.svg",
  "u1f537": "emoji_u1f537.svg",
  "u1f538": "emoji_u1f538.svg",
  "u1f539": "emoji_u1f539.svg",
  "u1f53a": "emoji_u1f53a.svg",
  "u1f53b": "emoji_u1f53b.svg",
  "u1f53c": "emoji_u1f53c.svg",
  "u1f53d": "emoji_u1f53d.svg",
  "u1f549": "emoji_u1f549.svg",
  "u1f54a": "emoji_u1f54a.svg",
  "u1f54b": "emoji_u1f54b.svg",
  "u1f54c": "emoji_u1f54c.svg",
  "u1f54d": "emoji_u1f54d.svg",
  "u1f54e": "emoji_u1f54e.svg",
  "u1f550": "emoji_u1f550.svg",
  "u1f551": "emoji_u1f551.svg",
  "u1f552": "emoji_u1f552.svg",
  "u1f553": "emoji_u1f553.svg",
  "u1f554": "emoji_u1f554.svg",
  "u1f555": "emoji_u1f555.svg",
  "u1f556": "emoji_u1f556.svg",
  "u1f557": "emoji_u1f557.svg",
  "u1f558": "emoji_u1f558.svg",
  "u1f559": "emoji_u1f559.svg",
  "u1f55a": "emoji_u1f55a.svg",
  "u1f55b": "emoji_u1f55b.svg",
  "u1f55c": "emoji_u1f55c.svg",
  "u1f55d": "emoji_u1f55d.svg",
  "u1f55e": "emoji_u1f55e.svg",
  "u1f55f": "emoji_u1f55f.svg",
  "u1f560": "emoji_u1f560.svg",
  "u1f561": "emoji_u1f561.svg",
  "u1f562": "emoji_u1f562.svg",
  "u1f563": "emoji_u1f563.svg",
  "u1f564": "emoji_u1f564.svg",
  "u1f565": "emoji_u1f565.svg",
  "u1f566": "emoji_u1f566.svg",
  "u1f567": "emoji_u1f567.svg",
  "u1f56f": "emoji_u1f56f.svg",
  "u1f570": "emoji_u1f570.svg",
  "u1f573": "emoji_u1f573.svg",
  "u1f574": "emoji_u1f574.svg",
  "u1f575_1f3fb_200d_2640": "emoji_u1f575_1f3fb_200d_2640.svg",
  "u1f575_1f3fb_200d_2642": "emoji_u1f575_1f3fb_200d_2642.svg",
  "u1f575_1f3fb": "emoji_u1f575_1f3fb.svg",
  "u1f575_1f3fc_200d_2640": "emoji_u1f575_1f3fc_200d_2640.svg",
  "u1f575_1f3fc_200d_2642": "emoji_u1f575_1f3fc_200d_2642.svg",
  "u1f575_1f3fc": "emoji_u1f575_1f3fc.svg",
  "u1f575_1f3fd_200d_2640": "emoji_u1f575_1f3fd_200d_2640.svg",
  "u1f575_1f3fd_200d_2642": "emoji_u1f575_1f3fd_200d_2642.svg",
  "u1f575_1f3fd": "emoji_u1f575_1f3fd.svg",
  "u1f575_1f3fe_200d_2640": "emoji_u1f575_1f3fe_200d_2640.svg",
  "u1f575_1f3fe_200d_2642": "emoji_u1f575_1f3fe_200d_2642.svg",
  "u1f575_1f3fe": "emoji_u1f575_1f3fe.svg",
  "u1f575_1f3ff_200d_2640": "emoji_u1f575_1f3ff_200d_2640.svg",
  "u1f575_1f3ff_200d_2642": "emoji_u1f575_1f3ff_200d_2642.svg",
  "u1f575_1f3ff": "emoji_u1f575_1f3ff.svg",
  "u1f575_200d_2640": "emoji_u1f575_200d_2640.svg",
  "u1f575_200d_2642": "emoji_u1f575_200d_2642.svg",
  "u1f575": "emoji_u1f575.svg",
  "u1f576": "emoji_u1f576.svg",
  "u1f577": "emoji_u1f577.svg",
  "u1f578": "emoji_u1f578.svg",
  "u1f579": "emoji_u1f579.svg",
  "u1f57a_1f3fb": "emoji_u1f57a_1f3fb.svg",
  "u1f57a_1f3fc": "emoji_u1f57a_1f3fc.svg",
  "u1f57a_1f3fd": "emoji_u1f57a_1f3fd.svg",
  "u1f57a_1f3fe": "emoji_u1f57a_1f3fe.svg",
  "u1f57a_1f3ff": "emoji_u1f57a_1f3ff.svg",
  "u1f57a": "emoji_u1f57a.svg",
  "u1f587": "emoji_u1f587.svg",
  "u1f58a": "emoji_u1f58a.svg",
  "u1f58b": "emoji_u1f58b.svg",
  "u1f58c": "emoji_u1f58c.svg",
  "u1f58d": "emoji_u1f58d.svg",
  "u1f590_1f3fb": "emoji_u1f590_1f3fb.svg",
  "u1f590_1f3fc": "emoji_u1f590_1f3fc.svg",
  "u1f590_1f3fd": "emoji_u1f590_1f3fd.svg",
  "u1f590_1f3fe": "emoji_u1f590_1f3fe.svg",
  "u1f590_1f3ff": "emoji_u1f590_1f3ff.svg",
  "u1f590": "emoji_u1f590.svg",
  "u1f595_1f3fb": "emoji_u1f595_1f3fb.svg",
  "u1f595_1f3fc": "emoji_u1f595_1f3fc.svg",
  "u1f595_1f3fd": "emoji_u1f595_1f3fd.svg",
  "u1f595_1f3fe": "emoji_u1f595_1f3fe.svg",
  "u1f595_1f3ff": "emoji_u1f595_1f3ff.svg",
  "u1f595": "emoji_u1f595.svg",
  "u1f596_1f3fb": "emoji_u1f596_1f3fb.svg",
  "u1f596_1f3fc": "emoji_u1f596_1f3fc.svg",
  "u1f596_1f3fd": "emoji_u1f596_1f3fd.svg",
  "u1f596_1f3fe": "emoji_u1f596_1f3fe.svg",
  "u1f596_1f3ff": "emoji_u1f596_1f3ff.svg",
  "u1f596": "emoji_u1f596.svg",
  "u1f5a4": "emoji_u1f5a4.svg",
  "u1f5a5": "emoji_u1f5a5.svg",
  "u1f5a8": "emoji_u1f5a8.svg",
  "u1f5b1": "emoji_u1f5b1.svg",
  "u1f5b2": "emoji_u1f5b2.svg",
  "u1f5bc": "emoji_u1f5bc.svg",
  "u1f5c2": "emoji_u1f5c2.svg",
  "u1f5c3": "emoji_u1f5c3.svg",
  "u1f5c4": "emoji_u1f5c4.svg",
  "u1f5d1": "emoji_u1f5d1.svg",
  "u1f5d2": "emoji_u1f5d2.svg",
  "u1f5d3": "emoji_u1f5d3.svg",
  "u1f5dc": "emoji_u1f5dc.svg",
  "u1f5dd": "emoji_u1f5dd.svg",
  "u1f5de": "emoji_u1f5de.svg",
  "u1f5e1": "emoji_u1f5e1.svg",
  "u1f5e3": "emoji_u1f5e3.svg",
  "u1f5e8": "emoji_u1f5e8.svg",
  "u1f5ef": "emoji_u1f5ef.svg",
  "u1f5f3": "emoji_u1f5f3.svg",
  "u1f5fa": "emoji_u1f5fa.svg",
  "u1f5fb": "emoji_u1f5fb.svg",
  "u1f5fc": "emoji_u1f5fc.svg",
  "u1f5fd": "emoji_u1f5fd.svg",
  "u1f5fe": "emoji_u1f5fe.svg",
  "u1f5ff": "emoji_u1f5ff.svg",
  "u1f600": "emoji_u1f600.svg",
  "u1f601": "emoji_u1f601.svg",
  "u1f602": "emoji_u1f602.svg",
  "u1f603": "emoji_u1f603.svg",
  "u1f604": "emoji_u1f604.svg",
  "u1f605": "emoji_u1f605.svg",
  "u1f606": "emoji_u1f606.svg",
  "u1f607": "emoji_u1f607.svg",
  "u1f608": "emoji_u1f608.svg",
  "u1f609": "emoji_u1f609.svg",
  "u1f60a": "emoji_u1f60a.svg",
  "u1f60b": "emoji_u1f60b.svg",
  "u1f60c": "emoji_u1f60c.svg",
  "u1f60d": "emoji_u1f60d.svg",
  "u1f60e": "emoji_u1f60e.svg",
  "u1f60f": "emoji_u1f60f.svg",
  "u1f610": "emoji_u1f610.svg",
  "u1f611": "emoji_u1f611.svg",
  "u1f612": "emoji_u1f612.svg",
  "u1f613": "emoji_u1f613.svg",
  "u1f614": "emoji_u1f614.svg",
  "u1f615": "emoji_u1f615.svg",
  "u1f616": "emoji_u1f616.svg",
  "u1f617": "emoji_u1f617.svg",
  "u1f618": "emoji_u1f618.svg",
  "u1f619": "emoji_u1f619.svg",
  "u1f61a": "emoji_u1f61a.svg",
  "u1f61b": "emoji_u1f61b.svg",
  "u1f61c": "emoji_u1f61c.svg",
  "u1f61d": "emoji_u1f61d.svg",
  "u1f61e": "emoji_u1f61e.svg",
  "u1f61f": "emoji_u1f61f.svg",
  "u1f620": "emoji_u1f620.svg",
  "u1f621": "emoji_u1f621.svg",
  "u1f622": "emoji_u1f622.svg",
  "u1f623": "emoji_u1f623.svg",
  "u1f624": "emoji_u1f624.svg",
  "u1f625": "emoji_u1f625.svg",
  "u1f626": "emoji_u1f626.svg",
  "u1f627": "emoji_u1f627.svg",
  "u1f628": "emoji_u1f628.svg",
  "u1f629": "emoji_u1f629.svg",
  "u1f62a": "emoji_u1f62a.svg",
  "u1f62b": "emoji_u1f62b.svg",
  "u1f62c": "emoji_u1f62c.svg",
  "u1f62d": "emoji_u1f62d.svg",
  "u1f62e": "emoji_u1f62e.svg",
  "u1f62f": "emoji_u1f62f.svg",
  "u1f630": "emoji_u1f630.svg",
  "u1f631": "emoji_u1f631.svg",
  "u1f632": "emoji_u1f632.svg",
  "u1f633": "emoji_u1f633.svg",
  "u1f634": "emoji_u1f634.svg",
  "u1f635": "emoji_u1f635.svg",
  "u1f636": "emoji_u1f636.svg",
  "u1f637": "emoji_u1f637.svg",
  "u1f638": "emoji_u1f638.svg",
  "u1f639": "emoji_u1f639.svg",
  "u1f63a": "emoji_u1f63a.svg",
  "u1f63b": "emoji_u1f63b.svg",
  "u1f63c": "emoji_u1f63c.svg",
  "u1f63d": "emoji_u1f63d.svg",
  "u1f63e": "emoji_u1f63e.svg",
  "u1f63f": "emoji_u1f63f.svg",
  "u1f640": "emoji_u1f640.svg",
  "u1f641": "emoji_u1f641.svg",
  "u1f642": "emoji_u1f642.svg",
  "u1f643": "emoji_u1f643.svg",
  "u1f644": "emoji_u1f644.svg",
  "u1f645_1f3fb_200d_2640": "emoji_u1f645_1f3fb_200d_2640.svg",
  "u1f645_1f3fb_200d_2642": "emoji_u1f645_1f3fb_200d_2642.svg",
  "u1f645_1f3fb": "emoji_u1f645_1f3fb.svg",
  "u1f645_1f3fc_200d_2640": "emoji_u1f645_1f3fc_200d_2640.svg",
  "u1f645_1f3fc_200d_2642": "emoji_u1f645_1f3fc_200d_2642.svg",
  "u1f645_1f3fc": "emoji_u1f645_1f3fc.svg",
  "u1f645_1f3fd_200d_2640": "emoji_u1f645_1f3fd_200d_2640.svg",
  "u1f645_1f3fd_200d_2642": "emoji_u1f645_1f3fd_200d_2642.svg",
  "u1f645_1f3fd": "emoji_u1f645_1f3fd.svg",
  "u1f645_1f3fe_200d_2640": "emoji_u1f645_1f3fe_200d_2640.svg",
  "u1f645_1f3fe_200d_2642": "emoji_u1f645_1f3fe_200d_2642.svg",
  "u1f645_1f3fe": "emoji_u1f645_1f3fe.svg",
  "u1f645_1f3ff_200d_2640": "emoji_u1f645_1f3ff_200d_2640.svg",
  "u1f645_1f3ff_200d_2642": "emoji_u1f645_1f3ff_200d_2642.svg",
  "u1f645_1f3ff": "emoji_u1f645_1f3ff.svg",
  "u1f645_200d_2640": "emoji_u1f645_200d_2640.svg",
  "u1f645_200d_2642": "emoji_u1f645_200d_2642.svg",
  "u1f645": "emoji_u1f645.svg",
  "u1f646_1f3fb_200d_2640": "emoji_u1f646_1f3fb_200d_2640.svg",
  "u1f646_1f3fb_200d_2642": "emoji_u1f646_1f3fb_200d_2642.svg",
  "u1f646_1f3fb": "emoji_u1f646_1f3fb.svg",
  "u1f646_1f3fc_200d_2640": "emoji_u1f646_1f3fc_200d_2640.svg",
  "u1f646_1f3fc_200d_2642": "emoji_u1f646_1f3fc_200d_2642.svg",
  "u1f646_1f3fc": "emoji_u1f646_1f3fc.svg",
  "u1f646_1f3fd_200d_2640": "emoji_u1f646_1f3fd_200d_2640.svg",
  "u1f646_1f3fd_200d_2642": "emoji_u1f646_1f3fd_200d_2642.svg",
  "u1f646_1f3fd": "emoji_u1f646_1f3fd.svg",
  "u1f646_1f3fe_200d_2640": "emoji_u1f646_1f3fe_200d_2640.svg",
  "u1f646_1f3fe_200d_2642": "emoji_u1f646_1f3fe_200d_2642.svg",
  "u1f646_1f3fe": "emoji_u1f646_1f3fe.svg",
  "u1f646_1f3ff_200d_2640": "emoji_u1f646_1f3ff_200d_2640.svg",
  "u1f646_1f3ff_200d_2642": "emoji_u1f646_1f3ff_200d_2642.svg",
  "u1f646_1f3ff": "emoji_u1f646_1f3ff.svg",
  "u1f646_200d_2640": "emoji_u1f646_200d_2640.svg",
  "u1f646_200d_2642": "emoji_u1f646_200d_2642.svg",
  "u1f646": "emoji_u1f646.svg",
  "u1f647_1f3fb_200d_2640": "emoji_u1f647_1f3fb_200d_2640.svg",
  "u1f647_1f3fb_200d_2642": "emoji_u1f647_1f3fb_200d_2642.svg",
  "u1f647_1f3fb": "emoji_u1f647_1f3fb.svg",
  "u1f647_1f3fc_200d_2640": "emoji_u1f647_1f3fc_200d_2640.svg",
  "u1f647_1f3fc_200d_2642": "emoji_u1f647_1f3fc_200d_2642.svg",
  "u1f647_1f3fc": "emoji_u1f647_1f3fc.svg",
  "u1f647_1f3fd_200d_2640": "emoji_u1f647_1f3fd_200d_2640.svg",
  "u1f647_1f3fd_200d_2642": "emoji_u1f647_1f3fd_200d_2642.svg",
  "u1f647_1f3fd": "emoji_u1f647_1f3fd.svg",
  "u1f647_1f3fe_200d_2640": "emoji_u1f647_1f3fe_200d_2640.svg",
  "u1f647_1f3fe_200d_2642": "emoji_u1f647_1f3fe_200d_2642.svg",
  "u1f647_1f3fe": "emoji_u1f647_1f3fe.svg",
  "u1f647_1f3ff_200d_2640": "emoji_u1f647_1f3ff_200d_2640.svg",
  "u1f647_1f3ff_200d_2642": "emoji_u1f647_1f3ff_200d_2642.svg",
  "u1f647_1f3ff": "emoji_u1f647_1f3ff.svg",
  "u1f647_200d_2640": "emoji_u1f647_200d_2640.svg",
  "u1f647_200d_2642": "emoji_u1f647_200d_2642.svg",
  "u1f647": "emoji_u1f647.svg",
  "u1f648": "emoji_u1f648.svg",
  "u1f649": "emoji_u1f649.svg",
  "u1f64a": "emoji_u1f64a.svg",
  "u1f64b_1f3fb_200d_2640": "emoji_u1f64b_1f3fb_200d_2640.svg",
  "u1f64b_1f3fb_200d_2642": "emoji_u1f64b_1f3fb_200d_2642.svg",
  "u1f64b_1f3fb": "emoji_u1f64b_1f3fb.svg",
  "u1f64b_1f3fc_200d_2640": "emoji_u1f64b_1f3fc_200d_2640.svg",
  "u1f64b_1f3fc_200d_2642": "emoji_u1f64b_1f3fc_200d_2642.svg",
  "u1f64b_1f3fc": "emoji_u1f64b_1f3fc.svg",
  "u1f64b_1f3fd_200d_2640": "emoji_u1f64b_1f3fd_200d_2640.svg",
  "u1f64b_1f3fd_200d_2642": "emoji_u1f64b_1f3fd_200d_2642.svg",
  "u1f64b_1f3fd": "emoji_u1f64b_1f3fd.svg",
  "u1f64b_1f3fe_200d_2640": "emoji_u1f64b_1f3fe_200d_2640.svg",
  "u1f64b_1f3fe_200d_2642": "emoji_u1f64b_1f3fe_200d_2642.svg",
  "u1f64b_1f3fe": "emoji_u1f64b_1f3fe.svg",
  "u1f64b_1f3ff_200d_2640": "emoji_u1f64b_1f3ff_200d_2640.svg",
  "u1f64b_1f3ff_200d_2642": "emoji_u1f64b_1f3ff_200d_2642.svg",
  "u1f64b_1f3ff": "emoji_u1f64b_1f3ff.svg",
  "u1f64b_200d_2640": "emoji_u1f64b_200d_2640.svg",
  "u1f64b_200d_2642": "emoji_u1f64b_200d_2642.svg",
  "u1f64b": "emoji_u1f64b.svg",
  "u1f64c_1f3fb": "emoji_u1f64c_1f3fb.svg",
  "u1f64c_1f3fc": "emoji_u1f64c_1f3fc.svg",
  "u1f64c_1f3fd": "emoji_u1f64c_1f3fd.svg",
  "u1f64c_1f3fe": "emoji_u1f64c_1f3fe.svg",
  "u1f64c_1f3ff": "emoji_u1f64c_1f3ff.svg",
  "u1f64c": "emoji_u1f64c.svg",
  "u1f64d_1f3fb_200d_2640": "emoji_u1f64d_1f3fb_200d_2640.svg",
  "u1f64d_1f3fb_200d_2642": "emoji_u1f64d_1f3fb_200d_2642.svg",
  "u1f64d_1f3fb": "emoji_u1f64d_1f3fb.svg",
  "u1f64d_1f3fc_200d_2640": "emoji_u1f64d_1f3fc_200d_2640.svg",
  "u1f64d_1f3fc_200d_2642": "emoji_u1f64d_1f3fc_200d_2642.svg",
  "u1f64d_1f3fc": "emoji_u1f64d_1f3fc.svg",
  "u1f64d_1f3fd_200d_2640": "emoji_u1f64d_1f3fd_200d_2640.svg",
  "u1f64d_1f3fd_200d_2642": "emoji_u1f64d_1f3fd_200d_2642.svg",
  "u1f64d_1f3fd": "emoji_u1f64d_1f3fd.svg",
  "u1f64d_1f3fe_200d_2640": "emoji_u1f64d_1f3fe_200d_2640.svg",
  "u1f64d_1f3fe_200d_2642": "emoji_u1f64d_1f3fe_200d_2642.svg",
  "u1f64d_1f3fe": "emoji_u1f64d_1f3fe.svg",
  "u1f64d_1f3ff_200d_2640": "emoji_u1f64d_1f3ff_200d_2640.svg",
  "u1f64d_1f3ff_200d_2642": "emoji_u1f64d_1f3ff_200d_2642.svg",
  "u1f64d_1f3ff": "emoji_u1f64d_1f3ff.svg",
  "u1f64d_200d_2640": "emoji_u1f64d_200d_2640.svg",
  "u1f64d_200d_2642": "emoji_u1f64d_200d_2642.svg",
  "u1f64d": "emoji_u1f64d.svg",
  "u1f64e_1f3fb_200d_2640": "emoji_u1f64e_1f3fb_200d_2640.svg",
  "u1f64e_1f3fb_200d_2642": "emoji_u1f64e_1f3fb_200d_2642.svg",
  "u1f64e_1f3fb": "emoji_u1f64e_1f3fb.svg",
  "u1f64e_1f3fc_200d_2640": "emoji_u1f64e_1f3fc_200d_2640.svg",
  "u1f64e_1f3fc_200d_2642": "emoji_u1f64e_1f3fc_200d_2642.svg",
  "u1f64e_1f3fc": "emoji_u1f64e_1f3fc.svg",
  "u1f64e_1f3fd_200d_2640": "emoji_u1f64e_1f3fd_200d_2640.svg",
  "u1f64e_1f3fd_200d_2642": "emoji_u1f64e_1f3fd_200d_2642.svg",
  "u1f64e_1f3fd": "emoji_u1f64e_1f3fd.svg",
  "u1f64e_1f3fe_200d_2640": "emoji_u1f64e_1f3fe_200d_2640.svg",
  "u1f64e_1f3fe_200d_2642": "emoji_u1f64e_1f3fe_200d_2642.svg",
  "u1f64e_1f3fe": "emoji_u1f64e_1f3fe.svg",
  "u1f64e_1f3ff_200d_2640": "emoji_u1f64e_1f3ff_200d_2640.svg",
  "u1f64e_1f3ff_200d_2642": "emoji_u1f64e_1f3ff_200d_2642.svg",
  "u1f64e_1f3ff": "emoji_u1f64e_1f3ff.svg",
  "u1f64e_200d_2640": "emoji_u1f64e_200d_2640.svg",
  "u1f64e_200d_2642": "emoji_u1f64e_200d_2642.svg",
  "u1f64e": "emoji_u1f64e.svg",
  "u1f64f_1f3fb": "emoji_u1f64f_1f3fb.svg",
  "u1f64f_1f3fc": "emoji_u1f64f_1f3fc.svg",
  "u1f64f_1f3fd": "emoji_u1f64f_1f3fd.svg",
  "u1f64f_1f3fe": "emoji_u1f64f_1f3fe.svg",
  "u1f64f_1f3ff": "emoji_u1f64f_1f3ff.svg",
  "u1f64f": "emoji_u1f64f.svg",
  "u1f680": "emoji_u1f680.svg",
  "u1f681": "emoji_u1f681.svg",
  "u1f682": "emoji_u1f682.svg",
  "u1f683": "emoji_u1f683.svg",
  "u1f684": "emoji_u1f684.svg",
  "u1f685": "emoji_u1f685.svg",
  "u1f686": "emoji_u1f686.svg",
  "u1f687": "emoji_u1f687.svg",
  "u1f688": "emoji_u1f688.svg",
  "u1f689": "emoji_u1f689.svg",
  "u1f68a": "emoji_u1f68a.svg",
  "u1f68b": "emoji_u1f68b.svg",
  "u1f68c": "emoji_u1f68c.svg",
  "u1f68d": "emoji_u1f68d.svg",
  "u1f68e": "emoji_u1f68e.svg",
  "u1f68f": "emoji_u1f68f.svg",
  "u1f690": "emoji_u1f690.svg",
  "u1f691": "emoji_u1f691.svg",
  "u1f692": "emoji_u1f692.svg",
  "u1f693": "emoji_u1f693.svg",
  "u1f694": "emoji_u1f694.svg",
  "u1f695": "emoji_u1f695.svg",
  "u1f696": "emoji_u1f696.svg",
  "u1f697": "emoji_u1f697.svg",
  "u1f698": "emoji_u1f698.svg",
  "u1f699": "emoji_u1f699.svg",
  "u1f69a": "emoji_u1f69a.svg",
  "u1f69b": "emoji_u1f69b.svg",
  "u1f69c": "emoji_u1f69c.svg",
  "u1f69d": "emoji_u1f69d.svg",
  "u1f69e": "emoji_u1f69e.svg",
  "u1f69f": "emoji_u1f69f.svg",
  "u1f6a0": "emoji_u1f6a0.svg",
  "u1f6a1": "emoji_u1f6a1.svg",
  "u1f6a2": "emoji_u1f6a2.svg",
  "u1f6a3_1f3fb_200d_2640": "emoji_u1f6a3_1f3fb_200d_2640.svg",
  "u1f6a3_1f3fb_200d_2642": "emoji_u1f6a3_1f3fb_200d_2642.svg",
  "u1f6a3_1f3fb": "emoji_u1f6a3_1f3fb.svg",
  "u1f6a3_1f3fc_200d_2640": "emoji_u1f6a3_1f3fc_200d_2640.svg",
  "u1f6a3_1f3fc_200d_2642": "emoji_u1f6a3_1f3fc_200d_2642.svg",
  "u1f6a3_1f3fc": "emoji_u1f6a3_1f3fc.svg",
  "u1f6a3_1f3fd_200d_2640": "emoji_u1f6a3_1f3fd_200d_2640.svg",
  "u1f6a3_1f3fd_200d_2642": "emoji_u1f6a3_1f3fd_200d_2642.svg",
  "u1f6a3_1f3fd": "emoji_u1f6a3_1f3fd.svg",
  "u1f6a3_1f3fe_200d_2640": "emoji_u1f6a3_1f3fe_200d_2640.svg",
  "u1f6a3_1f3fe_200d_2642": "emoji_u1f6a3_1f3fe_200d_2642.svg",
  "u1f6a3_1f3fe": "emoji_u1f6a3_1f3fe.svg",
  "u1f6a3_1f3ff_200d_2640": "emoji_u1f6a3_1f3ff_200d_2640.svg",
  "u1f6a3_1f3ff_200d_2642": "emoji_u1f6a3_1f3ff_200d_2642.svg",
  "u1f6a3_1f3ff": "emoji_u1f6a3_1f3ff.svg",
  "u1f6a3_200d_2640": "emoji_u1f6a3_200d_2640.svg",
  "u1f6a3_200d_2642": "emoji_u1f6a3_200d_2642.svg",
  "u1f6a3": "emoji_u1f6a3.svg",
  "u1f6a4": "emoji_u1f6a4.svg",
  "u1f6a5": "emoji_u1f6a5.svg",
  "u1f6a6": "emoji_u1f6a6.svg",
  "u1f6a7": "emoji_u1f6a7.svg",
  "u1f6a8": "emoji_u1f6a8.svg",
  "u1f6a9": "emoji_u1f6a9.svg",
  "u1f6aa": "emoji_u1f6aa.svg",
  "u1f6ab": "emoji_u1f6ab.svg",
  "u1f6ac": "emoji_u1f6ac.svg",
  "u1f6ad": "emoji_u1f6ad.svg",
  "u1f6ae": "emoji_u1f6ae.svg",
  "u1f6af": "emoji_u1f6af.svg",
  "u1f6b0": "emoji_u1f6b0.svg",
  "u1f6b1": "emoji_u1f6b1.svg",
  "u1f6b2": "emoji_u1f6b2.svg",
  "u1f6b3": "emoji_u1f6b3.svg",
  "u1f6b4_1f3fb_200d_2640": "emoji_u1f6b4_1f3fb_200d_2640.svg",
  "u1f6b4_1f3fb_200d_2642": "emoji_u1f6b4_1f3fb_200d_2642.svg",
  "u1f6b4_1f3fb": "emoji_u1f6b4_1f3fb.svg",
  "u1f6b4_1f3fc_200d_2640": "emoji_u1f6b4_1f3fc_200d_2640.svg",
  "u1f6b4_1f3fc_200d_2642": "emoji_u1f6b4_1f3fc_200d_2642.svg",
  "u1f6b4_1f3fc": "emoji_u1f6b4_1f3fc.svg",
  "u1f6b4_1f3fd_200d_2640": "emoji_u1f6b4_1f3fd_200d_2640.svg",
  "u1f6b4_1f3fd_200d_2642": "emoji_u1f6b4_1f3fd_200d_2642.svg",
  "u1f6b4_1f3fd": "emoji_u1f6b4_1f3fd.svg",
  "u1f6b4_1f3fe_200d_2640": "emoji_u1f6b4_1f3fe_200d_2640.svg",
  "u1f6b4_1f3fe_200d_2642": "emoji_u1f6b4_1f3fe_200d_2642.svg",
  "u1f6b4_1f3fe": "emoji_u1f6b4_1f3fe.svg",
  "u1f6b4_1f3ff_200d_2640": "emoji_u1f6b4_1f3ff_200d_2640.svg",
  "u1f6b4_1f3ff_200d_2642": "emoji_u1f6b4_1f3ff_200d_2642.svg",
  "u1f6b4_1f3ff": "emoji_u1f6b4_1f3ff.svg",
  "u1f6b4_200d_2640": "emoji_u1f6b4_200d_2640.svg",
  "u1f6b4_200d_2642": "emoji_u1f6b4_200d_2642.svg",
  "u1f6b4": "emoji_u1f6b4.svg",
  "u1f6b5_1f3fb_200d_2640": "emoji_u1f6b5_1f3fb_200d_2640.svg",
  "u1f6b5_1f3fb_200d_2642": "emoji_u1f6b5_1f3fb_200d_2642.svg",
  "u1f6b5_1f3fb": "emoji_u1f6b5_1f3fb.svg",
  "u1f6b5_1f3fc_200d_2640": "emoji_u1f6b5_1f3fc_200d_2640.svg",
  "u1f6b5_1f3fc_200d_2642": "emoji_u1f6b5_1f3fc_200d_2642.svg",
  "u1f6b5_1f3fc": "emoji_u1f6b5_1f3fc.svg",
  "u1f6b5_1f3fd_200d_2640": "emoji_u1f6b5_1f3fd_200d_2640.svg",
  "u1f6b5_1f3fd_200d_2642": "emoji_u1f6b5_1f3fd_200d_2642.svg",
  "u1f6b5_1f3fd": "emoji_u1f6b5_1f3fd.svg",
  "u1f6b5_1f3fe_200d_2640": "emoji_u1f6b5_1f3fe_200d_2640.svg",
  "u1f6b5_1f3fe_200d_2642": "emoji_u1f6b5_1f3fe_200d_2642.svg",
  "u1f6b5_1f3fe": "emoji_u1f6b5_1f3fe.svg",
  "u1f6b5_1f3ff_200d_2640": "emoji_u1f6b5_1f3ff_200d_2640.svg",
  "u1f6b5_1f3ff_200d_2642": "emoji_u1f6b5_1f3ff_200d_2642.svg",
  "u1f6b5_1f3ff": "emoji_u1f6b5_1f3ff.svg",
  "u1f6b5_200d_2640": "emoji_u1f6b5_200d_2640.svg",
  "u1f6b5_200d_2642": "emoji_u1f6b5_200d_2642.svg",
  "u1f6b5": "emoji_u1f6b5.svg",
  "u1f6b6_1f3fb_200d_2640": "emoji_u1f6b6_1f3fb_200d_2640.svg",
  "u1f6b6_1f3fb_200d_2642": "emoji_u1f6b6_1f3fb_200d_2642.svg",
  "u1f6b6_1f3fb": "emoji_u1f6b6_1f3fb.svg",
  "u1f6b6_1f3fc_200d_2640": "emoji_u1f6b6_1f3fc_200d_2640.svg",
  "u1f6b6_1f3fc_200d_2642": "emoji_u1f6b6_1f3fc_200d_2642.svg",
  "u1f6b6_1f3fc": "emoji_u1f6b6_1f3fc.svg",
  "u1f6b6_1f3fd_200d_2640": "emoji_u1f6b6_1f3fd_200d_2640.svg",
  "u1f6b6_1f3fd_200d_2642": "emoji_u1f6b6_1f3fd_200d_2642.svg",
  "u1f6b6_1f3fd": "emoji_u1f6b6_1f3fd.svg",
  "u1f6b6_1f3fe_200d_2640": "emoji_u1f6b6_1f3fe_200d_2640.svg",
  "u1f6b6_1f3fe_200d_2642": "emoji_u1f6b6_1f3fe_200d_2642.svg",
  "u1f6b6_1f3fe": "emoji_u1f6b6_1f3fe.svg",
  "u1f6b6_1f3ff_200d_2640": "emoji_u1f6b6_1f3ff_200d_2640.svg",
  "u1f6b6_1f3ff_200d_2642": "emoji_u1f6b6_1f3ff_200d_2642.svg",
  "u1f6b6_1f3ff": "emoji_u1f6b6_1f3ff.svg",
  "u1f6b6_200d_2640": "emoji_u1f6b6_200d_2640.svg",
  "u1f6b6_200d_2642": "emoji_u1f6b6_200d_2642.svg",
  "u1f6b6": "emoji_u1f6b6.svg",
  "u1f6b7": "emoji_u1f6b7.svg",
  "u1f6b8": "emoji_u1f6b8.svg",
  "u1f6b9": "emoji_u1f6b9.svg",
  "u1f6ba": "emoji_u1f6ba.svg",
  "u1f6bb": "emoji_u1f6bb.svg",
  "u1f6bc": "emoji_u1f6bc.svg",
  "u1f6bd": "emoji_u1f6bd.svg",
  "u1f6be": "emoji_u1f6be.svg",
  "u1f6bf": "emoji_u1f6bf.svg",
  "u1f6c0_1f3fb": "emoji_u1f6c0_1f3fb.svg",
  "u1f6c0_1f3fc": "emoji_u1f6c0_1f3fc.svg",
  "u1f6c0_1f3fd": "emoji_u1f6c0_1f3fd.svg",
  "u1f6c0_1f3fe": "emoji_u1f6c0_1f3fe.svg",
  "u1f6c0_1f3ff": "emoji_u1f6c0_1f3ff.svg",
  "u1f6c0": "emoji_u1f6c0.svg",
  "u1f6c1": "emoji_u1f6c1.svg",
  "u1f6c2": "emoji_u1f6c2.svg",
  "u1f6c3": "emoji_u1f6c3.svg",
  "u1f6c4": "emoji_u1f6c4.svg",
  "u1f6c5": "emoji_u1f6c5.svg",
  "u1f6cb": "emoji_u1f6cb.svg",
  "u1f6cc": "emoji_u1f6cc.svg",
  "u1f6cd": "emoji_u1f6cd.svg",
  "u1f6ce": "emoji_u1f6ce.svg",
  "u1f6cf": "emoji_u1f6cf.svg",
  "u1f6d0": "emoji_u1f6d0.svg",
  "u1f6d1": "emoji_u1f6d1.svg",
  "u1f6d2": "emoji_u1f6d2.svg",
  "u1f6e0": "emoji_u1f6e0.svg",
  "u1f6e1": "emoji_u1f6e1.svg",
  "u1f6e2": "emoji_u1f6e2.svg",
  "u1f6e3": "emoji_u1f6e3.svg",
  "u1f6e4": "emoji_u1f6e4.svg",
  "u1f6e5": "emoji_u1f6e5.svg",
  "u1f6e9": "emoji_u1f6e9.svg",
  "u1f6eb": "emoji_u1f6eb.svg",
  "u1f6ec": "emoji_u1f6ec.svg",
  "u1f6f0": "emoji_u1f6f0.svg",
  "u1f6f3": "emoji_u1f6f3.svg",
  "u1f6f4": "emoji_u1f6f4.svg",
  "u1f6f5": "emoji_u1f6f5.svg",
  "u1f6f6": "emoji_u1f6f6.svg",
  "u1f910": "emoji_u1f910.svg",
  "u1f911": "emoji_u1f911.svg",
  "u1f912": "emoji_u1f912.svg",
  "u1f913": "emoji_u1f913.svg",
  "u1f914": "emoji_u1f914.svg",
  "u1f915": "emoji_u1f915.svg",
  "u1f916": "emoji_u1f916.svg",
  "u1f917": "emoji_u1f917.svg",
  "u1f918_1f3fb": "emoji_u1f918_1f3fb.svg",
  "u1f918_1f3fc": "emoji_u1f918_1f3fc.svg",
  "u1f918_1f3fd": "emoji_u1f918_1f3fd.svg",
  "u1f918_1f3fe": "emoji_u1f918_1f3fe.svg",
  "u1f918_1f3ff": "emoji_u1f918_1f3ff.svg",
  "u1f918": "emoji_u1f918.svg",
  "u1f919_1f3fb": "emoji_u1f919_1f3fb.svg",
  "u1f919_1f3fc": "emoji_u1f919_1f3fc.svg",
  "u1f919_1f3fd": "emoji_u1f919_1f3fd.svg",
  "u1f919_1f3fe": "emoji_u1f919_1f3fe.svg",
  "u1f919_1f3ff": "emoji_u1f919_1f3ff.svg",
  "u1f919": "emoji_u1f919.svg",
  "u1f91a_1f3fb": "emoji_u1f91a_1f3fb.svg",
  "u1f91a_1f3fc": "emoji_u1f91a_1f3fc.svg",
  "u1f91a_1f3fd": "emoji_u1f91a_1f3fd.svg",
  "u1f91a_1f3fe": "emoji_u1f91a_1f3fe.svg",
  "u1f91a_1f3ff": "emoji_u1f91a_1f3ff.svg",
  "u1f91a": "emoji_u1f91a.svg",
  "u1f91b_1f3fb": "emoji_u1f91b_1f3fb.svg",
  "u1f91b_1f3fc": "emoji_u1f91b_1f3fc.svg",
  "u1f91b_1f3fd": "emoji_u1f91b_1f3fd.svg",
  "u1f91b_1f3fe": "emoji_u1f91b_1f3fe.svg",
  "u1f91b_1f3ff": "emoji_u1f91b_1f3ff.svg",
  "u1f91b": "emoji_u1f91b.svg",
  "u1f91c_1f3fb": "emoji_u1f91c_1f3fb.svg",
  "u1f91c_1f3fc": "emoji_u1f91c_1f3fc.svg",
  "u1f91c_1f3fd": "emoji_u1f91c_1f3fd.svg",
  "u1f91c_1f3fe": "emoji_u1f91c_1f3fe.svg",
  "u1f91c_1f3ff": "emoji_u1f91c_1f3ff.svg",
  "u1f91c": "emoji_u1f91c.svg",
  "u1f91d_1f3fb": "emoji_u1f91d_1f3fb.svg",
  "u1f91d_1f3fc": "emoji_u1f91d_1f3fc.svg",
  "u1f91d_1f3fd": "emoji_u1f91d_1f3fd.svg",
  "u1f91d_1f3fe": "emoji_u1f91d_1f3fe.svg",
  "u1f91d_1f3ff": "emoji_u1f91d_1f3ff.svg",
  "u1f91d": "emoji_u1f91d.svg",
  "u1f91e_1f3fb": "emoji_u1f91e_1f3fb.svg",
  "u1f91e_1f3fc": "emoji_u1f91e_1f3fc.svg",
  "u1f91e_1f3fd": "emoji_u1f91e_1f3fd.svg",
  "u1f91e_1f3fe": "emoji_u1f91e_1f3fe.svg",
  "u1f91e_1f3ff": "emoji_u1f91e_1f3ff.svg",
  "u1f91e": "emoji_u1f91e.svg",
  "u1f920": "emoji_u1f920.svg",
  "u1f921": "emoji_u1f921.svg",
  "u1f922": "emoji_u1f922.svg",
  "u1f923": "emoji_u1f923.svg",
  "u1f924": "emoji_u1f924.svg",
  "u1f925": "emoji_u1f925.svg",
  "u1f926_1f3fb_200d_2640": "emoji_u1f926_1f3fb_200d_2640.svg",
  "u1f926_1f3fb_200d_2642": "emoji_u1f926_1f3fb_200d_2642.svg",
  "u1f926_1f3fb": "emoji_u1f926_1f3fb.svg",
  "u1f926_1f3fc_200d_2640": "emoji_u1f926_1f3fc_200d_2640.svg",
  "u1f926_1f3fc_200d_2642": "emoji_u1f926_1f3fc_200d_2642.svg",
  "u1f926_1f3fc": "emoji_u1f926_1f3fc.svg",
  "u1f926_1f3fd_200d_2640": "emoji_u1f926_1f3fd_200d_2640.svg",
  "u1f926_1f3fd_200d_2642": "emoji_u1f926_1f3fd_200d_2642.svg",
  "u1f926_1f3fd": "emoji_u1f926_1f3fd.svg",
  "u1f926_1f3fe_200d_2640": "emoji_u1f926_1f3fe_200d_2640.svg",
  "u1f926_1f3fe_200d_2642": "emoji_u1f926_1f3fe_200d_2642.svg",
  "u1f926_1f3fe": "emoji_u1f926_1f3fe.svg",
  "u1f926_1f3ff_200d_2640": "emoji_u1f926_1f3ff_200d_2640.svg",
  "u1f926_1f3ff_200d_2642": "emoji_u1f926_1f3ff_200d_2642.svg",
  "u1f926_1f3ff": "emoji_u1f926_1f3ff.svg",
  "u1f926_200d_2640": "emoji_u1f926_200d_2640.svg",
  "u1f926_200d_2642": "emoji_u1f926_200d_2642.svg",
  "u1f926": "emoji_u1f926.svg",
  "u1f927": "emoji_u1f927.svg",
  "u1f930_1f3fb": "emoji_u1f930_1f3fb.svg",
  "u1f930_1f3fc": "emoji_u1f930_1f3fc.svg",
  "u1f930_1f3fd": "emoji_u1f930_1f3fd.svg",
  "u1f930_1f3fe": "emoji_u1f930_1f3fe.svg",
  "u1f930_1f3ff": "emoji_u1f930_1f3ff.svg",
  "u1f930": "emoji_u1f930.svg",
  "u1f933_1f3fb": "emoji_u1f933_1f3fb.svg",
  "u1f933_1f3fc": "emoji_u1f933_1f3fc.svg",
  "u1f933_1f3fd": "emoji_u1f933_1f3fd.svg",
  "u1f933_1f3fe": "emoji_u1f933_1f3fe.svg",
  "u1f933_1f3ff": "emoji_u1f933_1f3ff.svg",
  "u1f933": "emoji_u1f933.svg",
  "u1f934_1f3fb": "emoji_u1f934_1f3fb.svg",
  "u1f934_1f3fc": "emoji_u1f934_1f3fc.svg",
  "u1f934_1f3fd": "emoji_u1f934_1f3fd.svg",
  "u1f934_1f3fe": "emoji_u1f934_1f3fe.svg",
  "u1f934_1f3ff": "emoji_u1f934_1f3ff.svg",
  "u1f934": "emoji_u1f934.svg",
  "u1f935_1f3fb": "emoji_u1f935_1f3fb.svg",
  "u1f935_1f3fc": "emoji_u1f935_1f3fc.svg",
  "u1f935_1f3fd": "emoji_u1f935_1f3fd.svg",
  "u1f935_1f3fe": "emoji_u1f935_1f3fe.svg",
  "u1f935_1f3ff": "emoji_u1f935_1f3ff.svg",
  "u1f935": "emoji_u1f935.svg",
  "u1f936_1f3fb": "emoji_u1f936_1f3fb.svg",
  "u1f936_1f3fc": "emoji_u1f936_1f3fc.svg",
  "u1f936_1f3fd": "emoji_u1f936_1f3fd.svg",
  "u1f936_1f3fe": "emoji_u1f936_1f3fe.svg",
  "u1f936_1f3ff": "emoji_u1f936_1f3ff.svg",
  "u1f936": "emoji_u1f936.svg",
  "u1f937_1f3fb_200d_2640": "emoji_u1f937_1f3fb_200d_2640.svg",
  "u1f937_1f3fb_200d_2642": "emoji_u1f937_1f3fb_200d_2642.svg",
  "u1f937_1f3fb": "emoji_u1f937_1f3fb.svg",
  "u1f937_1f3fc_200d_2640": "emoji_u1f937_1f3fc_200d_2640.svg",
  "u1f937_1f3fc_200d_2642": "emoji_u1f937_1f3fc_200d_2642.svg",
  "u1f937_1f3fc": "emoji_u1f937_1f3fc.svg",
  "u1f937_1f3fd_200d_2640": "emoji_u1f937_1f3fd_200d_2640.svg",
  "u1f937_1f3fd_200d_2642": "emoji_u1f937_1f3fd_200d_2642.svg",
  "u1f937_1f3fd": "emoji_u1f937_1f3fd.svg",
  "u1f937_1f3fe_200d_2640": "emoji_u1f937_1f3fe_200d_2640.svg",
  "u1f937_1f3fe_200d_2642": "emoji_u1f937_1f3fe_200d_2642.svg",
  "u1f937_1f3fe": "emoji_u1f937_1f3fe.svg",
  "u1f937_1f3ff_200d_2640": "emoji_u1f937_1f3ff_200d_2640.svg",
  "u1f937_1f3ff_200d_2642": "emoji_u1f937_1f3ff_200d_2642.svg",
  "u1f937_1f3ff": "emoji_u1f937_1f3ff.svg",
  "u1f937_200d_2640": "emoji_u1f937_200d_2640.svg",
  "u1f937_200d_2642": "emoji_u1f937_200d_2642.svg",
  "u1f937": "emoji_u1f937.svg",
  "u1f938_1f3fb_200d_2640": "emoji_u1f938_1f3fb_200d_2640.svg",
  "u1f938_1f3fb_200d_2642": "emoji_u1f938_1f3fb_200d_2642.svg",
  "u1f938_1f3fb": "emoji_u1f938_1f3fb.svg",
  "u1f938_1f3fc_200d_2640": "emoji_u1f938_1f3fc_200d_2640.svg",
  "u1f938_1f3fc_200d_2642": "emoji_u1f938_1f3fc_200d_2642.svg",
  "u1f938_1f3fc": "emoji_u1f938_1f3fc.svg",
  "u1f938_1f3fd_200d_2640": "emoji_u1f938_1f3fd_200d_2640.svg",
  "u1f938_1f3fd_200d_2642": "emoji_u1f938_1f3fd_200d_2642.svg",
  "u1f938_1f3fd": "emoji_u1f938_1f3fd.svg",
  "u1f938_1f3fe_200d_2640": "emoji_u1f938_1f3fe_200d_2640.svg",
  "u1f938_1f3fe_200d_2642": "emoji_u1f938_1f3fe_200d_2642.svg",
  "u1f938_1f3fe": "emoji_u1f938_1f3fe.svg",
  "u1f938_1f3ff_200d_2640": "emoji_u1f938_1f3ff_200d_2640.svg",
  "u1f938_1f3ff_200d_2642": "emoji_u1f938_1f3ff_200d_2642.svg",
  "u1f938_1f3ff": "emoji_u1f938_1f3ff.svg",
  "u1f938_200d_2640": "emoji_u1f938_200d_2640.svg",
  "u1f938_200d_2642": "emoji_u1f938_200d_2642.svg",
  "u1f938": "emoji_u1f938.svg",
  "u1f939_1f3fb_200d_2640": "emoji_u1f939_1f3fb_200d_2640.svg",
  "u1f939_1f3fb_200d_2642": "emoji_u1f939_1f3fb_200d_2642.svg",
  "u1f939_1f3fb": "emoji_u1f939_1f3fb.svg",
  "u1f939_1f3fc_200d_2640": "emoji_u1f939_1f3fc_200d_2640.svg",
  "u1f939_1f3fc_200d_2642": "emoji_u1f939_1f3fc_200d_2642.svg",
  "u1f939_1f3fc": "emoji_u1f939_1f3fc.svg",
  "u1f939_1f3fd_200d_2640": "emoji_u1f939_1f3fd_200d_2640.svg",
  "u1f939_1f3fd_200d_2642": "emoji_u1f939_1f3fd_200d_2642.svg",
  "u1f939_1f3fd": "emoji_u1f939_1f3fd.svg",
  "u1f939_1f3fe_200d_2640": "emoji_u1f939_1f3fe_200d_2640.svg",
  "u1f939_1f3fe_200d_2642": "emoji_u1f939_1f3fe_200d_2642.svg",
  "u1f939_1f3fe": "emoji_u1f939_1f3fe.svg",
  "u1f939_1f3ff_200d_2640": "emoji_u1f939_1f3ff_200d_2640.svg",
  "u1f939_1f3ff_200d_2642": "emoji_u1f939_1f3ff_200d_2642.svg",
  "u1f939_1f3ff": "emoji_u1f939_1f3ff.svg",
  "u1f939_200d_2640": "emoji_u1f939_200d_2640.svg",
  "u1f939_200d_2642": "emoji_u1f939_200d_2642.svg",
  "u1f939": "emoji_u1f939.svg",
  "u1f93a": "emoji_u1f93a.svg",
  "u1f93c_1f3fb_200d_2640": "emoji_u1f93c_1f3fb_200d_2640.svg",
  "u1f93c_1f3fb_200d_2642": "emoji_u1f93c_1f3fb_200d_2642.svg",
  "u1f93c_1f3fb": "emoji_u1f93c_1f3fb.svg",
  "u1f93c_1f3fc_200d_2640": "emoji_u1f93c_1f3fc_200d_2640.svg",
  "u1f93c_1f3fc_200d_2642": "emoji_u1f93c_1f3fc_200d_2642.svg",
  "u1f93c_1f3fc": "emoji_u1f93c_1f3fc.svg",
  "u1f93c_1f3fd_200d_2640": "emoji_u1f93c_1f3fd_200d_2640.svg",
  "u1f93c_1f3fd_200d_2642": "emoji_u1f93c_1f3fd_200d_2642.svg",
  "u1f93c_1f3fd": "emoji_u1f93c_1f3fd.svg",
  "u1f93c_1f3fe_200d_2640": "emoji_u1f93c_1f3fe_200d_2640.svg",
  "u1f93c_1f3fe_200d_2642": "emoji_u1f93c_1f3fe_200d_2642.svg",
  "u1f93c_1f3fe": "emoji_u1f93c_1f3fe.svg",
  "u1f93c_1f3ff_200d_2640": "emoji_u1f93c_1f3ff_200d_2640.svg",
  "u1f93c_1f3ff_200d_2642": "emoji_u1f93c_1f3ff_200d_2642.svg",
  "u1f93c_1f3ff": "emoji_u1f93c_1f3ff.svg",
  "u1f93c_200d_2640": "emoji_u1f93c_200d_2640.svg",
  "u1f93c_200d_2642": "emoji_u1f93c_200d_2642.svg",
  "u1f93c": "emoji_u1f93c.svg",
  "u1f93d_1f3fb_200d_2640": "emoji_u1f93d_1f3fb_200d_2640.svg",
  "u1f93d_1f3fb_200d_2642": "emoji_u1f93d_1f3fb_200d_2642.svg",
  "u1f93d_1f3fb": "emoji_u1f93d_1f3fb.svg",
  "u1f93d_1f3fc_200d_2640": "emoji_u1f93d_1f3fc_200d_2640.svg",
  "u1f93d_1f3fc_200d_2642": "emoji_u1f93d_1f3fc_200d_2642.svg",
  "u1f93d_1f3fc": "emoji_u1f93d_1f3fc.svg",
  "u1f93d_1f3fd_200d_2640": "emoji_u1f93d_1f3fd_200d_2640.svg",
  "u1f93d_1f3fd_200d_2642": "emoji_u1f93d_1f3fd_200d_2642.svg",
  "u1f93d_1f3fd": "emoji_u1f93d_1f3fd.svg",
  "u1f93d_1f3fe_200d_2640": "emoji_u1f93d_1f3fe_200d_2640.svg",
  "u1f93d_1f3fe_200d_2642": "emoji_u1f93d_1f3fe_200d_2642.svg",
  "u1f93d_1f3fe": "emoji_u1f93d_1f3fe.svg",
  "u1f93d_1f3ff_200d_2640": "emoji_u1f93d_1f3ff_200d_2640.svg",
  "u1f93d_1f3ff_200d_2642": "emoji_u1f93d_1f3ff_200d_2642.svg",
  "u1f93d_1f3ff": "emoji_u1f93d_1f3ff.svg",
  "u1f93d_200d_2640": "emoji_u1f93d_200d_2640.svg",
  "u1f93d_200d_2642": "emoji_u1f93d_200d_2642.svg",
  "u1f93d": "emoji_u1f93d.svg",
  "u1f93e_1f3fb_200d_2640": "emoji_u1f93e_1f3fb_200d_2640.svg",
  "u1f93e_1f3fb_200d_2642": "emoji_u1f93e_1f3fb_200d_2642.svg",
  "u1f93e_1f3fb": "emoji_u1f93e_1f3fb.svg",
  "u1f93e_1f3fc_200d_2640": "emoji_u1f93e_1f3fc_200d_2640.svg",
  "u1f93e_1f3fc_200d_2642": "emoji_u1f93e_1f3fc_200d_2642.svg",
  "u1f93e_1f3fc": "emoji_u1f93e_1f3fc.svg",
  "u1f93e_1f3fd_200d_2640": "emoji_u1f93e_1f3fd_200d_2640.svg",
  "u1f93e_1f3fd_200d_2642": "emoji_u1f93e_1f3fd_200d_2642.svg",
  "u1f93e_1f3fd": "emoji_u1f93e_1f3fd.svg",
  "u1f93e_1f3fe_200d_2640": "emoji_u1f93e_1f3fe_200d_2640.svg",
  "u1f93e_1f3fe_200d_2642": "emoji_u1f93e_1f3fe_200d_2642.svg",
  "u1f93e_1f3fe": "emoji_u1f93e_1f3fe.svg",
  "u1f93e_1f3ff_200d_2640": "emoji_u1f93e_1f3ff_200d_2640.svg",
  "u1f93e_1f3ff_200d_2642": "emoji_u1f93e_1f3ff_200d_2642.svg",
  "u1f93e_1f3ff": "emoji_u1f93e_1f3ff.svg",
  "u1f93e_200d_2640": "emoji_u1f93e_200d_2640.svg",
  "u1f93e_200d_2642": "emoji_u1f93e_200d_2642.svg",
  "u1f93e": "emoji_u1f93e.svg",
  "u1f940": "emoji_u1f940.svg",
  "u1f941": "emoji_u1f941.svg",
  "u1f942": "emoji_u1f942.svg",
  "u1f943": "emoji_u1f943.svg",
  "u1f944": "emoji_u1f944.svg",
  "u1f945": "emoji_u1f945.svg",
  "u1f947": "emoji_u1f947.svg",
  "u1f948": "emoji_u1f948.svg",
  "u1f949": "emoji_u1f949.svg",
  "u1f94a": "emoji_u1f94a.svg",
  "u1f94b": "emoji_u1f94b.svg",
  "u1f950": "emoji_u1f950.svg",
  "u1f951": "emoji_u1f951.svg",
  "u1f952": "emoji_u1f952.svg",
  "u1f953": "emoji_u1f953.svg",
  "u1f954": "emoji_u1f954.svg",
  "u1f955": "emoji_u1f955.svg",
  "u1f956": "emoji_u1f956.svg",
  "u1f957": "emoji_u1f957.svg",
  "u1f958": "emoji_u1f958.svg",
  "u1f959": "emoji_u1f959.svg",
  "u1f95a": "emoji_u1f95a.svg",
  "u1f95b": "emoji_u1f95b.svg",
  "u1f95c": "emoji_u1f95c.svg",
  "u1f95d": "emoji_u1f95d.svg",
  "u1f95e": "emoji_u1f95e.svg",
  "u1f980": "emoji_u1f980.svg",
  "u1f981": "emoji_u1f981.svg",
  "u1f982": "emoji_u1f982.svg",
  "u1f983": "emoji_u1f983.svg",
  "u1f984": "emoji_u1f984.svg",
  "u1f985": "emoji_u1f985.svg",
  "u1f986": "emoji_u1f986.svg",
  "u1f987": "emoji_u1f987.svg",
  "u1f988": "emoji_u1f988.svg",
  "u1f989": "emoji_u1f989.svg",
  "u1f98a": "emoji_u1f98a.svg",
  "u1f98b": "emoji_u1f98b.svg",
  "u1f98c": "emoji_u1f98c.svg",
  "u1f98d": "emoji_u1f98d.svg",
  "u1f98e": "emoji_u1f98e.svg",
  "u1f98f": "emoji_u1f98f.svg",
  "u1f990": "emoji_u1f990.svg",
  "u1f991": "emoji_u1f991.svg",
  "u1f9c0": "emoji_u1f9c0.svg",
  "u203c": "emoji_u203c.svg",
  "u2049": "emoji_u2049.svg",
  "u20e3": "emoji_u20e3.svg",
  "u2122": "emoji_u2122.svg",
  "u2139": "emoji_u2139.svg",
  "u2194": "emoji_u2194.svg",
  "u2195": "emoji_u2195.svg",
  "u2196": "emoji_u2196.svg",
  "u2197": "emoji_u2197.svg",
  "u2198": "emoji_u2198.svg",
  "u2199": "emoji_u2199.svg",
  "u21a9": "emoji_u21a9.svg",
  "u21aa": "emoji_u21aa.svg",
  "u231a": "emoji_u231a.svg",
  "u231b": "emoji_u231b.svg",
  "u2328": "emoji_u2328.svg",
  "u23cf": "emoji_u23cf.svg",
  "u23e9": "emoji_u23e9.svg",
  "u23ea": "emoji_u23ea.svg",
  "u23eb": "emoji_u23eb.svg",
  "u23ec": "emoji_u23ec.svg",
  "u23ed": "emoji_u23ed.svg",
  "u23ee": "emoji_u23ee.svg",
  "u23ef": "emoji_u23ef.svg",
  "u23f0": "emoji_u23f0.svg",
  "u23f1": "emoji_u23f1.svg",
  "u23f2": "emoji_u23f2.svg",
  "u23f3": "emoji_u23f3.svg",
  "u23f8": "emoji_u23f8.svg",
  "u23f9": "emoji_u23f9.svg",
  "u23fa": "emoji_u23fa.svg",
  "u24c2": "emoji_u24c2.svg",
  "u25aa": "emoji_u25aa.svg",
  "u25ab": "emoji_u25ab.svg",
  "u25b6": "emoji_u25b6.svg",
  "u25c0": "emoji_u25c0.svg",
  "u25fb": "emoji_u25fb.svg",
  "u25fc": "emoji_u25fc.svg",
  "u25fd": "emoji_u25fd.svg",
  "u25fe": "emoji_u25fe.svg",
  "u2600": "emoji_u2600.svg",
  "u2601": "emoji_u2601.svg",
  "u2602": "emoji_u2602.svg",
  "u2603": "emoji_u2603.svg",
  "u2604": "emoji_u2604.svg",
  "u260e": "emoji_u260e.svg",
  "u2611": "emoji_u2611.svg",
  "u2614": "emoji_u2614.svg",
  "u2615": "emoji_u2615.svg",
  "u2618": "emoji_u2618.svg",
  "u261d_1f3fb": "emoji_u261d_1f3fb.svg",
  "u261d_1f3fc": "emoji_u261d_1f3fc.svg",
  "u261d_1f3fd": "emoji_u261d_1f3fd.svg",
  "u261d_1f3fe": "emoji_u261d_1f3fe.svg",
  "u261d_1f3ff": "emoji_u261d_1f3ff.svg",
  "u261d": "emoji_u261d.svg",
  "u2620": "emoji_u2620.svg",
  "u2622": "emoji_u2622.svg",
  "u2623": "emoji_u2623.svg",
  "u2626": "emoji_u2626.svg",
  "u262a": "emoji_u262a.svg",
  "u262e": "emoji_u262e.svg",
  "u262f": "emoji_u262f.svg",
  "u2638": "emoji_u2638.svg",
  "u2639": "emoji_u2639.svg",
  "u263a": "emoji_u263a.svg",
  "u2640": "emoji_u2640.svg",
  "u2642": "emoji_u2642.svg",
  "u2648": "emoji_u2648.svg",
  "u2649": "emoji_u2649.svg",
  "u264a": "emoji_u264a.svg",
  "u264b": "emoji_u264b.svg",
  "u264c": "emoji_u264c.svg",
  "u264d": "emoji_u264d.svg",
  "u264e": "emoji_u264e.svg",
  "u264f": "emoji_u264f.svg",
  "u2650": "emoji_u2650.svg",
  "u2651": "emoji_u2651.svg",
  "u2652": "emoji_u2652.svg",
  "u2653": "emoji_u2653.svg",
  "u2660": "emoji_u2660.svg",
  "u2663": "emoji_u2663.svg",
  "u2665": "emoji_u2665.svg",
  "u2666": "emoji_u2666.svg",
  "u2668": "emoji_u2668.svg",
  "u267b": "emoji_u267b.svg",
  "u267f": "emoji_u267f.svg",
  "u2692": "emoji_u2692.svg",
  "u2693": "emoji_u2693.svg",
  "u2694": "emoji_u2694.svg",
  "u2695": "emoji_u2695.svg",
  "u2696": "emoji_u2696.svg",
  "u2697": "emoji_u2697.svg",
  "u2699": "emoji_u2699.svg",
  "u269b": "emoji_u269b.svg",
  "u269c": "emoji_u269c.svg",
  "u26a0": "emoji_u26a0.svg",
  "u26a1": "emoji_u26a1.svg",
  "u26aa": "emoji_u26aa.svg",
  "u26ab": "emoji_u26ab.svg",
  "u26b0": "emoji_u26b0.svg",
  "u26b1": "emoji_u26b1.svg",
  "u26bd": "emoji_u26bd.svg",
  "u26be": "emoji_u26be.svg",
  "u26c4": "emoji_u26c4.svg",
  "u26c5": "emoji_u26c5.svg",
  "u26c8": "emoji_u26c8.svg",
  "u26ce": "emoji_u26ce.svg",
  "u26cf": "emoji_u26cf.svg",
  "u26d1": "emoji_u26d1.svg",
  "u26d3": "emoji_u26d3.svg",
  "u26d4": "emoji_u26d4.svg",
  "u26e9": "emoji_u26e9.svg",
  "u26ea": "emoji_u26ea.svg",
  "u26f0": "emoji_u26f0.svg",
  "u26f1": "emoji_u26f1.svg",
  "u26f2": "emoji_u26f2.svg",
  "u26f3": "emoji_u26f3.svg",
  "u26f4": "emoji_u26f4.svg",
  "u26f5": "emoji_u26f5.svg",
  "u26f7": "emoji_u26f7.svg",
  "u26f8": "emoji_u26f8.svg",
  "u26f9_1f3fb_200d_2640": "emoji_u26f9_1f3fb_200d_2640.svg",
  "u26f9_1f3fb_200d_2642": "emoji_u26f9_1f3fb_200d_2642.svg",
  "u26f9_1f3fb": "emoji_u26f9_1f3fb.svg",
  "u26f9_1f3fc_200d_2640": "emoji_u26f9_1f3fc_200d_2640.svg",
  "u26f9_1f3fc_200d_2642": "emoji_u26f9_1f3fc_200d_2642.svg",
  "u26f9_1f3fc": "emoji_u26f9_1f3fc.svg",
  "u26f9_1f3fd_200d_2640": "emoji_u26f9_1f3fd_200d_2640.svg",
  "u26f9_1f3fd_200d_2642": "emoji_u26f9_1f3fd_200d_2642.svg",
  "u26f9_1f3fd": "emoji_u26f9_1f3fd.svg",
  "u26f9_1f3fe_200d_2640": "emoji_u26f9_1f3fe_200d_2640.svg",
  "u26f9_1f3fe_200d_2642": "emoji_u26f9_1f3fe_200d_2642.svg",
  "u26f9_1f3fe": "emoji_u26f9_1f3fe.svg",
  "u26f9_1f3ff_200d_2640": "emoji_u26f9_1f3ff_200d_2640.svg",
  "u26f9_1f3ff_200d_2642": "emoji_u26f9_1f3ff_200d_2642.svg",
  "u26f9_1f3ff": "emoji_u26f9_1f3ff.svg",
  "u26f9_200d_2640": "emoji_u26f9_200d_2640.svg",
  "u26f9_200d_2642": "emoji_u26f9_200d_2642.svg",
  "u26f9": "emoji_u26f9.svg",
  "u26fa": "emoji_u26fa.svg",
  "u26fd": "emoji_u26fd.svg",
  "u2702": "emoji_u2702.svg",
  "u2705": "emoji_u2705.svg",
  "u2708": "emoji_u2708.svg",
  "u2709": "emoji_u2709.svg",
  "u270a_1f3fb": "emoji_u270a_1f3fb.svg",
  "u270a_1f3fc": "emoji_u270a_1f3fc.svg",
  "u270a_1f3fd": "emoji_u270a_1f3fd.svg",
  "u270a_1f3fe": "emoji_u270a_1f3fe.svg",
  "u270a_1f3ff": "emoji_u270a_1f3ff.svg",
  "u270a": "emoji_u270a.svg",
  "u270b_1f3fb": "emoji_u270b_1f3fb.svg",
  "u270b_1f3fc": "emoji_u270b_1f3fc.svg",
  "u270b_1f3fd": "emoji_u270b_1f3fd.svg",
  "u270b_1f3fe": "emoji_u270b_1f3fe.svg",
  "u270b_1f3ff": "emoji_u270b_1f3ff.svg",
  "u270b": "emoji_u270b.svg",
  "u270c_1f3fb": "emoji_u270c_1f3fb.svg",
  "u270c_1f3fc": "emoji_u270c_1f3fc.svg",
  "u270c_1f3fd": "emoji_u270c_1f3fd.svg",
  "u270c_1f3fe": "emoji_u270c_1f3fe.svg",
  "u270c_1f3ff": "emoji_u270c_1f3ff.svg",
  "u270c": "emoji_u270c.svg",
  "u270d_1f3fb": "emoji_u270d_1f3fb.svg",
  "u270d_1f3fc": "emoji_u270d_1f3fc.svg",
  "u270d_1f3fd": "emoji_u270d_1f3fd.svg",
  "u270d_1f3fe": "emoji_u270d_1f3fe.svg",
  "u270d_1f3ff": "emoji_u270d_1f3ff.svg",
  "u270d": "emoji_u270d.svg",
  "u270f": "emoji_u270f.svg",
  "u2712": "emoji_u2712.svg",
  "u2714": "emoji_u2714.svg",
  "u2716": "emoji_u2716.svg",
  "u271d": "emoji_u271d.svg",
  "u2721": "emoji_u2721.svg",
  "u2728": "emoji_u2728.svg",
  "u2733": "emoji_u2733.svg",
  "u2734": "emoji_u2734.svg",
  "u2744": "emoji_u2744.svg",
  "u2747": "emoji_u2747.svg",
  "u274c": "emoji_u274c.svg",
  "u274e": "emoji_u274e.svg",
  "u2753": "emoji_u2753.svg",
  "u2754": "emoji_u2754.svg",
  "u2755": "emoji_u2755.svg",
  "u2757": "emoji_u2757.svg",
  "u2763": "emoji_u2763.svg",
  "u2764": "emoji_u2764.svg",
  "u2795": "emoji_u2795.svg",
  "u2796": "emoji_u2796.svg",
  "u2797": "emoji_u2797.svg",
  "u27a1": "emoji_u27a1.svg",
  "u27b0": "emoji_u27b0.svg",
  "u27bf": "emoji_u27bf.svg",
  "u2934": "emoji_u2934.svg",
  "u2935": "emoji_u2935.svg",
  "u2b05": "emoji_u2b05.svg",
  "u2b06": "emoji_u2b06.svg",
  "u2b07": "emoji_u2b07.svg",
  "u2b1b": "emoji_u2b1b.svg",
  "u2b1c": "emoji_u2b1c.svg",
  "u2b50": "emoji_u2b50.svg",
  "u2b55": "emoji_u2b55.svg",
  "u3030": "emoji_u3030.svg",
  "u303d": "emoji_u303d.svg",
  "u3297": "emoji_u3297.svg",
  "u3299": "emoji_u3299.svg",
  "ufe82b": "emoji_ufe82b.svg",
};

// function replaceEmoji(text) {
//   for (const key in EMOJI_MAP) {
//     if (EMOJI_MAP.hasOwnProperty(key)) {
//       const value = EMOJI_MAP[key];
//       const img = `<img class="emoji" src="/img/emoji/${value}"/>`;
//       const charcode = '\\' + key;
//       if (key == 'u270a') {
//         debugger;
//       }

//       var regex = new RegExp(charcode, "g");
//       text.replace(regex, img)
//     }
//   }
//   return text;
// }

const replaceEmojis = function (string) {

  // const emojis = string.match(/[\p{Emoji}\u200d]+/gu);
  const emojis = string.match(/[[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}\u{200d}]*/ug);

  // replace emojis with SVGs
  emojis.filter(e => e != '').forEach(emoji => {
    // get the unicodes of the emoji
    let unicode = "";

    function getNextChar(pointer) {
      const subUnicode = emoji.codePointAt(pointer);
      if (!subUnicode) return;
      unicode += '-' + subUnicode.toString(16);
      getNextChar(++pointer);
    }

    getNextChar(0);

    unicode = unicode.substr(1); // remove the beginning dash '-'
    console.log(unicode);
    const number = emoji.codePointAt(0);
    const toUtf16 = 'u' + number.toString(16);
    console.log(toUtf16);
    if (EMOJI_MAP[toUtf16]) {
      const imgName = EMOJI_MAP[toUtf16];
      string = string.replace(emoji, `<span style="margin: 0 5px; vertical-align: middle;"><img style="width:20px; height:20px" src='http://media.rds.it/hbbtv/img/emoji/${imgName}'></span>`);
    }
    // replace emoji here

  });

  return string;
};

export { replaceEmojis };