import { eventBus, EVENTS } from '../services/EventBus';
import cookieService from '../services/CookieService';
import store from '../Store';
const gtag = window.gtag;
const STAY_TIMER = 3;//MINUTES
let i = 1;
class Analytics {
  constructor() {
    this.initListeners();
    // this.send('user connected', store.uuid);
    setInterval(() => {
      //quarter-hour stay
      // this.send('stay', i * STAY_TIMER);

      gtag('event', 'stay', { 'time': (i * STAY_TIMER) + ' minuti' });
      i++;
    }, STAY_TIMER * 60 * 1000);
  }
  send(label, obj) {
    try {
      gtag('event', label, { 'name': obj });

      // ga('send', 'event', label, 'show', obj);
    } catch (error) {
      console.log(error);
    }
    console.log(`analytics send ${name} event`, obj);
  }
  initListeners() {
    eventBus.on(EVENTS.SHOW_RIGHT_BLOCK, ({ widget }) => {
      this.send('widget', widget);
    });
    eventBus.on(EVENTS.SHOW_VIDEO, (e) => {
      if (!store.isPlayingVideo) {
        let song = e.title + ' | ' + e.subtitle;
        if (store.isMixato) {
          song = 'Sequenza mixata';
        }
        this.send('video', song);
      }
    });
    eventBus.on(EVENTS.SHOW_FULL_PAGE_VIDEO_PREVIEW, (e) => {
      console.log(e);
      // this.send('video', song);
    });
    eventBus.on(EVENTS.SHOW_FULL_PAGE_VIDEO, (e) => {
      console.log(e);
      // this.send('video', song);
    });
  }
}
class FakeAnalytics {
  send() {
    console.log('analytics is disabled');
  }
}

let analytics;
const cookieEnabled = cookieService.getCookie('_rds_cookie_enabled');
const analitycsCookieEnabled = (cookieService.getCookie('cookieFlags') && cookieService.getCookie('cookieFlags')._m);
if (!cookieEnabled || !analitycsCookieEnabled) {
  analytics = new FakeAnalytics();
} else {
  analytics = new Analytics();
}



export default analytics;