<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Iconly/Light-Outline/Arrow - Up Circle</title>
    <g
      id="Iconly/Light-Outline/Arrow---Up-Circle"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Arrow---Up-Circle"
        transform="translate(2.000000, 2.000000)"
        fill="#000000"
      >
        <path
          d="M10,0 C15.514,0 20,4.486 20,10 C20,15.514 15.514,20 10,20 C4.486,20 0,15.514 0,10 C0,4.486 4.486,0 10,0 Z M10,1.5 C5.313,1.5 1.5,5.313 1.5,10 C1.5,14.687 5.313,18.5 10,18.5 C14.687,18.5 18.5,14.687 18.5,10 C18.5,5.313 14.687,1.5 10,1.5 Z M10.5317,7.4264 L14.0027,10.9134 C14.2947,11.2074 14.2937,11.6814 14.0007,11.9734 C13.8547,12.1194 13.6627,12.1924 13.4717,12.1924 C13.2797,12.1924 13.0867,12.1194 12.9407,11.9714 L9.9997,9.0184 L7.0607,11.9714 C6.7687,12.2654 6.2937,12.2654 5.9997,11.9734 C5.7067,11.6814 5.7047,11.2074 5.9977,10.9134 L9.4687,7.4264 C9.7497,7.1434 10.2497,7.1434 10.5317,7.4264 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'IconUp'
};
</script>
