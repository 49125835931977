<template>
  <div class="bar-down-box">
    <div class="bar-down-box-button" :class="item.color"></div>
    <div style="position: relative">
      <div class="cities">
        <div v-for="city in cities" :key="city.name">{{ city.name }}</div>
      </div>

      <span
        :class="{ disabled: !item.enabled(store) }"
        style="visibility: hidden; padding: 0 20px;"
      >Reggio Calabria</span>
    </div>

    <div class="arrow-container">
      <div :class="{'pressed':pressUp}" class="choice-arrow choice-arrow-up">&lt;</div>
      <div :class="{'pressed':pressDown}" class="choice-arrow choice-arrow-down">&lt;</div>
    </div>
    <div style="margin-left: 20px">Seleziona la tua città con le frecce e conferma premendo OK</div>
  </div>
</template>
<script>
import store from '../Store';
import CONFIG from '../config';
import { eventBus, EVENTS } from '../services/EventBus';
export default {
  name: 'MenuItemCityChoice',
  props: ['item', 'choiching'],
  data() {
    return {
      store: store,
      citiesIdx: this.getCityIdx(),
      pressUp: false,
      pressDown: false
    };
  },
  methods: {
    getCityIdx() {
      let citiesIdx = 1;
      if (store.currentCity && store.currentCity.name) {
        citiesIdx = CONFIG.cities.findIndex(
          c => c.name == store.currentCity.name
        );
      }
      return citiesIdx;
    },
    selectCity() {
      const city = this.cities[1];
      console.log(city);
      eventBus.emit(EVENTS.SET_CITY, city);
    },
    goUp() {
      console.log(this.citiesIdx);
      this.citiesIdx--;
      if (this.citiesIdx < 0) {
        this.citiesIdx = CONFIG.cities.length - 1;
      }
      this.pressUp = true;
      setTimeout(() => {
        this.pressUp = false;
      }, 200);
    },
    goDown() {
      this.citiesIdx++;
      if (this.citiesIdx > CONFIG.cities.length - 1) {
        this.citiesIdx = 0;
      }
      this.pressDown = true;
      setTimeout(() => {
        this.pressDown = false;
      }, 200);
    }
  },
  computed: {
    cities() {
      let i = 0;
      let citiesIdx = this.citiesIdx;
      console.log('citiesIdx:', citiesIdx);
      let _cities = CONFIG.cities
        .map(c => {
          c.id = i++;
          return c;
        })
        .filter(c => {
          let toreturn =
            c.id == citiesIdx || c.id == citiesIdx - 1 || c.id == citiesIdx + 1;
          if (!toreturn && citiesIdx + 1 == CONFIG.cities.length) {
            toreturn = c.id == 0;
            c.id = CONFIG.cities.length;
            // } else if (
            //   !toreturn &&
            //   citiesIdx.id + 1 == CONFIG.cities.length + 1
            // ) {
            //   toreturn = c.id == 1;
          }
          if (!toreturn && citiesIdx - 1 == -1) {
            console.log('--', c.id, c.id == CONFIG.cities.length - 1);
            toreturn = c.id == CONFIG.cities.length - 1;
            c.id = -1;
            // }else if(!toreturn && citiesIdx.id - 1 == -1){
            //   toreturn = c.id == CONFIG.cities.length-1;
          }
          return toreturn;
        });
      return _cities.sort((a, b) => a.id - b.id);
    }
  },
  mounted() {
    eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key == 'ArrowUp' || key == window.KeyEvent.VK_UP) {
        this.goUp();
      } else if (key == 'ArrowDown' || key == window.KeyEvent.VK_DOWN) {
        this.goDown();
      } else if (key == 'Enter' || key == window.KeyEvent.VK_ENTER) {
        this.selectCity();
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.bar-down-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 10px 10px;
  padding: 20px;
  color: rgb(255, 255, 255);
  font-family: 'Gilroy', sans-serif;
  font-size: 23px;
  span {
    line-height: 1em;
    margin-left: 10px;
    margin-top: 5px;
    &.disabled {
      opacity: 0.3;
    }
  }
}
.cities {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 10px;
  top: -30px;
  text-align: center;
  & > div:nth-child(2) {
    color: rgba(0, 0, 0, 0.7);
    background-color: white;
    margin: 0 15px;
  }
}
.arrow-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.choice-arrow {
  width: 10px;
  &.pressed {
    opacity: 0.5;
  }
  &.choice-arrow-down {
    transform: rotate(-90deg);
  }
  &.choice-arrow-up {
    transform: rotate(90deg);
    margin-left: -3px;
  }
}
.bar-down-box-button {
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;
  width: 17px;
  height: 17px;
  border-radius: 20px;

  &.red {
    background-color: rgb(199, 43, 62);
    border: 4px solid rgb(199, 43, 62);
  }
  &.green {
    background: green none repeat scroll 0% 0%;
    border: 4px solid green;
  }
  &.yellow {
    background: yellow none repeat scroll 0% 0%;
    border: 4px solid yellow;
  }
  &.blue {
    background: blue none repeat scroll 0% 0%;
    border: 4px solid blue;
  }
}
</style>