<template>
  <div class="cookie-policy-full" v-if="!fullPolicy">
    <div class="cookie-header">
      <h3>Impostazioni di tracciamento</h3>
      <div>
        Il seguente pannello ti consente di esprimere le tue preferenze di
        consenso alle tecnologie di tracciamento che adottiamo per offrire le
        funzionalità e svolgere le attività sotto descritte. Per ottenere
        ulteriori informazioni in merito all'utilità e al funzionamento di tali
        strumenti di tracciamento, fai riferimento alla Gestione dei Cookie.
        Puoi rivedere e modificare le tue scelte in qualsiasi momento.
      </div>
    </div>

    <div class="cookie-flags">
      <div
        class="cookie-flag"
        v-for="(cookieFlag, index) in cookieFlags"
        :key="cookieFlag.tile"
        :class="{ selected: index == selectedIdx }"
      >
        <div>
          <h3>{{ cookieFlag.title }}</h3>
          <div>{{ cookieFlag.text }}</div>
        </div>

        <div class="flex">
          <input
            :disabled="cookieFlag.locked"
            v-model="cookieFlag.enabled"
            type="checkbox"
          />
        </div>
      </div>
    </div>
    <div class="cookie-footer">
      <button
        :class="{
          selected:
            this.cookieFlags.length == selectedIdx && selectedButton === 0,
        }"
      >
        Visualizza privacy policy completa
      </button>
      <button
        :class="{
          selected:
            this.cookieFlags.length == selectedIdx && selectedButton === 1,
        }"
      >
        Salva e continua
      </button>
    </div>
  </div>
  <div v-else style="width: 100%; height: 100%">
    <CookiePolicyReader />
  </div>
</template>
<script>
import { eventBus, EVENTS } from '../services/EventBus';
import cookieService from '../services/CookieService';
import CookiePolicyReader from './CookiePolicyReader.vue';
const cookieFlags = [
  {
    id: '_n',
    title: 'Strettamente necessari',
    text: `
      Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.`,
    enabled: true,
    locked: true,
  },
  {
    id: '_s',
    title: 'Interazioni e funzionalità semplici',
    text: `
      Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi.`,
    enabled: true,
  },
  {
    id: '_m',
    title: 'Misurazione',
    text: `
      Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento con l'obiettivo di migliorare il nostro servizio.`,
    enabled: true,
  },
  // {
  //   title: 'Targeting e Pubblicità',
  //   text: `
  //     Questi strumenti di tracciamento ci consentono di fornirti contenuti commerciali personalizzati in base al tuo comportamento e di gestire, fornire e tracciare gli annunci pubblicitari.`,
  //   enabled: false
  // }
];
export default {
  name: 'CookiePolicyFull',
  components: {
    CookiePolicyReader,
  },
  data() {
    return {
      cookieFlags: cookieFlags,
      selectedIdx: cookieFlags.length,
      selectedButton: 1,
      fullPolicy: false,
    };
  },
  methods: {
    goUp() {
      if (this.selectedIdx > 0) {
        this.selectedIdx--;
      }
    },
    goDown() {
      if (this.selectedIdx < cookieFlags.length) {
        this.selectedIdx++;
      }
    },
    goLeft() {
      if (this.selectedIdx === this.cookieFlags.length) {
        if (this.selectedButton === 0) {
          this.selectedButton = 1;
        } else {
          this.selectedButton = 0;
        }
      }
    },
    goRight() {
      if (this.selectedIdx === this.cookieFlags.length) {
        if (this.selectedButton === 0) {
          this.selectedButton = 1;
        } else {
          this.selectedButton = 0;
        }
      }
    },
    toggleCookie() {
      if (this.selectedIdx === this.cookieFlags.length) {
        if (this.selectedButton === 1) {
          cookieService.setCookie('_rds_cookie_enabled', true, 365);
          cookieService.setCookie('cookieFlags', this.cookieFlags, 365);
          location.reload();
        } else {
          this.fullPolicy = true;
        }
      } else if (!this.cookieFlags[this.selectedIdx].locked) {
        console.log(this.selectedIdx);
        console.log(this.cookieFlags[this.selectedIdx]);
        this.cookieFlags[this.selectedIdx].enabled =
          !this.cookieFlags[this.selectedIdx].enabled;
      }
    },
  },
  mounted() {
    eventBus.on(EVENTS.KEY_DOWN, (key) => {
      if (key == 'ArrowUp' || key == window.KeyEvent.VK_UP) {
        this.goUp();
      } else if (key == 'ArrowDown' || key == window.KeyEvent.VK_DOWN) {
        this.goDown();
      } else if (key == 'Enter' || key == window.KeyEvent.VK_ENTER) {
        this.toggleCookie();
      } else if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
        console.log(this);
        this.goLeft();
      } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
        console.log(this);
        this.goRight();
      } else if (key == 'Backspace' || key == window.KeyEvent.VK_BACK) {
        if (this.fullPolicy) {
          this.fullPolicy = false;
        } else {
          eventBus.emit(EVENTS.HIDE_COOKIE_PAGE);
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.cookie-policy-full {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 80%;
  background-color: white;
  // background-color: #cccccc4d;
  // background: rgba(0, 0, 0, 0.71) none repeat scroll 0% 0%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: justify;
  padding: 20px 0;
  h3 {
    margin: 5px 0 8px 0;
  }
  .cookie-header {
    padding: 0 20px;
    flex: 1;
  }
  .cookie-flag {
    display: flex;
    padding: 20px;
    border-top: #ccc solid 1px;
    // padding: 3px;
    // &:last-child {
    //   border-bottom: black solid 1px;
    // }
    &.selected {
      background-color: #f3eeee;
    }
    .flex {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
    input {
      width: 64px;
      height: 32px;
      border-radius: 32px;
      -webkit-transition: background-position 0.4s ease,
        background-color 0.4s ease;
      -o-transition: background-position 0.4s ease, background-color 0.4s ease;
      transition: background-position 0.4s ease, background-color 0.4s ease;
      background-color: #ccc;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M9 0a9 9 0 1 1 0 18A9 9 0 0 1 9 0zM5.729 5.033a.5.5 0 0 0-.638.058l-.058.07a.5.5 0 0 0 .058.637l3.201 3.201-3.201 3.203a.5.5 0 0 0 .707.707l3.201-3.203 3.203 3.203.07.058a.5.5 0 0 0 .637-.058l.058-.07a.5.5 0 0 0-.058-.637L9.706 8.999l3.203-3.201a.5.5 0 0 0-.707-.707L8.999 8.292 5.798 5.091z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: top 4px left 4px;
      background-size: 24px 24px;
      appearance: inherit;
      &[disabled] {
        opacity: 0.35;
      }
    }
  }
  input:checked {
    //background-color: #2b3376;
    background-color: #184793;
    background-position: top 4px left 36px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M9 0a9 9 0 1 1 0 18A9 9 0 0 1 9 0zm4.646 5.646l-6.198 6.2-3.1-3a.5.5 0 1 0-.696.718l3.454 3.342a.5.5 0 0 0 .701-.006l6.547-6.546a.5.5 0 1 0-.708-.708z'/%3E%3C/svg%3E");
  }
  .cookie-footer {
    display: flex;
    justify-content: center;
  }
  button {
    -ms-flex-align: end;
    padding: 8px 32px;
    border: #2a337b solid 1px;
    border-radius: 64px;
    font-weight: 700;
    font-size: 100%;
    margin: 10px 20px 10px 10px;
    justify-content: end;
    background-color: white;
    // color: #ef3824;
    // color: #0073ce;
    color: #2a337b;
    &.selected {
      border: none;
      color: white;
      background-color: #ef3824;
    }
  }
}
</style>