<template>
  <div class="bottom-area">
    <transition name="slide-up">
      <div v-if="currentMenu" id="bar-down" class="bar-down">
        <div>
          <h3>Informativa</h3>
          <span>
            Noi e alcuni partner selezionati utilizziamo cookie o tecnologie
            simili come specificato nella cookie policy.
          </span>
          <span>
            Puoi acconsentire all’utilizzo di tali tecnologie selezionando
            'Accetta'.
          </span>
          <div class="buttons">
            <button :class="{ selected: selectedButton == 0 }">
              Scopri di più e personalizza
            </button>
            <button :class="{ selected: selectedButton == 1 }">Accetta</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { eventBus, EVENTS } from '../services/EventBus';
import cookieService from '../services/CookieService';
export default {
  name: 'CookiePolicyBar',
  data() {
    return {
      bannerText: ``,
      currentMenu: false,
      selectedButton: 1
    };
  },
  methods: {
    changeSelected() {
      if (this.selectedButton == 0) {
        this.selectedButton = 1;
      } else {
        this.selectedButton = 0;
      }
    }
  },
  mounted() {
    this.currentMenu = true;
    eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
        console.log(this);
        this.changeSelected();
      } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
        console.log(this);
        this.changeSelected();
      } else if (key == 'Enter' || key == window.KeyEvent.VK_ENTER) {
        if (this.selectedButton == 0) {
          eventBus.emit(EVENTS.SHOW_COOKIE_PAGE);
        } else {
          cookieService.setCookie('_rds_cookie_enabled', true, 365);
          location.reload();
        }
      }
    });
  }
};
</script>
<style lang="scss">
.bottom-area,
.news-preview,
.bar-down {
  width: 100%;
}
.bottom-area {
  height: 150px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  padding-top: 10px;
  z-index: 3;
}

.bar-down {
  & > div {
    width: 90%;
  }
  display: flex;
  background: rgba(0, 0, 0, 0.71) none repeat scroll 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -3px 7px 0px;
  padding-top: 10px;
  height: 150px;
  justify-content: center;
  color: white;
  h3 {
    margin: 8px 0;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  button {
    padding: 8px 32px;
    border: none;
    border-radius: 64px;
    font-weight: 700;
    font-size: 100%;
    margin: 10px 0 10px 10px;
    text-align: center;
    background-color: white;
    // color: #ef3824;
    // color: #0073ce;
    color: #2a337b;
    &.selected {
      color: white;
      background-color: #ef3824;
    }
  }
}
</style>