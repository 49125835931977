<template>
  <div class="artist-bio">
    <div class="artist-bio__title-container">
      <h2 class="artist-bio__title">
        {{ currentBio.artist }}
      </h2>
    </div>
    <div class="artist-bio__bio">
      {{ currentBio.bio }}
    </div>
  </div>
</template>
<script>
import store from '../Store';
import { eventBus, EVENTS } from '../services/EventBus';

export default {
  name: 'ArtistBio',
  data() {
    return {
      store: store,
      currentBio: store.currentBio
    };
  },
  mounted() {
    this.currentBio = this.store.currentBio;
    this.eventId = eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key === 'ArrowUp' || key === window.KeyEvent.VK_UP) {
        if (document.getElementsByClassName('artist-bio__bio').length) {
          document.getElementsByClassName('artist-bio__bio')[0].scrollTop -= 20;
        }
      } else if (key === 'ArrowDown' || key === window.KeyEvent.VK_DOWN) {
        if (document.getElementsByClassName('artist-bio__bio').length) {
          document.getElementsByClassName('artist-bio__bio')[0].scrollTop += 20;
        }
      }
    });
  },
  destroyed() {
    eventBus.off(EVENTS.KEY_DOWN, this.eventId);
  }
};
</script>
<style lang="scss" scoped>
.artist-bio {
  height: 100%;
  padding: 0 20px 10px 20px;
  width: 100%;

  &__title-container {
    margin: 20px 0;
    height: 82px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: #000;
    margin: 0;
  }

  &__bio {
    height: calc(100% - 150px);
    overflow-y: scroll;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    color: #000;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__bio::-webkit-scrollbar {
    display: none;
  }
}
</style>