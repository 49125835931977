<template>
  <div v-if="allData && allData.length" class="meteo-container">
    <div class="meteo-today">
      <div class="meteo-today__container">
        <div class="meteo-today__icon-container">
          <img class="meteo-today__icon" :src="todayMeteo.icon" />
        </div>
        <div class="meteo-today__description">
          <span class="meteo-today__city">
          {{ store.currentCity.name }}
          </span>
          <span class="meteo-today__temp-max">
            {{ rounded(todayMeteo.max) }}° C
          </span>
        </div>
      </div>
    </div>
    <div class="meteo-week">
      <div class="meteo-week__container" v-for="day in nextDaysMeteo" :key="day.idx">
        <span class="meteo-week__day">{{ day.giorno }}</span>
        <div class="meteo-week__icon-container">
          <img class="meteo-week__icon" :src="day.icon" />
        </div>
        <div class="meteo-week__temperatures">
          <span class="meteo-week__temp-max">{{ rounded(day.max) }}°C</span>
          <span class="meteo-week__temp-min">{{ rounded(day.min) }}°C</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../services/Http.service';
import CONFIG from '../config';
import store from '../Store';
export default {
  name: 'Meteo',
  data() {
    return {
      store: store,
      pageIdx: 0,
      allData: []
    };
  },
  computed: {
    todayMeteo() {
      let cityMeteo = this.allData[this.pageIdx];
      let icon = cityMeteo[1].icona;
      let max = cityMeteo[1].temperatura_max;
      return {
        icon: icon,
        max: max
      };
    },
    nextDaysMeteo() {
      let idx = 0;
      return Object.values(this.allData[this.pageIdx])
        .filter((c, i) => {
          return i > 0 && c.icona;
        })
        .map(cm => {
          return {
            id: idx++,
            giorno: cm.giorno.substring(0, 1),
            icon: cm.icona,
            max: cm.temperatura_max,
            min: cm.temperatura_min
          };
        });
    }
  },
  methods: {
    rounded(temp) {
      return Math.round(temp);
    },
    showMeteo(m) {
      this.allData = m;
      this.pageIdx = m.findIndex(
        i => i.meteo_location === store.currentCity.name
      );
    },
    hideMeteo() {}
  },
  mounted() {
    http.get(CONFIG.rdsMeteoURL).then(m => {
      this.showMeteo(m);
    });
    /*
        $.getJSON(CONFIG.rdsMeteoURL, function (m) {


        STATUS.status = 'meteo';
        STATUS.page = 0;
        STATUS.data = m.data;

        showPageMeteo(0, STATUS.data);
        // $('#rightblock').html(html);
        showRightBlock('green');

    });
    */
  }
};
</script>

<style lang="scss" scoped>
.meteo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95%;
  width: 100%;
  padding: 0 20px;

  .meteo-today {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon-container {
      text-align: center;
    }
    &__icon {
      width: 100px;
      height: 100px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      color: #000000;
    }
  }

  .meteo-week {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__day {
      width: 40px;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      color: #000000;
    }

    &__temperatures {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: right;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: #000000;
    }

    &__icon {
      width: 40px;
    }
  }
}
</style>