<template>
  <div v-if="!loading && widget" class="full-page">
    <div 
      v-if="store.fullpage" 
      :class="'full-page__container'">
      <!-- <div
        :class="[`full-page__bg-over--${store.mode}`]"
        class="full-page__bg-over">
        <img class="full-page__image-bg" :src="selected.image" />
      </div> -->
      <div
        :class="[`full-page__content--${store.mode}`]"
        class="full-page__content">
        <div
          :class="[`full-page__header--${store.mode}`]"
          class="full-page__header">
          <div 
            :class="[`full-page__logo-container--${store.mode}`]"
            class="full-page__logo-container">
            <img
              class="full-page__logo"
              src="https://media.rds.it/images/hbbtv/Logo.svg"
            />
          </div>
          <div 
            :class="[`full-page__header-title--${store.mode}`]"
            class="full-page__header-title">
            <div 
              v-if="widget === 'showcase'" 
              :class="[`full-page__title--${store.mode}`]"
              class="full-page__title">
              {{ selected.titolo }}
            </div>
            <div 
              v-if="widget === 'archivio'" 
              :class="[`full-page__title--${store.mode}`]"
              class="full-page__title">
              {{ selected.descrizione }}
            </div>
            <div 
              v-if="widget === 'topChart'" 
              :class="[`full-page__title--${store.mode}`]"
              class="full-page__title">
              {{ selected.titolo }}
            </div>
            <div
              v-if="widget === 'showcase'"
              :class="[`full-page__subtitle--${store.mode}`]"
              class="full-page__subtitle">
              {{ selected.categoria }}
            </div>
            <div
              v-if="widget === 'archivio'"
              :class="[`full-page__subtitle--${store.mode}`]"
              class="full-page__subtitle">
              {{ selected.titolo }}
            </div>
            <div
              v-if="widget === 'topChart'"
              :class="[`full-page__subtitle--${store.mode}`]"
              class="full-page__subtitle">
              {{ selected.distribuzione }}
            </div>
          </div>
          <div 
            :class="[`full-page__date-time--${store.mode}`]"
            class="full-page__date-time">
            <span 
              :class="[`full-page__time--${store.mode}`]"
              class="full-page__time">
              {{ currentTime }}
            </span>
            <span 
              :class="[`full-page__date--${store.mode}`]"
              class="full-page__date">
              {{ currentDate }} 
            </span>
          </div>
        </div>
        <div 
          :class="[`full-page__video-list--${store.mode}`]"
          class="full-page__video-list">
          <div 
            ref="listVideo"
            :class="{'full-page__list-video--transform': !videoFullscreen}" 
            class="full-page__list-video">
            <div
              :data-id="previousIndex"
              :class="[`full-page__block--${store.mode}`]"
              class="full-page__block full-page__block--first"
            >
              <div class="full-page__preview-image">
                <RdsImg :src="videoElements[previousIndex].image" />
                <span v-if="widget === 'showcase'" class="full-page__video-label">
                  {{ videoElements[previousIndex].titolo }}
                </span>
                <span v-else-if="widget === 'archivio'" class="full-page__video-label">
                  {{ videoElements[previousIndex].descrizione }}
                </span>
                <span v-else-if="widget === 'topChart'" class="full-page__video-label">
                  {{ videoElements[previousIndex].titolo }}
                </span>
              </div>
            </div>
            <div
              v-for="(element, index) in videoElements"
              :key="index"
              :class="[
                { 'full-page__block--selected': selectedIndex === element.index },
                `full-page__block--${store.mode}`
              ]"
              class="full-page__block">
              <div 
                v-if="selectedIndex === element.index" 
                :class="{'full-page__video--fullscreen': videoFullscreen}"
                class="full-page__video">
                <RdsVideo
                  v-if="video"
                  :src="video.videoclipUrl"
                  :type="video.videoType"
                  :autoplay="false"
                  :fullscreen="video.fullscreen"
                  :posterImage="selected.image"
                />
              </div>
              <div
                v-if="selectedIndex !== element.index"
                class="full-page__preview-image">
                <RdsImg :src="element.image" />
                <span v-if="widget === 'showcase'" class="full-page__video-label">
                  {{ element.titolo }}
                </span>
                <span v-else-if="widget === 'archivio'" class="full-page__video-label">
                  {{ element.descrizione }}
                </span>
                <span v-else-if="widget === 'topChart'" class="full-page__video-label">
                  {{ element.titolo }}
                </span>
              </div>
            </div>
            <div
              :data-id="nextIndex"
              :class="[`full-page__block--${store.mode}`]"
              class="full-page__block full-page__block--last"
            >
              <div class="full-page__preview-image">
                <RdsImg :src="videoElements[nextIndex].image" />
                <span v-if="widget === 'showcase'" class="full-page__video-label">
                  {{ videoElements[nextIndex].titolo }}
                </span>
                <span v-else-if="widget === 'archivio'" class="full-page__video-label">
                  {{ videoElements[nextIndex].descrizione }}
                </span>
                <span v-else-if="widget === 'topChart'" class="full-page__video-label">
                  {{ videoElements[nextIndex].titolo }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[`full-page__footer--${store.mode}`]"
          class="full-page__footer">
          <div 
          v-if="selectedCity"
            :class="[`full-page__footer-meteo--${store.mode}`]"
            class="full-page__footer-meteo">
            <span 
              :class="[`full-page__footer-meteo-city--${store.mode}`]"
              class="full-page__footer-meteo-city">
              {{ selectedCity.location }}
            </span>
            <div class="full-page__footer-meteo-temperature">
              <img class="full-page__footer-meteo-icon" :src="selectedCity.image" />
              <span 
                :class="[`full-page__footer-meteo-maxtemp--${store.mode}`]"
                class="full-page__footer-meteo-maxtemp">
                {{ selectedCity.Temperature }}
              </span>
            </div>
          </div>
          <div 
            :class="[`full-page__footer-buttons--${store.mode}`]"
            class="full-page__footer-buttons">
            <div 
            :class="[`arrow-left--${store.mode}`]"
            class="arrow-left" />
            <div class="full-page__footer-buttons-text">
              <span class="full-page__footer-buttons-ok">OK</span>
              <span 
                :class="[`full-page__footer-buttons-play--${store.mode}`]" 
                class="full-page__footer-buttons-play">
                PLAY
              </span>
            </div>
            <div 
            :class="[`arrow-right--${store.mode}`]"
            class="arrow-right" />
          </div>
          <div 
            :class="[`full-page__footer-frequencies--${store.mode}`]"
            class="full-page__footer-frequencies">
            <span 
              :class="[`full-page__footer-frequencies-city--${store.mode}`]"
              class="full-page__footer-frequencies-city">
              {{ selectedFrequency.luogo }}
            </span>
            <span 
              :class="[
                `full-page__footer-frequencies-frequency--${store.mode}`
              ]"
              class="full-page__footer-frequencies-frequency">
              {{ selectedFrequency.frequenza }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="is-loading" v-else-if="loading">
    <img src="../assets/img/loading.gif" />
  </div>
</template>

<script>
import { eventBus, EVENTS } from '../services/EventBus';
import { format } from 'date-fns';
import http from '../services/Http.service';
import config from '../config';
import store from '../Store';
import RdsVideo from './RdsVideo.new.vue';
import RdsImg from './RdsImg';

let hideEventId;
let keyEventId;

function handleKeyDown(key) {
  if (this.isVisible) {
    if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
      this.goPrevious();
    } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
      this.goNext();
    } else if (
      !this.videoFullscreen &&
      (key === 'Enter' || key === window.KeyEvent.VK_ENTER)
    ) {
      this.showVideo();
    }
  }
}

export default {
  name: 'FullPage',
  props: {
    isVisible: { type: Boolean, default: false }
  },
  components: {
    RdsVideo,
    RdsImg
  },
  data: () => {
    return {
      widget: null,
      config: config,
      store: store,
      videoarray: [],
      label: '',
      selectedIndex: 0,
      loading: true,
      videoFullscreen: false,
      currentTime: format(new Date(), 'HH:mm'),
      currentDate: format(new Date(), 'dd MMM yyyy'),
      selectedCityIndex: 0,
      selectedCity: null,
      selectedFrequency: null,
      changeCity: false
    };
  },
  computed: {
    selected() {
      return this.videoarray.length
        ? this.videoarray[this.selectedIndex]
        : null;
    },
    video() {
      if (!this.selected) return null;
      const fileExt = this.selected.video_url.split('.').pop();
      let videoType = 'application/dash+xml';
      if (fileExt == 'mp4') videoType = 'video/mp4';
      return {
        videoclipUrl: this.selected.video_url,
        videoType: videoType,
        fullscreen: this.videoFullscreen
      };
    },
    videoElements() {
      const elements = this.videoarray.map((item, index) => {
        item.index = index;
        return item;
      });
      return elements;
    },
    previousIndex() {
      if (this.selectedIndex === 0) {
        return this.videoElements.length - 1;
      }
      return this.selectedIndex - 1;
    },
    nextIndex() {
      if (this.selectedIndex === this.videoElements.length - 1) {
        return 0;
      }
      return this.selectedIndex + 1;
    },
    cities() {
      return this.config.cities;
    }
  },
  async mounted() {
    await this.meteoConfig();
    await this.frequenciesConfig();
    // hide video in fullscreen
    eventBus.on(EVENTS.HIDE_VIDEO, () => {
      if (store.videoFullscreen) {
        keyEventId = eventBus.on(
          EVENTS.KEY_DOWN,
          handleKeyDown.bind(this),
          'fullpageKeyDown'
        );
      }
      store.videoFullscreen = false;
      this.videoFullscreen = false;
    });

    // show video in fullpage
    const onshowFullPage = e => {
      this.loading = true;
      let videoArray = [];
      if (Array.isArray(e.widget)) {
        var risultati = this.mapArray(store.kitchen, 'Kitchen sound').concat(
          this.mapArray(store.showcase, 'Showcase')
        );
        var fiter = risultati.filter(function(value) {
          return (
            value['titolo'].toLowerCase().indexOf(e.widget[1].toLowerCase()) >=
              0 ||
            value['descrizione']
              .toLowerCase()
              .indexOf(e.widget[1].toLowerCase()) >= 0
          );
        });
        if (fiter.length == 0) {
          store.search = false;
          this.loading = false;
          return;
        } else {
          videoArray = fiter;
          this.label = 'Risultati ' + "'" + e.widget[1] + "'";
        }
      }
      store.search = false;
      store.fullpage = true;
      this.widget = e.widget;
      if (store.showcase.length) store.loadShowcase();
      if (store.kitchen.length) store.loadKitchenSound();
      if (store.topChart.length) store.loadTopChart();
      if (this.widget === 'showcase') {
        this.label = 'Showcase recenti';
        videoArray = this.mapArray(store.showcase, 'Showcase');
      } else if (this.widget === 'archivio') {
        let kitchenStore;
        if (this.store.kitchen.length > 10) {
          kitchenStore = this.store.kitchen
            .sort(function(a, b) {
              return new Date(b.publishDate) - new Date(a.publishDate);
            })
            .slice(0, 10);
        } else {
          kitchenStore = this.store.kitchen;
        }
        videoArray = this.mapArray(kitchenStore, 'Kitchen sound');
        this.label = 'Tutte le ricette';
      } else if (this.widget === 'topChart') {
        videoArray = this.mapArray(store.topChart, 'Top Chart');
        this.label = 'Tutti i contenuti';
      } else {
        if (!e.widget[1] != '') {
          store.search = false;
        }
      }
      this.videoarray = videoArray;
      this.selectedIndex = 0;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    };

    // show full page
    eventBus.on(EVENTS.SHOW_FULL_PAGE, onshowFullPage.bind(this));

    const that = this;
    // update date time every second
    setInterval(() => {
      that.currentTime = format(new Date(), 'HH:mm');
      that.currentDate = format(new Date(), 'dd MMM yyyy');
    }, 1000);

    setInterval(async () => {
      await this.meteoConfig();
      await this.frequenciesConfig();
    }, 8000);
  },
  created() {
    hideEventId = eventBus.on(EVENTS.HIDE_FULL_PAGE, () => {
      if (store.fullpage) {
        window.location.reload();
      }
      store.fullpage = false;
    });
    keyEventId = eventBus.on(
      EVENTS.KEY_DOWN,
      handleKeyDown.bind(this),
      'fullpageKeyDown'
    );
  },
  destroyed() {
    if (keyEventId) {
      console.log('eventBus.off EVENTS.KEY_DOWN');
      eventBus.off(EVENTS.KEY_DOWN, keyEventId);
      keyEventId = null;
    }
    eventBus.off(EVENTS.HIDE_SEARCH, hideEventId);
    const vm = this;
    document.removeEventListener('keydown', handleKeyDown.bind(vm), false);
  },
  methods: {
    goPrevious() {
      if (!store.search) {
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        } else {
          this.selectedIndex = this.videoarray.length - 1;
        }
        if (this.selectedIndex === this.videoarray.length - 1) {
          this.$nextTick(() => {
            const blockWidth = document
              .getElementsByClassName('full-page__block')[0]
              .getBoundingClientRect().width;
            const totallyBlocks = blockWidth * this.videoarray.length;
            this.$refs.listVideo.scrollLeft += totallyBlocks - 20;
          });
        } else {
          this.$nextTick(() => {
            const blockWidth = document
              .getElementsByClassName('full-page__block')[0]
              .getBoundingClientRect().width;
            this.$refs.listVideo.scrollLeft -= blockWidth + 40;
          });
        }
      }
    },
    goNext() {
      if (!store.search) {
        if (this.selectedIndex < this.videoarray.length - 1) {
          this.selectedIndex++;
        } else {
          this.selectedIndex = 0;
        }
        if (this.previousIndex === this.videoarray.length - 1) {
          this.$nextTick(() => {
            const blockWidth = document
              .getElementsByClassName('full-page__block')[0]
              .getBoundingClientRect().width;
            const totallyBlocks = blockWidth * this.videoarray.length;
            this.$refs.listVideo.scrollLeft -= totallyBlocks - 20;
          });
        } else {
          this.$nextTick(() => {
            const blockWidth = document
              .getElementsByClassName('full-page__block')[0]
              .getBoundingClientRect().width;
            this.$refs.listVideo.scrollLeft += blockWidth + 40;
          });
        }
      }
    },
    showVideo() {
      if (!store.search) {
        if (keyEventId) {
          eventBus.off(EVENTS.KEY_DOWN, keyEventId);
          console.log('eventBus.off EVENTS.KEY_DOWN');
          keyEventId = null;
        }
        eventBus.emit(EVENTS.SHOW_FULL_PAGE_VIDEO, this);
        this.videoFullscreen = true;
        store.videoFullscreen = true;
      }
    },
    mapArray(array, category) {
      const isKS = category === 'Kitchen sound';
      return array.map(i => {
        let data = new Date(i.publish_date) || new Date(i.date);
        return {
          image: i.img_src || i.image,
          descrizione: isKS
            ? i.label.replace('Alessandro Borghese Kitchen Sound - ', '')
            : '',
          titolo: isKS
            ? 'Alessandro Borghese'
            : category === 'Top Chart'
            ? i.title.replace('RDS TOPCHART - ', '')
            : i.label,
          video_url: i.videoUrl || i.video_url,
          categoria: category,
          distribuzione: i.distribuzione,
          publishDate: i.publish_date,
          data: data.toLocaleDateString('it', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        };
      });
    },
    async meteoConfig() {
      this.changeCity = true;
      await http.get(config.dailyMeteo).then(value => {
        if (value && value.length) {
          this.selectedCityIndex = Math.floor(
            Math.random() * this.cities.length
          );
          this.selectedCity = value[this.selectedCityIndex];
        }
      });
      this.changeCity = false;
    },

    async frequenciesConfig() {
      await http.get(config.frequencies).then(value => {
        if (value && value.length && this.selectedCity) {
          this.selectedFrequency = value.find(
            item => item.luogo === this.selectedCity.location
          );
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.is-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.full-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }

  .full-page__container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    font-family: 'Gilroy';

    .full-page__bg-over {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;

      &--dark {
        background-color: #022f84;
      }
      &--light {
        background-color: #fff;
      }

      .full-page__image-bg {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }

    .full-page__content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: 1;

      &--dark {
        background-color: #022f84;
      }
      &--light {
        background-color: #fff;
      }

      .full-page__header {
        display: flex;
        background-color: #fff;
        height: 150px;

        &--dark {
          background-color: #022f84;
        }

        .full-page__logo-container {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #808080;
          border-bottom: 1px solid #808080;

          &--dark {
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
          }
        }

        .full-page__header-title {
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #808080;
          padding: 10px 20px;

          &--dark {
            color: #fff;
            border-bottom: 1px solid #fff;
          }

          .full-page__title {
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;
            color: rgb(51, 51, 51);
            text-transform: capitalize;
            text-align: center;
            color: #000000;

            &--dark {
              color: #fff;
            }
          }

          .full-page__subtitle {
            font-size: 21.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 31.5px;
            color: #aaaaaa;
            text-align: center;

            &--dark {
              color: #fff;
            }
          }
        }

        .full-page__date-time {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #808080;
          border-bottom: 1px solid #808080;

          &--dark {
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
          }

          .full-page__time {
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;
            color: #000000;

            &--dark {
              color: #fff;
            }
          }
          .full-page__date {
            font-size: 21.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 31.5px;
            color: #808080;

            &--dark {
              color: #fff;
            }
          }
        }
      }

      .full-page__video-list {
        position: relative;
        z-index: 3;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100% - 300px);

        &--dark {
          background-color: #022f84;
          color: white;
        }

        .full-page__list-video {
          display: flex;
          align-items: center;
          overflow-x: hidden;

          &--transform {
            transform: translate3d(0, 0, 0);
          }

          .full-page__block {
            width: 340px;
            height: 240px;
            margin-right: 40px;
            opacity: 0.5;

            &--selected {
              width: 500px;
              height: 300px;
              flex: none;
              opacity: 1;
            }

            &--first {
              margin-left: 10px;
              opacity: 0.5;
            }

            &--last {
              opacity: 0.5;
            }

            .full-page__video {
              width: 500px;
              height: 300px;
            }

            .full-page__preview-image {
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              overflow: hidden;
              width: 340px;
              height: 240px;

              img {
                width: 340px;
                height: 168px;
                object-fit: cover;
              }

              .full-page__video-label {
                font-weight: bold;
                font-size: 24px;
                line-height: 34px;
                margin-top: 10px;
                height: 68px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      .full-page__footer {
        display: flex;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;

        &--dark {
          background-color: #022f84;
          color: white;
        }

        .full-page__footer-meteo {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #808080;
          border-top: 1px solid #808080;

          &--dark {
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
          }

          .full-page__footer-meteo-city {
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;
            color: #333;
            white-space: nowrap;
            text-align: center;
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;

            &--dark {
              color: #fff;
            }
          }

          .full-page__footer-meteo-temperature {
            display: flex;
            align-items: center;

            .full-page__footer-meteo-icon {
              width: 40px;
              margin-right: 20px;
            }
            .full-page__footer-meteo-maxtemp {
              font-size: 31.5px;
              letter-spacing: 1px;
              font-weight: 300;
              line-height: 41.5px;
              color: #aaaaaa;

              &--dark {
                color: white;
              }
            }
          }
        }

        .full-page__footer-buttons {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #808080;

          &--dark {
            border-top: 1px solid #fff;
          }

          .arrow-right {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 20px solid #808080;

            &--dark {
              border-left: 20px solid #fff;
            }
          }

          .arrow-left {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid #808080;

            &--dark {
              border-right: 20px solid #fff;
            }
          }

          .full-page__footer-buttons-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 60px;
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;

            // .full-page__footer-buttons-ok {}
            .full-page__footer-buttons-play {
              color: #808080;

              &--dark {
                color: #fff;
              }
            }
          }
        }

        .full-page__footer-frequencies {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #808080;
          border-top: 1px solid #808080;

          &--dark {
            border-left: 1px solid #fff;
            border-top: 1px solid #fff;
          }

          .full-page__footer-frequencies-city {
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;
            color: #333;
            white-space: nowrap;
            text-align: center;
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;

            &--dark {
              color: #fff;
            }
          }

          .full-page__footer-frequencies-frequency {
            font-size: 31.5px;
            letter-spacing: 1px;
            font-weight: 300;
            line-height: 41.5px;
            color: #aaaaaa;

            &--dark {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
