import { eventBus, EVENTS } from './EventBus';

function getApp(id) {
  var node = document.getElementById(id);
  if (node.getOwnerApplication) {
    return node.getOwnerApplication(document);
  }

  return {
    show: function () { },
    privateData: {
      keyset: {
        value: undefined,
        setValue: function (i) {
          this.value = i;
        }
      }
    }
  };
}

let app = getApp('appmgr');
let ESATTO = 0;

function start() {
  try {
    setKeyset(0x1 + 0x2 + 0x4 + 0x8 + 0x10);
  } catch (error) {
    console.warn(error);
  }
  
  try {
    app.show();
    try {
      app.activate(); // this is for HbbTV 0.5 backwards-compliance. It will throw an ignored exception on HbbTV 1.x devices, which is fine
    } catch (e) { console.log(e); }
  } catch (e) {
    // ignore
    console.log(e);
  }
  //R V G R R G
  document.addEventListener('keydown', function (e) {
    switch (e.code || e.keyCode) {
      case window.KeyEvent.VK_RED:
        switch (ESATTO) {
          case 0:
          case 3:
          case 4:
            ESATTO++;
            break;
          default:
            ESATTO = 0;
            break;
        }
        break;
      case window.KeyEvent.VK_GREEN:
        switch (ESATTO) {
          case 1:
            ESATTO++;
            break;
          default:
            ESATTO = 0;
            break;
        }
        break;
      case window.KeyEvent.VK_YELLOW:
        switch (ESATTO) {
          case 2:
            ESATTO++;
            break;
          case 5:
            window.location.href = 'http://hbbtv-dev.rds.radio';
            break;
          default:
            ESATTO = 0;
            break;
        }
        break;
      default:
        ESATTO = 0;
        break;
    }
    console.log('ESATTO: ', ESATTO);
    eventBus.emit(EVENTS.KEY_DOWN, e.code || e.keyCode);
  }, false);
  // openWsConnection();

}

function setKeyset(mask) {
  // var elemcfg;
  // const userAgent = navigator.userAgent;
  // // for HbbTV 0.5:
  // if (userAgent.indexOf('Web0S') == -1 && userAgent.indexOf('HbbTV/1.5.1') == -1) {
  //   try {
  //     elemcfg = document.getElementById('oipfcfg');
  //     elemcfg.keyset.value = mask;
  //   } catch (e) {
  //     // ignore
  //   }
  //   try {
  //     elemcfg = document.getElementById('oipfcfg');
  //     elemcfg.keyset.setValue(mask);
  //   } catch (e) {
  //     // ignore
  //   }
  // }


  // // for HbbTV 1.0:
  try {
    app.privateData.keyset.setValue(mask);
  } catch (e) {
    // ignore
  }
}
class HbbtvService {
  start() {
    start();
    try {
      const broadcast = document.getElementById('broadcast');
      broadcast.bindToCurrentChannel();
      broadcast.setFullScreen(false);
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf('Web0S') == -1 && userAgent.indexOf('HbbTV/1.5.1') == -1) {
        broadcast.setChannel(broadcast.currentChannel);
      }

      // if (broadcast.currentChannel.cid!='ccid://265.0') {
      //   broadcast.setChannel(broadcast.currentChannel);
      // }
      // broadcast.onPlayStateChange = (state, err) => {
      //   if (err) {
      //     broadcast.setChannel(broadcast.currentChannel);
      //   }
      // }
    } catch (error) {
      console.warn('error', error);
    }

  }
}
const hbbtv = new HbbtvService();
export default hbbtv;