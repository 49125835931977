<template>
  <div class="hbbtv-instagram">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <div class="swiper-pagination" slot="pagination"></div>
      <swiper-slide v-for="content in instagramContents" :key="content.id" class="swiper-slide">
        <div class="swiper-container">
          <div class="instagram-header">
            <div class="instagram-logo-container">
              <img class="instagram-logo" src="../assets/img/instagram_logo.png" />
            </div>
            <span class="instagram-name">
              {{ content.instagramName }}
            </span>
          </div>
          <img class="main-img" :src="content.mediaUrl" />
          <div class="hbbtv-instagram-content">
            <div class="instagram-post-date">
              {{ content.postDate }}
            </div>
            &nbsp;
            <span 
              v-html="content.caption" 
              class="instagram-post-caption" 
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import store from '../Store';
import { replaceEmojis } from '../services/EmojiUtil';
import { directive } from 'vue-awesome-swiper';
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import { eventBus, EVENTS } from '../services/EventBus';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: 'InstagramViewer',
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      store: store,
      instagramContentsCopy: store.instagramContents,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet'
        }
      }
    };
  },
  computed: {
    instagramContents() {
      let idx = 0;
      const contents = this.instagramContentsCopy
        .filter(ic => !!ic.local_image)
        .map(instagramContent => {
          let caption = JSON.parse(instagramContent.caption);
          const postDate = new Date(instagramContent.created_at)
            .toLocaleDateString('it', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
            .replace(/\//g, '.');
          try {
            caption = JSON.parse(instagramContent.caption);
            caption = replaceEmojis(caption);
          } catch (error) {
            console.warn(error);
          }
          return {
            id: idx++,
            mediaUrl: instagramContent.local_image,
            caption: caption,
            instagramName: instagramContent.instagram_name,
            postDate: postDate
          };
        });
      return contents;
    },
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null;
    }
  },
  mounted() {
    if (this.store.instagramContents.length) {
      if (this.store.instagramContents.length > 10) {
        this.instagramContentsCopy = this.store.instagramContents
          .sort(function(a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .slice(0, 10);
      } else {
        this.instagramContentsCopy = this.store.instagramContents;
      }
    }
    this.eventId = eventBus.on(EVENTS.KEY_DOWN, key => {
      if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
        this.swiper.slidePrev(1000);
      } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
        this.swiper.slideNext(1000);
      } else if (key === 'ArrowUp' || key === window.KeyEvent.VK_UP) {
        document.getElementsByClassName(
          'swiper-slide-active'
        )[0].scrollTop -= 20;
      } else if (key === 'ArrowDown' || key === window.KeyEvent.VK_DOWN) {
        document.getElementsByClassName(
          'swiper-slide-active'
        )[0].scrollTop += 20;
      }
    });
  },
  destroyed() {
    eventBus.off(EVENTS.KEY_DOWN, this.eventId);
  }
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper-bundle.css';

.hbbtv-instagram {
  height: 100%;
  padding-left: 20px;
  width: 100%;

  .swiper {
    height: 100%;
    width: 100%;

    .swiper-wrapper::-webkit-scrollbar {
      display: none;
    }
    .swiper-wrapper {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .swiper-slide::-webkit-scrollbar {
      display: none;
    }
    .swiper-slide {
      height: calc(100% - 70px);
      margin-top: 60px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .swiper-container {
        padding-right: 40px;
        .instagram-header {
          display: flex;
          align-items: center;

          .instagram-logo-container {
            margin-right: 14px;

            .instagram-logo {
              width: 45px;
              height: 45px;
            }
          }

          .instagram-name {
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #000;
          }
        }

        .main-img {
          max-width: 90%;
        }

        .hbbtv-instagram-content {
          width: 80%;
          display: flex;
          flex-direction: column;
          font-size: 25px;
          line-height: 37px;
          color: #000;

          .instagram-post-date {
            font-weight: bold;
          }

          .instagram-post-date /deep/ tcxspan {
            text-decoration: none;
          }

          .instagram-post-caption {
            font-weight: 500;
            word-break: break-all;
          }
        }
      }
    }

    .swiper-pagination {
      top: 20px;
      width: 90%;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      --swiper-pagination-bullet-height: 12px;
      --swiper-pagination-bullet-width: 12px;
      --swiper-pagination-color: blue;
      --swiper-pagination-bullet-inactive-color: #808080;
      --swiper-pagination-bullet-inactive-opacity: 1;
    }
  }
}
</style>