<template>
  <transition name="slide-right">
    <div id="rightblock" v-if="store.widget" class="right-block">
      <div class="right-block__container" :class="`right-block__container--${color}`">
        <!-- <svg height="0" width="0">
          <defs>
            <clipPath id="svgPath">
              <path d="M18.2005 680C70.2954 412.622 43.9085 171.426 0 0H909.54V680H18.2005Z" fill="white" stroke="#000000" stroke-width="1.5794" stroke-miterlimit="10" />
            </clipPath>
          </defs>
        </svg> -->

        <div class="slide-bg">
          <ArtistBio v-if="store.widget === 'bio'" />
          <Lyrics v-if="store.widget === 'lyrics'" />
          <Meteo v-if="store.widget === 'meteo'" />
          <InstagramViewer v-if="store.widget === 'instagram'" />
          <News 
            v-if="store.widget === 'news'" 
            :initialArticle="param" 
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { eventBus, EVENTS } from '../services/EventBus';
import ArtistBio from './ArtistBio';
import InstagramViewer from './InstagramViewer';
import Lyrics from './Lyrics';
import Meteo from './Meteo';
import News from './News';
import store from './../Store';
let eventId;
let hideEventId;
export default {
  name: 'RightBlock',
  components: {
    ArtistBio,
    Lyrics,
    Meteo,
    News,
    InstagramViewer
  },
  data() {
    return {
      color: 'green',
      // widget: null,
      param: null,
      store: store
    };
  },
  created() {
    const vm = this;
    hideEventId = eventBus.on(EVENTS.HIDE_RIGHT_BLOK, () => {
      store.widget = null;
    });
    eventId = eventBus.on(
      EVENTS.SHOW_RIGHT_BLOCK,
      ({ widget, color, param }) => {
        const store = vm.store;
        if (store.widget == widget) {
          store.widget = null;
          return;
        }
        if (store.widget) {
          store.widget = null;
          setTimeout(() => {
            vm.color = color;
            store.widget = widget;
            vm.param = param;
          }, 300);
        } else {
          store.widget = widget;
          vm.color = color;
          vm.param = param;
        }
      }
    );
  },
  destroyed() {
    eventBus.off(EVENTS.SHOW_RIGHT_BLOCK, eventId);
    eventBus.off(EVENTS.HIDE_RIGHT_BLOK, hideEventId);
  }
};
</script>

<style lang="scss" scoped>
.right-block {
  position: fixed;
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
  margin-top: 10.8%;
  margin-bottom: 20%;
  display: flex;
  background-color: transparent;
  transition: transform 0.4s;

  &__container {
    height: 59.4%;
    display: flex;
    width: 34%;
    // width: 425px;
    border: 3px solid transparent;

    &--red {
      border-color: rgb(199, 43, 62);
    }
    &--green {
      border-color: green;
    }
    &--yellow {
      border-color: yellow;
    }
    &--blue {
      border-color: blue;
    }
  }

  .slide-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    clip-path: url('#svgPath');
    background-color: #fff;
  }
}
</style>