import { eventBus, EVENTS } from '../services/EventBus';
import store from '../Store';
// import http from '../services/Http.service';
// import CONFIG from '../config';

// function getLyrics(songid) {
//   http.get(CONFIG.rdsNewLyricsURL, { sid: songid }).then((r) => {
//     if (r.found) {
//       store.currentLyrics = r.lyrics;
//     } else {
//       store.currentLyrics = null;
//     }
//   });
// }
// function getInstagram(songId) {
//   http.get(CONFIG.newInstagramURL, { songId: songId }).then((r) => {
//     console.log(store);
//     if (r.instagramContents) {
//       store.instagramContents = r.instagramContents;
//     } else {
//       store.instagramContents = [];
//     }
//   });
// }

function onmessage(message) {
  store.currentContentType = message.type;
  switch (message.type) {
    case 'song':
      // getLyrics(message.songid);
      // getInstagram(message.songid);
      store.currentSong = {
        artist: message.artist,
        title: message.title
      };
      if (message.bio && message.bio) {
        store.currentBio = {
          artist: message.artist,
          bio: message.bio
        };
      }
      if (message.hbbtv && message.hbbtv.lyric) {
        try {
          store.currentLyrics = message.hbbtv.lyric[0].testo;
        } catch (error) {
          store.currentLyrics = null;
        }

      }
      if (message.hbbtv && message.hbbtv.instagramContents && message.hbbtv.instagramContents.length) {
        store.instagramContents = message.hbbtv.instagramContents;
      } else {
        store.instagramContents = [];
      }
      // if (message.videoClipMixato) {
      //   store.videoclipUrl = message.videoClipMixato;
      //   store.isMixato = true;
      // } else 
      if (message.videoclip) {
        store.videoclipUrl = message.videoclip;
        store.isMixato = false;
      } else {
        // store.videoclipUrl = 'https://video.rds.it/web/da368ce7589d84a76b96d6f82629d0e5b195b6af7472ceec91bab90784d2e42b/720p/da368ce7589d84a76b96d6f82629d0e5b195b6af7472ceec91bab90784d2e42b.mp4';

        store.videoclipUrl = null;
      }
      break;
    default:
      store.instagramContents = [];
      store.currentBio = null;
      store.currentSong = null;
      store.currentLyrics = null;
      store.videoclipUrl = null;
    // store.videoclipUrl = 'https://video.rds.it/web/da368ce7589d84a76b96d6f82629d0e5b195b6af7472ceec91bab90784d2e42b/720p/da368ce7589d84a76b96d6f82629d0e5b195b6af7472ceec91bab90784d2e42b.mp4';
  }
}
eventBus.on(EVENTS.NEW_MESSAGE, onmessage);
