function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
const DEBUG_EVENTS = true;
const EVENTS = {
  KEY_DOWN: 'KEY_DOWN',
  OPEN_MENU: 'OPEN_MENU',
  LIKE: 'LIKE',
  SHOW_VIDEO: 'SHOW_VIDEO',
  HIDE_VIDEO: 'HIDE_VIDEO',
  SHOW_RIGHT_BLOCK: 'SHOW_RIGHT_BLOCK',
  NEW_MESSAGE: 'NEW_MESSAGE',
  SET_CITY: 'SET_CITY',
  CHOICHE_CITY: 'CHOICHE_CITY',
  HIDE_MENU: 'HIDE_MENU',
  HIDE_RIGHT_BLOK: 'HIDE_RIGHT_BLOK',
  SHOW_FULL_PAGE: 'SHOW_FULL_PAGE',
  HIDE_FULL_PAGE: 'HIDE_FULL_PAGE',
  SHOW_SEARCH: 'SHOW_SEARCH',
  HIDE_SEARCH: 'HIDE_SEARCH',
  SHOW_FULL_PAGE_VIDEO_PREVIEW: 'SHOW_FULL_PAGE_VIDEO_PREVIEW',
  SHOW_FULL_PAGE_VIDEO: 'SHOW_FULL_PAGE_VIDEO',
  SHOW_COOKIE_PAGE: 'SHOW_COOKIE_PAGE',
  HIDE_COOKIE_PAGE: 'HIDE_COOKIE_PAGE'

};
class EventBus {
  constructor() {
    this.subscribers = {};
  }

  on(eventName, fn, label) {
    const id = uuidv4();
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = [];
    }
    const event = { id: id, fn: fn };
    if (label) {
      event.label = label;
    }
    this.subscribers[eventName].push(event);

    return id;
  }
  off(eventName, id) {
    const idx = this.subscribers[eventName].findIndex((s) => s.id === id);
    this.subscribers[eventName].splice(idx, 1);
    console.log(this.subscribers[eventName]);
  }

  emit(eventName, data) {
    if (DEBUG_EVENTS) {
      console.log(`emit event ${eventName}`, data);
    }
    if (!this.subscribers[eventName]) {
      return;
    }
    for (let i = 0; i < this.subscribers[eventName].length; i++) {
      const objFn = this.subscribers[eventName][i];
      const fn = objFn.fn;
      fn.apply(this, [data]);
    }
  }
}
const eventBus = new EventBus();
window.eventBus = eventBus;
export { eventBus, EVENTS };
