import Vue from 'vue';
import hbbtv from './services/Hbbtv.service';
import ws from './services/WebSocket.service';
import './services/MessageProcessor';
import App from './App.vue';
import './services/Analytics';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import cookieService from './services/CookieService';
// import mainVideo from './services/MainVideoWrapper';

if (window.location.host === 'hbbtv-dev.rds.radio' || window.location.host === 'localhost:8080') {
  if (window.location.host === 'hbbtv-dev.rds.radio') {
    let fileref = document.createElement('script');
    fileref.setAttribute("type", "text/javascript");
    fileref.setAttribute("src", 'http://18.201.105.249:8888/target/target-script-min.js#anonymous');
    if (typeof fileref != "undefined") {
      document.getElementsByTagName("head")[0].appendChild(fileref);
    }
  }
} else {
  // Sentry.init({ dsn: 'https://719deaa07e7c491197950edb20e59e82@o389103.ingest.sentry.io/5262728' });
  Sentry.init({
    dsn: 'https://719deaa07e7c491197950edb20e59e82@o389103.ingest.sentry.io/5262728',
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  });
}

if (cookieService.getCookie('_rds_cookie_enabled') && cookieService.getCookie('cookieFlags') && cookieService.getCookie('cookieFlags')._m) {
  let fileref = document.createElement('script');
  fileref.setAttribute("type", "text/javascript");
  fileref.setAttribute("src", 'https://www.googletagmanager.com/gtag/js?id=G-0YZE54FF7B');
  if (typeof fileref != "undefined") {
    document.getElementsByTagName("head")[0].appendChild(fileref);
  }
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-0YZE54FF7B');
  }, 1000);
}


Vue.config.productionTip = false;
hbbtv.start();
ws.init();

// setTimeout(() => {
//   console.log('mainVideo.lright');
//   mainVideo.lright();
// }, 5000);

// setTimeout(() => {
//   console.log('mainVideo.setFullScreen');
//   mainVideo.setFullScreen();
// }, 15000);

new Vue({
  render: h => h(App),
}).$mount('#app');
