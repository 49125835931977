<template>
  <div class="bar-down-box">
    <div
      class="bar-down-box-button"
      :class="[item.color, { disabled: !item.enabled(store) }]"
    ></div>
    <span :class="{ disabled: !item.enabled(store) }">{{ item.label }}</span>
  </div>
</template>
<script>
import store from '../Store';
export default {
  name: 'MenuItem',
  props: ['item'],
  data() {
    return {
      store: store
    };
  }
};
</script>
<style lang="scss">
.bar-down-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 10px 10px;
  padding: 20px;
  color: rgb(255, 255, 255);
  font-family: 'Gilroy', sans-serif;
  font-size: 23px;
  span {
    line-height: 1em;
    margin-left: 10px;
    margin-top: 5px;
    &.disabled {
      opacity: 0.3;
    }
  }
}
.bar-down-box-button {
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;
  width: 17px;
  height: 17px;
  border-radius: 20px;

  &.red {
    background-color: rgb(199, 43, 62);
    border: 2px solid rgb(199, 43, 62);
  }
  &.green {
    background: green none repeat scroll 0% 0%;
    border: 2px solid green;
  }
  &.yellow {
    background: yellow none repeat scroll 0% 0%;
    border: 2px solid yellow;
  }
  &.blue {
    background: blue none repeat scroll 0% 0%;
    border: 2px solid blue;
  }
  &.disabled {
    background-color: transparent;
  }
}
</style>